import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IntlMessages from "../../../util/IntlMessages";

const useStyles = makeStyles({
  noResults: {
    marginTop: "40px",
    textAlign: "center",
  },
});

const NoResults = () => {
  const classes = useStyles();

  return (
    <Box className={classes.noResults}>
      <Typography variant="overline">
        <IntlMessages id="noResults.message" />
      </Typography>
    </Box>
  );
};

export { NoResults };
