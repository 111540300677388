import { getFormattedDate } from "./dates";

const DATE_FORMAT = "MMMM Do, YYYY";
const TIME_FORMAT = "hh:mm";
const AMPM_FORMAT = "a";

export const getDateStr = (appointment, timezone) => {
  const { start_datetime: start } = appointment?.slot || {};

  return timezone
    ? getFormattedDate(start, DATE_FORMAT, timezone)
    : getFormattedDate(start, DATE_FORMAT);
};

export const getTimeSlotStr = (appointment, timezone) => {
  const { start_datetime: start, end_datetime: end } = appointment?.slot || {};

  const startTime = timezone
    ? getFormattedDate(start, TIME_FORMAT, timezone)
    : getFormattedDate(start, TIME_FORMAT);
  const endTime = timezone
    ? getFormattedDate(end, TIME_FORMAT, timezone)
    : getFormattedDate(end, TIME_FORMAT);
  const startAmPm = timezone
    ? getFormattedDate(start, AMPM_FORMAT, timezone)
    : getFormattedDate(start, AMPM_FORMAT);
  const endAmPm = timezone
    ? getFormattedDate(end, AMPM_FORMAT, timezone)
    : getFormattedDate(end, AMPM_FORMAT);

  return `${startTime} ${
    startAmPm === endAmPm ? "" : startAmPm + " "
  }- ${endTime} ${endAmPm}`;
};
