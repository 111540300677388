import { makeStyles } from "@mui/styles";
import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";

const useStyles = makeStyles({
  breadcrumb: {
    backgroundColor: "unset",
    margin: 0,
    padding: 0,
  },
});

const getUrlString = (path, sub, index) => {
  if (index === 0) {
    return "/";
  } else {
    return "/" + path.split(sub)[0] + sub;
  }
};

const ContainerHeader = ({ title, match }) => {
  const classes = useStyles();
  const path = match.substr(1);
  const subPath = path.split("/");
  return (
    <div className="d-sm-flex justify-content-sm-between align-items-sm-center my-3">
      <h1 className="title fw-bold my-1">{title}</h1>

      <Breadcrumb className="mb-0" listClassName={classes.breadcrumb} tag="nav">
        {subPath.map((sub, index) => {
          const subPathTitle = sub.replace(/([-_][a-z])/g, (group) =>
            group.toUpperCase().replace("-", "").replace("_", "")
          );
          return (
            <BreadcrumbItem
              active={subPath.length === index + 1}
              tag={subPath.length === index + 1 ? "span" : "a"}
              key={index}
              href={getUrlString(path, sub, index)}
            >
              <IntlMessages id={`breadcrumb.${subPathTitle}`} />
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export { ContainerHeader };
