import { NotificationContainer } from "react-notifications";
import { Routes } from "../../routes/Routes";
import { Vertical } from "./Vertical/Vertical";

const AppLayout = () => {
  return (
    <Vertical>
      <Routes />
      <NotificationContainer />
    </Vertical>
  );
};

export { AppLayout };
