import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useParams } from "react-router-dom";
import { userSetPassword } from "../../../actions/Auth";
import {
  SET_PASSWORD_FAIL,
  SET_PASSWORD_SUCCESS,
} from "../../../constants/ActionTypes";
import { FORM_SUBMISSION_STATUS } from "../../../constants/common";
import { getErrorMessage } from "../../../util";
import IntlMessages from "../../../util/IntlMessages";
import { AuthFormTextField } from "../../generic/Forms/AuthFormTextField/AuthFormTextField";
import { SubmitButton } from "../../generic/Forms/SubmitButton/SubmitButton";

const SetBeneficiarySecurityCode = () => {
  const securityCodeLength = 6;
  const store = useStore();
  const { code } = useParams();
  const [securityCode, setSecurityCode] = useState("");
  const [repeatSecurityCode, setRepeatSecurityCode] = useState("");
  const [showSecurityCode, setShowSecurityCode] = useState();
  const [showRepeatSecurityCode, setShowRepeatSecurityCode] = useState();
  const [formStatus, setFormStatus] = useState(
    FORM_SUBMISSION_STATUS.LOCAL_DATA
  );
  const [valueInputHelperText, setValueInputHelperText] = useState();
  const [repeatValueInputHelperText, setRepeatValueInputHelperText] =
    useState();
  const [canSubmit, setCanSubmit] = useState(false);
  const dispatch = useDispatch();
  const { loader } = useSelector(({ auth }) => auth);
  const securityCodeInputRef = useRef(null);
  const intl = useIntl();

  const statusMap = {
    [SET_PASSWORD_SUCCESS]: FORM_SUBMISSION_STATUS.SUCCESS,
    [SET_PASSWORD_FAIL]: FORM_SUBMISSION_STATUS.ERROR,
  };

  const processResultMap = {
    [SET_PASSWORD_SUCCESS]: processSuccess,
    [SET_PASSWORD_FAIL]: processError,
  };

  function processSuccess() {
    NotificationManager.success(
      intl.formatMessage({
        id: "resetBeneficiarySecurityCode.setSecurityCodeSuccess",
      })
    );
  }

  function processErrorMessage(errors, message) {
    const errorMessage = getErrorMessage(errors);
    if (errorMessage[0] === "Code not found") {
      message = intl.formatMessage({
        id: "resetBeneficiarySecurityCode.codeNotFound",
      });
    } else {
      message = `${message}: ${errorMessage}`;
    }
    return message;
  }

  function processError() {
    let message = intl.formatMessage({
      id: "resetBeneficiarySecurityCode.setSecurityCodeFail",
    });
    const errors = store.getState().auth.errors;

    if (store.getState().auth.errors) {
      message = processErrorMessage(errors, message);
    }
    NotificationManager.error(message);
  }

  async function submit() {
    setFormStatus(FORM_SUBMISSION_STATUS.SUBMITTED); // optimistic
    const result = await dispatch(
      userSetPassword({ code, password: securityCode })
    );

    setFormStatus(statusMap[result.type]);
    processResultMap[result.type]();
  }

  const processForm = async () => {
    const valid = formIsValid(
      setValueInputHelperText,
      setRepeatValueInputHelperText
    );
    if (valid) {
      await submit();
    } else {
      setFormStatus(FORM_SUBMISSION_STATUS.LOCAL_ERROR);
      NotificationManager.error(
        <IntlMessages id="resetBeneficiarySecurityCode.criteriaNotMet" />
      );
    }
  };

  function isNumeric(setHelperFn) {
    const result = !!securityCode && !isNaN(securityCode);
    if (!result) {
      setHelperFn(
        <IntlMessages id="resetBeneficiarySecurityCode.securityCodeIsNonNumeric" />
      );
    }
    return result;
  }

  function isCorrectLength(setValueFn) {
    const result = !securityCode || securityCode?.length === securityCodeLength;
    if (!result) {
      setValueFn(
        <IntlMessages id="resetBeneficiarySecurityCode.securityCodeLengthIncorrect" />
      );
    }
    return result;
  }

  function repeatMatchesSecurityCode(setHelperFn) {
    if (repeatSecurityCode === securityCode) {
      return true;
    } else {
      setHelperFn(
        <IntlMessages id="resetBeneficiarySecurityCode.securityCodesDoNotMatch" />
      );
      return false;
    }
  }

  const formIsValid = (setValueHelperFn, setValueRepeatHelperFn) => {
    setValueHelperFn("");
    setValueRepeatHelperFn("");
    return (
      valueIsOk(setValueHelperFn) && repeatValueIsOk(setValueRepeatHelperFn)
    );
  };

  const valueIsOk = (setHelperFn) => {
    setHelperFn("");
    return isCorrectLength(setHelperFn) && isNumeric(setHelperFn);
  };

  const repeatValueIsOk = (setHelperFn) => {
    setHelperFn("");
    return repeatMatchesSecurityCode(setHelperFn);
  };

  const onInput = (e) => {
    e.target.value = e.target.value
      .toString()
      .replace(".", "")
      .slice(0, securityCodeLength);
  };

  // Handle state change results: security code value
  useEffect(() => {
    valueIsOk(setValueInputHelperText);
    repeatValueIsOk(setRepeatValueInputHelperText);
  }, [securityCode, repeatSecurityCode]);

  // Handle state change results: security code repeat value
  useEffect(() => {
    repeatValueIsOk(setRepeatValueInputHelperText);
  }, [repeatSecurityCode]);

  // Handle submit disabled
  useEffect(() => {
    const canSubmit =
      securityCode &&
      repeatSecurityCode &&
      !valueInputHelperText &&
      !repeatValueInputHelperText;
    setCanSubmit(canSubmit);
  }, [
    securityCode,
    repeatSecurityCode,
    valueInputHelperText,
    repeatValueInputHelperText,
  ]);

  const getEndAdornment = (showValue, setShowValue) => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle Security Code visibility"
          onClick={() => setShowValue(!showValue)}
        >
          {showValue ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  };

  const getStringNumbers = (value) => value.replace(/\D/g, "");

  return (
    <>
      <div className="app-login-header mb-4">
        {formStatus === FORM_SUBMISSION_STATUS.SUCCESS && (
          <>
            <h1>
              <IntlMessages id="resetBeneficiarySecurityCode.setSecurityCodeSuccess" />
            </h1>
            <div>
              <IntlMessages id="resetBeneficiarySecurityCode.requestText" />
            </div>
          </>
        )}

        {[
          FORM_SUBMISSION_STATUS.LOCAL_DATA,
          FORM_SUBMISSION_STATUS.SUBMITTED,
          FORM_SUBMISSION_STATUS.ERROR,
          FORM_SUBMISSION_STATUS.LOCAL_ERROR,
        ].includes(formStatus) && (
          <>
            <h1>
              <IntlMessages id="resetBeneficiarySecurityCode.requestTitle" />
            </h1>
            <div>
              <IntlMessages id="resetBeneficiarySecurityCode.requestText" />:
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <AuthFormTextField
                    id="securityCode1"
                    type={showSecurityCode ? "number" : "password"}
                    label={<IntlMessages id="appModule.securityCode" />}
                    onChange={(event) => {
                      setSecurityCode(getStringNumbers(event.target.value));
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        securityCodeInputRef.current.focus();
                      }
                    }}
                    value={securityCode}
                    InputProps={{
                      endAdornment: getEndAdornment(
                        showSecurityCode,
                        setShowSecurityCode
                      ),
                    }}
                    error={!!valueInputHelperText}
                    helperText={valueInputHelperText}
                    onInput={onInput}
                  />

                  <AuthFormTextField
                    id="securityCode2"
                    type={showRepeatSecurityCode ? "number" : "password"}
                    label={<IntlMessages id="appModule.repeatSecurityCode" />}
                    onChange={(event) => {
                      setRepeatSecurityCode(
                        getStringNumbers(event.target.value)
                      );
                    }}
                    inputRef={securityCodeInputRef}
                    onKeyDown={async (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        await processForm();
                      }
                    }}
                    value={repeatSecurityCode}
                    error={!!repeatValueInputHelperText}
                    helperText={repeatValueInputHelperText}
                    onInput={onInput}
                    InputProps={{
                      endAdornment: getEndAdornment(
                        showRepeatSecurityCode,
                        setShowRepeatSecurityCode
                      ),
                    }}
                  />

                  <SubmitButton
                    onSubmit={processForm}
                    loader={loader}
                    disabled={!canSubmit}
                  />
                </fieldset>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { SetBeneficiarySecurityCode };
