import { Box, Button, Grid } from "@mui/material";
import cl from "classnames";
import PropTypes from "prop-types";
import IntlMessages from "../../../../../util/IntlMessages";
import styles from "./Buttons.module.scss";

const Buttons = ({
  onDelete,
  onSubmit,
  toggleDrawer,
  submitTitle = "common.filters.apply",
  deleteTitle = "common.filters.clear",
  disableSubmit,
}) => {
  return (
    <Box role="presentation" className={styles.buttonsBox}>
      <Grid container className={styles.buttonsContainer}>
        <Grid item xs={5} className={styles.buttonContainer}>
          <Button
            size="large"
            color="secondary"
            onClick={() => onDelete(true)}
            className={cl(styles.button, styles.cancelBtn)}
          >
            <IntlMessages id={deleteTitle} />
          </Button>
        </Grid>
        <Grid item xs={7} className={styles.buttonContainer}>
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit();
              toggleDrawer(false);
            }}
            disableElevation
            className={cl(styles.button, styles.saveBtn)}
            disabled={disableSubmit}
          >
            <IntlMessages id={submitTitle} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

Buttons.propTypes = {
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  toggleDrawer: PropTypes.func,
  submitTitle: PropTypes.string,
  deleteTitle: PropTypes.string,
};

export { Buttons };
