import dayjs from "dayjs";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat.default);

/**
 * the timezone to use. If not set, the functions will guess it.
 */
let defaultTimezone = undefined;
export const setDefaultTimezone = (timezone) => {
  defaultTimezone = timezone;
};

/**
 * Parses a date to a string format
 * @param date {Date} the date
 * @param format {string} the format
 */
export const getFormattedDate = (date, format, timezone = defaultTimezone) => {
  const tz = timezone || dayjs.tz.guess();
  return dayjs(date).tz(tz).format(format);
};
/**
 * Transform date from timestamp
 * @param timestamp {string} the timestamp
 */
export const getDateFromTimestamp = (timestamp) => {
  return dayjs.unix(+timestamp).toDate();
};

/**
 * Transform date to timestamp
 * @param date {Date} the date
 */
export const getTimestampFromDate = (date) => {
  return dayjs(date).utc().unix() + "";
};
