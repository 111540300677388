import allLocales from "@fullcalendar/core/locales-all";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import momentPlugin from "@fullcalendar/moment";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayjs from "dayjs";
import { createRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  CALENDAR_HEIGHT,
  CALENDAR_SLOT_DURATION,
  CALENDAR_START_TIME,
  CALENDAR_VIEWS,
} from "../../../constants/common";
import { languageSelector } from "../../../reducers/selectors";
import { getReducedName } from "../../../util/user";
import "./ScheduleView.component.scss";

const ScheduleView = ({
  events,
  selectDatesRange,
  openApointment,
  openAddAvailability,
}) => {
  const intl = useIntl();
  const calendarRef = createRef();
  const language = useSelector(languageSelector);
  const [calendarApi, setCalendarApi] = useState();

  useEffect(
    () => setCalendarApi(calendarRef?.current?.getApi()),
    [calendarRef]
  );

  const slotLabelRender = ({ text, el }) => {
    const minutes = text.split(":")[1];

    if (el && minutes && minutes === "00")
      el.parentElement.className += " main-hour-slot";
  };

  const eventRender = ({ event, el, view }) => {
    const { clientHeight } = el.parentElement;

    // Vertically center event title
    if (!el.classList.contains("fc-list-event")) {
      el.style.height = `${clientHeight}px`;
      el.style.lineHeight = `${clientHeight}px`;
    }

    // Change event title according to view
    switch (view.type) {
      case CALENDAR_VIEWS.MONTH:
        event.setProp("display", "list-item");
        event.setProp("borderColor", event.backgroundColor);
        event.setProp("title", getMonthEventTitle(event.extendedProps));
        break;
      case CALENDAR_VIEWS.LIST:
        event.setProp("borderColor", event.backgroundColor);
        event.setProp("title", getListEventTitle(event.extendedProps));
        break;
    }
  };

  const getMonthEventTitle = (props) => {
    const { operator } = props;

    const operatorStr = getReducedName(operator);

    return `${operatorStr}`;
  };

  const getListEventTitle = (props) => {
    const { operator, onboarding } = props;

    const operatorStr = getReducedName(operator);
    const onboardingStr = getReducedName(onboarding?.user);

    return `${operatorStr} ${onboarding ? `with ${onboardingStr}` : "available"}`;
  };

  const onEventClick = (event) => {
    const eventInfo = { ...event?.event?.extendedProps };
    openApointment(eventInfo);
  };

  const onSelect = (event) => {
    const startDate = dayjs(event?.start);
    startDate.isBefore(new Date(), "day")
      ? calendarApi.unselect()
      : openAddAvailability(event);
  };

  return (
    <div className="scheduleView">
      <FullCalendar
        ref={calendarRef}
        plugins={[
          momentPlugin,
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin,
        ]}
        locales={allLocales}
        locale={language}
        initialView={CALENDAR_VIEWS.WEEK}
        // Sizing
        height={CALENDAR_HEIGHT}
        // Toolbar
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: `${CALENDAR_VIEWS.MONTH},${CALENDAR_VIEWS.WEEK},${CALENDAR_VIEWS.DAY},${CALENDAR_VIEWS.LIST}`,
        }}
        buttonText={{
          today: intl.formatMessage({ id: "pages.schedule.today" }),
          month: intl.formatMessage({ id: "pages.schedule.month" }),
          week: intl.formatMessage({ id: "pages.schedule.week" }),
          day: intl.formatMessage({ id: "pages.schedule.day" }),
          list: intl.formatMessage({ id: "pages.schedule.list" }),
        }}
        titleFormat="{MMMM Do}, YYYY"
        // Override views
        views={{
          [CALENDAR_VIEWS.MONTH]: {
            displayEventTime: true,
            eventTimeFormat: "hh:mm a",
          },
          [CALENDAR_VIEWS.LIST]: {
            displayEventTime: true,
            eventTimeFormat: "hh:mm a",
          },
        }}
        // Date
        navLinks
        // Time
        slotDuration={CALENDAR_SLOT_DURATION}
        slotLabelInterval={CALENDAR_SLOT_DURATION}
        slotLabelFormat="HH:mm"
        slotEventOverlap={false}
        scrollTime={CALENDAR_START_TIME}
        datesSet={selectDatesRange}
        allDaySlot={false}
        slotLabelDidMount={slotLabelRender}
        // selectMirror
        selectable
        select={onSelect}
        selectConstraint={{ daysOfWeek: [1, 2, 3, 4, 5] }}
        // Events
        dayMaxEvents
        eventClick={onEventClick}
        events={events}
        eventDidMount={eventRender}
        displayEventTime={false}
      />
    </div>
  );
};

export { ScheduleView };
