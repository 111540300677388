import { createSelector } from "reselect";
import {
  getCallsHistory,
  getColorFromName,
  getDarkerColorFromName,
  getFullName,
  getInitialsName,
  getLightColorFromName,
  isUserBeneficiary,
  userBelongsToSupportGroup,
  userIsAdmin,
  userIsAgent,
  userIsHelpDeskManager,
  userIsReadOnly,
} from "../util/user";

//#region User
const getUser = (state) => state.user;
export const userDataSelector = createSelector(getUser, (user) => user.data);

export const userLoadingSelector = createSelector(
  getUser,
  (user) => user.loading
);

export const userErrors = createSelector(getUser, (user) => user.errors);

export const userIdSelector = createSelector(
  userDataSelector,
  (userData) => userData.id
);

export const userLanguageSelector = createSelector(
  userDataSelector,
  (userData) => userData?.profile?.language
);

export const currentUserBelongsToSupportGroupSelector = createSelector(
  userDataSelector,
  (userData) => userBelongsToSupportGroup(userData)
);

export const currentUserIsAdminSelector = createSelector(
  userDataSelector,
  (userData) => userIsAdmin(userData)
);
//#endregion

//#region Users
const getUsers = (state) => state.users;
export const usersListSelector = createSelector(
  getUsers,
  (users) => users.list
);

export const usersDetailSelector = createSelector(
  getUsers,
  (users) => users.detail
);

export const usersCountSelector = createSelector(
  getUsers,
  (users) => users.count
);

export const usersLoadingSelector = createSelector(
  getUsers,
  (users) => users.loading
);

export const usersFiltersListSelector = createSelector(getUsers, (users) => {
  return users.usersFiltersList;
});

export const userIsBeneficiarySelector = createSelector(
  usersDetailSelector,
  (userData) => isUserBeneficiary(userData)
);

export const currentUserIsHelpDeskManagerSelector = createSelector(
  userDataSelector,
  (userData) => userIsHelpDeskManager(userData)
);

export const currentUserIsAgentSelector = createSelector(
  userDataSelector,
  (userData) => userIsAgent(userData)
);

export const currentUserIsReadOnlySelector = createSelector(
  userDataSelector,
  (userData) => userIsReadOnly(userData)
);

const getOperatorsFromUsers = createSelector(usersListSelector, (usersList) => {
  return usersList?.filter(userBelongsToSupportGroup);
});

export const operatorsListForSelectSelector = createSelector(
  [
    getOperatorsFromUsers,
    currentUserIsHelpDeskManagerSelector,
    currentUserIsAgentSelector,
    userDataSelector,
  ],
  (users, userIsHelpDeskManager, userIsAgent, user) => {
    let result = [getUserInfo(user)];

    if (userIsHelpDeskManager || userIsAgent) {
      result = users
        ?.map((user) => getUserInfo(user))
        ?.sort((prev, next) => prev.fullName.localeCompare(next.fullName));
    }
    return result;
  }
);

export const getUserInfo = (user) => {
  return user
    ? {
        id: user.id,
        fullName: getFullName(user),
        timezone: user.profile?.timezone || "N/D",
      }
    : null;
};
//#endregion

//#region Auth
const getAuth = (state) => state.auth;

export const userTokenSelector = createSelector(
  getAuth,
  (auth) => auth?.userToken
);

export const userAuthSelector = createSelector(getAuth, (auth) => ({
  userName: auth.userName,
  token: auth.userToken,
  refreshToken: auth.refreshToken,
}));

export const authSuccessMessageSelector = createSelector(
  getAuth,
  (auth) => auth?.successMessage
);
//#endregion

//#region BeneficiariesList
const getBeneficiariesData = (state) => state.beneficiaries;
export const beneficiariesSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiaries
);
export const beneficiaryDetailSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiaryDetail
);
export const beneficiariesCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.beneficiariesCount
);
export const beneficiariesLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => {
    return beneficiaries.beneficiariesLoading;
  }
);
export const beneficiariesFiltersListSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => {
    return beneficiaries.beneficiariesFiltersList;
  }
);
//#endregion

//#region Enrolments
const getEnrolmentsData = (state) => state.enrolment;
export const filtersListSelector = createSelector(
  getEnrolmentsData,
  (enrolment) => enrolment.enrolmentFiltersList
);

export const toggleAssignedSelector = createSelector(
  getEnrolmentsData,
  (enrolment) => enrolment.enrolmentToggleAssigned
);

export const toggleAttentionSelector = createSelector(
  getEnrolmentsData,
  (enrolment) => enrolment.enrolmentToggleAttention
);

export const enrolmentsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolments
);

export const enrolmentsCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolmentsCount || 0
);

export const enrolmentsLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolmentsLoading
);

export const approvedEnrolmentsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.approvedEnrolments
);

export const approvedEnrolmentFromListSelector = createSelector(
  approvedEnrolmentsSelector,
  (approvedEnrolments) => approvedEnrolments && approvedEnrolments[0]
);

export const enrolmentDetailSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.enrolmentDetail
);
//#endregion

//#region Certificates of entitlement
export const certificatesOfEntitlementSelector = createSelector(
  (state) => state.beneficiaries?.certificatesWithPage,
  (certificatesWithPage) => {
    const certificates =
      certificatesWithPage &&
      // eslint-disable-next-line no-unused-vars
      Object.entries(certificatesWithPage).flatMap(([page, items]) => items);
    return certificates;
  }
);

export const userHasNoCertificatesSelector = createSelector(
  certificatesOfEntitlementSelector,
  (certificates) => {
    return Array.isArray(certificates) && !certificates?.length;
  }
);
//#endregion

//#region App installs
export const appInstallsSelector = createSelector(
  (state) => state.beneficiaries.appInstalls,
  (appInstalls) => appInstalls
);

export const appInstallsCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.appInstallsCount || 0
);
//#endregion

//#region User invites
export const userInvitationsSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.invitations
);

export const userInvitationsCountSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.invitationsCount || 0
);

export const userInvitationsLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => beneficiaries.invitationsLoading
);
//#endregion

//#region Mix
export const beneficiaryProfileLoadingSelector = createSelector(
  getBeneficiariesData,
  (beneficiaries) => {
    return (
      beneficiaries.enrolmentsLoading ||
      beneficiaries.existenceValidationsLoading ||
      beneficiaries.invitationsLoading ||
      beneficiaries.certificatesLoading ||
      beneficiaries.appInstallsLoading ||
      beneficiaries.instantCallLoading
    );
  }
);

export const loadingCertificatesSelector = createSelector(
  (state) => state.beneficiaries?.certificatesLoading,
  (loading) => loading
);

export const loadingDCoEValidation = createSelector(
  (state) => state.beneficiaries?.certificateValidationLoading,
  (loading) => loading
);

export const loadingAppInstalls = createSelector(
  (state) => state.beneficiaries?.appInstallsLoading,
  (loading) => loading
);
//#endregion

//#region Calls history selector
export const callsHistorySelector = createSelector(
  (state) => state.beneficiaries?.callEvents,
  (callEvents) => (callEvents ? getCallsHistory(callEvents) : [])
);

export const loadingCallEvents = createSelector(
  (state) => state.beneficiaries,
  (beneficiaries) => beneficiaries?.callEventsLoading
);
//#endregion

//#region Schedule
const getSupportSchedule = (state) => state.supportSchedule;

const makeOperatorEvent = ({ id, operator, slot, onboarding }) => {
  const title = getInitialsName(operator);
  const operatorColor = onboarding
    ? getColorFromName(getFullName(operator))
    : getLightColorFromName(getFullName(operator));
  const operatorDarkerColor = getDarkerColorFromName(getFullName(operator));

  return {
    id,
    title,
    backgroundColor: operatorColor,
    borderColor: operatorDarkerColor,
    textColor: onboarding ? "#fff" : "#565656",
    start: slot.start_datetime,
    end: slot.end_datetime,
  };
};

export const availabilitySlotsSelectorV2 = createSelector(
  getSupportSchedule,
  (supportSchedule) => {
    return supportSchedule?.availabilitySlots;
  }
);

const appointmentsSelector = createSelector(
  getSupportSchedule,
  (supportSchedule) => {
    return supportSchedule?.appointments;
  }
);

export const calendarEventsSelector = createSelector(
  appointmentsSelector,
  (appointments) => {
    const result = appointments?.map((appointmentInfo) => {
      const calendarAppointment = makeOperatorEvent(appointmentInfo);
      return { ...calendarAppointment, ...{ extendedProps: appointmentInfo } };
    });
    return result;
  }
);

export const availabilityRequestInProgress = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.onAvailabilitySlotsRequest
);

export const appointmentsRequestInProgress = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.onAppointmentsRequest
);

export const videoCallTokenRequestInProgress = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.onVideoCallUrlRequest
);

export const VideoCallUrlSelector = createSelector(
  getSupportSchedule,
  (supportSchedule) => supportSchedule.videoCallUrl
);
//#endregion

//#region Common
const getCommonData = (state) => state.common;

export const organizationsSelector = createSelector(
  getCommonData,
  (common) => common.organizations
);

export const loadingOrganizationsSelector = createSelector(
  getCommonData,
  (common) => common.loadingOrganizations
);

export const reportedCountriesSelector = createSelector(
  getCommonData,
  (common) => common.reportedCountries
);

export const loadingReportedCountriesSelector = createSelector(
  getCommonData,
  (common) => common.loadingReportedCountries
);
//#endregion

//#region Settings
const getSettings = (state) => state.settings;

export const drawerTypeSelector = createSelector(
  getSettings,
  (settings) => settings?.drawerType
);

export const navCollapsedSelector = createSelector(
  getSettings,
  (settings) => settings?.navCollapsed
);

export const navigationStyleSelector = createSelector(
  getSettings,
  (settings) => settings?.navigationStyle
);

export const languageSelector = createSelector(
  getSettings,
  (settings) => settings?.language
);

export const isDirectionRTLSelector = createSelector(
  getSettings,
  (settings) => settings?.isDirectionRTL
);
//#endregion
