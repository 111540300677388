import {
  AccountBoxOutlined,
  Assignment,
  CalendarTodayOutlined,
  Check,
  CheckBoxOutlined,
  Clear,
  CommentOutlined,
  CreditCardOutlined,
  MessageOutlined,
  PersonOutline,
  PhoneOutlined,
  ScheduleOutlined,
  Timer,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import cl from "classnames";
import dayjs from "dayjs";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  bookAppointment,
  bulkAssignEnrolments,
  updateEnrolmentNoteAndPhoneNr,
} from "../../../../../actions/Beneficiaries";
import {
  BOOK_APPOINTMENT_SUCCESS,
  BULK_ASSIGN_ENROLMENTS_SUCCESS,
  UPDATE_ENROLMENT_SUCCESS,
} from "../../../../../constants/ActionTypes";
import {
  DATE_TIME_FORMAT,
  ENROLMENT_STATUS,
  ENROLMENT_WORKFLOW_STATUS,
  ENROL_REJECT_FLOW_STEP,
  ENROL_REJECT_REASONS,
  ID_DOCUMENT_STATUS,
} from "../../../../../constants/common";
import { enrolmentsLoadingSelector } from "../../../../../reducers/selectors";
import { getErrorMessage } from "../../../../../util";
import { getFormattedDate } from "../../../../../util/dates";
import IntlMessages from "../../../../../util/IntlMessages";
import { getFullName, getRejectedReasons } from "../../../../../util/user";
import { FiltersSidebar } from "../../../../generic/FilterSidebar/FilterSidebar";
import { NotAvailable } from "../../../../generic/NotAvailable/NotAvailable";
import { TabPanel } from "../../../../generic/TabPanel";
import { EnrolmentSupportStatusDot } from "../../../EnrolmentSupportStatus/EnrolmentSupportStatusDot";
import { EnrolmentCardBodyItem } from "../../EnrolmentCardBodyItem";
import styles from "./EnrolmentContent.module.scss";
import { HistoryLog } from "./HistoryLog";
import { IdDocConfidence } from "./IdDocConfidence";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat.default);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  tabPanelWithButtons: {
    height: "calc(100% - 78px)",
    overflowY: "auto",
  },
  tabPanel: {
    height: "100%",
    overflowY: "auto",
  },
  alertContainer: {
    padding: 0,
    marginBottom: "30px",
  },
  alert: {
    textAlign: "left",
    "&:not(first-child)": {
      marginTop: "10px",
    },
  },
  idDocumentAlert: {
    borderColor: "#BE8A00",
    backgroundColor: "#FFF6DE",
  },
  idDocumentAlertColor: {
    color: "#BE8A00",
  },
  tabHeader: {
    "&:not(.first-tab)": {
      marginTop: "30px",
    },
    alignItems: "center",
    borderRadius: "0.25rem",
    padding: "0.625rem 1rem",
    backgroundColor: "#F2F4F7",
  },
  tabTitle: {
    color: "#202020",
    fontWeight: 590,
  },
  editButton: {
    minWidth: "unset",
    padding: 0,
    fontSize: "16px",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  actionButton: {
    textTransform: "none",
    fontSize: "16px",
  },
  openDocumentButton: {
    padding: "0.75rem 1rem",
  },
  rejectButton: {
    padding: "0.75rem 2.625rem",
    backgroundColor: "#C62222",
    color: theme.palette.getContrastText("#C62222"),
    "&:hover": {
      backgroundColor: "#8D1A1A",
    },
  },
  approveButton: {
    marginLeft: "10px",
    padding: "0.75rem 2.125rem",
    backgroundColor: "#4DA551",
    color: theme.palette.getContrastText("#4DA551"),
    "&:hover": {
      backgroundColor: "#326C34",
    },
  },
}));

const EnrolmentContent = ({
  enrolment,
  manageEnrolmentFn,
  handleRejectButtonClick,
  edit,
  reload,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();

  const createdDate =
    enrolment?.created && getFormattedDate(enrolment.created, DATE_TIME_FORMAT);
  const reviewDate = enrolment?.modified ? (
    getFormattedDate(enrolment.modified, "MMMM Do YYYY")
  ) : (
    <NotAvailable />
  );
  const appointmentDay =
    enrolment?.appointment?.slot?.start_datetime &&
    getFormattedDate(enrolment.appointment.slot.start_datetime, "DD/MM/YYYY");
  const appointmentTimeStart =
    enrolment?.appointment?.slot?.start_datetime &&
    getFormattedDate(enrolment.appointment.slot.start_datetime, "HH:mm a");
  const appointmentTimeEnd =
    enrolment?.appointment?.slot?.end_datetime &&
    getFormattedDate(enrolment.appointment.slot.end_datetime, "HH:mm a");
  const appointmentTime =
    appointmentTimeStart &&
    appointmentTimeEnd &&
    appointmentTimeStart + " " + appointmentTimeEnd;
  const userTimezone = enrolment?.user?.profile?.timezone;
  const appointmentPreferences = enrolment?.appointment_preferences[0];
  const preferenceTime = getPreferenceTime();
  const preferenceComments = appointmentPreferences?.comment;
  const phoneNumber = enrolment?.phone_number;
  const note = enrolment?.note;
  const reviewedBy = getFullName(enrolment?.reviewed_by);
  const loading = useSelector(enrolmentsLoadingSelector);
  const emptyPhoneNumber = !enrolment?.phone_number;
  const hasError = !enrolment?.task_status;
  const idDocumentStatus = enrolment?.document_scan_status;
  const isEnrolmentRejected =
    enrolment?.workflow_status_unjspf === ENROLMENT_WORKFLOW_STATUS.REJECTED;
  const rejectedReasons = getRejectedReasons(enrolment?.rejected_reasons);
  const disableEdit =
    enrolment?.workflow_status_unjspf === ENROLMENT_WORKFLOW_STATUS.APPROVED ||
    enrolment?.workflow_status_unjspf === ENROLMENT_WORKFLOW_STATUS.REJECTED;
  const disableReject =
    loading ||
    hasError ||
    enrolment?.workflow_status_unjspf === ENROLMENT_WORKFLOW_STATUS.REJECTED;
  const disableAccept =
    loading ||
    hasError ||
    enrolment?.workflow_status_unjspf === ENROLMENT_WORKFLOW_STATUS.APPROVED;

  const hasPendingStatus = enrolment?.status === ENROLMENT_STATUS.PENDING;
  const hasEmptyPhoneNumber = emptyPhoneNumber && !disableEdit;
  const showIdDocumentApproved =
    idDocumentStatus === ID_DOCUMENT_STATUS.APPROVED &&
    enrolment?.status !== ENROLMENT_STATUS.APPROVED;
  const hasAlert =
    hasError | hasPendingStatus | hasEmptyPhoneNumber | showIdDocumentApproved;

  const [value, setValue] = useState(0);
  const [toggleDrawerRequest, setToggleDrawerRequest] = useState(false);
  const [toggleDrawerNotes, setToggleDrawerNotes] = useState(false);
  const [toggleDrawerAppointment, setToggleDrawerAppointment] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const saveEnrolmentNotes = async (notes) => {
    updateEnrolment({ notes });
  };

  const saveEnrolmentRequest = async (phoneNumber) => {
    updateEnrolment({ phoneNumber });
  };

  const updateEnrolment = async (data) => {
    const { notes, phoneNumber } = data;
    const result = await dispatch(
      updateEnrolmentNoteAndPhoneNr(enrolment.id, {
        phone_number: phoneNumber,
        note: notes,
      })
    );

    if (result.type === UPDATE_ENROLMENT_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.enrolmentDetail.updateSuccess" />
      );
      reload();
      edit(false);
    } else {
      NotificationManager.error(
        <IntlMessages id="appModule.errorTryAgainLater" />
      );
    }
  };

  const saveEnrolmentAppointment = async (enrolmentAppointment) => {
    const { enrolment, selectedAgent, selectedSlot } = enrolmentAppointment;
    const result = await dispatch(
      selectedSlot
        ? bookAppointment(enrolmentAppointment)
        : bulkAssignEnrolments({
            on_boarding_ids: [enrolment?.id],
            assigned_agent: selectedAgent?.id,
          })
    );

    if (result.type === BOOK_APPOINTMENT_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.schedule.slotAvailable.addAppointment.success" />
      );
      reload();
    } else if (result.type === BULK_ASSIGN_ENROLMENTS_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.enrolments.assign.success" />
      );
      reload();
    } else {
      const errors = result.error?.response?.data;
      let message = intl.formatMessage({ id: "notification.error" });
      if (errors) {
        message = `${message}: ${getErrorMessage(errors, true)}`;
      }
      NotificationManager.error(message);
    }
  };

  const onDeleteEnrolmentRequest = () => {
    setToggleDrawerRequest(false);
  };

  const onDeleteEnrolmentNotes = () => {
    setToggleDrawerNotes(false);
  };

  const onDeleteEnrolmentAppointment = () => {
    setToggleDrawerAppointment(false);
  };

  function getPreferenceTime() {
    if (appointmentPreferences) {
      const startDateTime = `2022-01-01 ${appointmentPreferences.start_time}`;
      const startHour = dayjs(startDateTime).hour();
      const startDateTimeConverted = dayjs
        .tz(startDateTime, userTimezone)
        .utc();
      const localStartHours = new Date(startDateTimeConverted).getHours();
      const localStartHour =
        localStartHours > 12 ? localStartHours - 12 : localStartHours;
      const localStartAmPm = localStartHours > 12 ? "pm" : "am";

      const endDateTime = `2022-01-01 ${appointmentPreferences.end_time}`;
      const endDateTimeConverted = dayjs.tz(endDateTime, userTimezone).utc();
      const localEndHours = new Date(endDateTimeConverted).getHours();
      const localEndHour =
        localEndHours > 12 ? localEndHours - 12 : localEndHours;
      const localEndAmPm = localEndHours > 12 ? "pm" : "am";

      return `${localStartHour}${
        localStartAmPm === localEndAmPm ? "" : " " + localStartAmPm
      } - ${localEndHour} ${localEndAmPm} (${
        startHour === 8 ? "Morning" : "Afternoon"
      })`;
    } else return null;
  }

  return (
    <Box className={classes.container}>
      <div className={styles.enrolmentContent}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          className={styles.tabs}
        >
          <Tab
            label={intl.formatMessage({
              id: "pages.enrolmentDetail.tab.verification",
            })}
          />
          <Tab
            label={intl.formatMessage({
              id: "pages.enrolmentDetail.tab.information",
            })}
          />
          <Tab
            label={intl.formatMessage({
              id: "pages.enrolmentDetail.tab.history",
            })}
          />
        </Tabs>
        <TabPanel
          index={0}
          value={value}
          className={classes.tabPanelWithButtons}
        >
          <IdDocConfidence enrolment={enrolment} />
        </TabPanel>
        <TabPanel index={1} value={value} className={classes.tabPanel}>
          {/* Alerts */}
          {hasAlert ? (
            <Box p={0} className={classes.alertContainer}>
              {/* Error alert */}
              {hasError && (
                <Alert
                  variant="outlined"
                  severity="error"
                  className={classes.alert}
                >
                  <AlertTitle>
                    <IntlMessages id="pages.enrolmentDetail.processingError" />
                  </AlertTitle>
                  <Typography variant="body2">
                    <IntlMessages id="pages.enrolmentDetail.processingErrorHelp" />
                  </Typography>
                </Alert>
              )}

              {/* Pending status alert */}
              {hasPendingStatus && (
                <Alert
                  variant="outlined"
                  severity="info"
                  className={classes.alert}
                >
                  <AlertTitle>Note</AlertTitle>
                  <Typography variant="body2">
                    <IntlMessages id="pages.enrolmentDetail.help" />
                  </Typography>
                </Alert>
              )}

              {/* Empty phone number alert */}
              {hasEmptyPhoneNumber && (
                <Alert
                  variant="outlined"
                  severity="warning"
                  className={classes.alert}
                >
                  <AlertTitle>Note</AlertTitle>
                  <Typography variant="body2">
                    <IntlMessages id="pages.enrolmentDetail.phoneNumberEmpty" />
                  </Typography>
                </Alert>
              )}

              {/* ID Document approved */}
              {showIdDocumentApproved && (
                <Alert
                  variant="outlined"
                  icon={<Assignment className={classes.idDocumentAlertColor} />}
                  className={cl(classes.alert, classes.idDocumentAlert)}
                >
                  <AlertTitle className={classes.idDocumentAlertColor}>
                    <IntlMessages id="pages.enrolmentDetail.idDocumentApproved.title" />
                  </AlertTitle>
                  <Typography variant="body2">
                    <IntlMessages id="pages.enrolmentDetail.idDocumentApproved.message" />
                  </Typography>
                </Alert>
              )}
            </Box>
          ) : (
            <></>
          )}

          <div className={cl(styles.tabContent)}>
            <Box
              className={
                classes.tabHeader +
                " first-tab d-flex flex-row justify-content-between"
              }
            >
              <Typography className={classes.tabTitle}>
                <IntlMessages id="pages.enrolmentDetail.enrolmentRequest" />
              </Typography>
              <Button
                color="primary"
                className={classes.editButton}
                onClick={() => setToggleDrawerRequest(true)}
                disabled={disableEdit}
                disableFocusRipple
              >
                <IntlMessages id="common.edit" />
              </Button>
            </Box>

            <FiltersSidebar
              toggleDrawer={setToggleDrawerRequest}
              opened={toggleDrawerRequest}
              filtersTitle="pages.enrolmentDetail.enrolmentInformation"
              deleteTitle="common.cancel"
              submitTitle="common.saveChanges"
              activateInformations
              enrolmentPhone={
                phoneNumber || intl.formatMessage({ id: "common.notAvailable" })
              }
              enrolmentLabel="common.phoneNumber"
              onDelete={onDeleteEnrolmentRequest}
              onSubmit={saveEnrolmentRequest}
            />
            <div
              className={cl(
                styles.infoContent,
                " d-flex flex-row justify-content-between"
              )}
            >
              <EnrolmentCardBodyItem
                icon={<CheckBoxOutlined />}
                label="common.status"
              >
                <EnrolmentSupportStatusDot
                  status={enrolment?.workflow_status_unjspf}
                  showLabel
                />
              </EnrolmentCardBodyItem>
              <EnrolmentCardBodyItem
                icon={<CalendarTodayOutlined />}
                label="pages.enrolmentDetail.enrolmentRequest.request"
              >
                <span className={cl(styles.text, " text-dark text")}>
                  {createdDate}
                </span>
              </EnrolmentCardBodyItem>
              <EnrolmentCardBodyItem
                icon={<PhoneOutlined />}
                label="common.phoneNumber"
              >
                {phoneNumber ? (
                  <span className={cl(styles.text, " text-dark text")}>
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </span>
                ) : (
                  <NotAvailable textClassName="text-lighten-2" />
                )}
              </EnrolmentCardBodyItem>
            </div>
            <div
              className={cl(
                styles.infoContent,
                " d-flex flex-row justify-content-start"
              )}
            >
              <EnrolmentCardBodyItem
                icon={<PersonOutline />}
                label="pages.enrolmentDetail.enrolmentRequest.reviewedBy"
              >
                <span className={cl(styles.text, " text-dark text")}>
                  {reviewedBy || <NotAvailable />}
                </span>
              </EnrolmentCardBodyItem>
              <EnrolmentCardBodyItem
                icon={<CalendarTodayOutlined />}
                label="pages.enrolmentDetail.enrolmentRequest.reviewDate"
              >
                <span className={cl(styles.text, " text-dark text")}>
                  {reviewDate || <NotAvailable />}
                </span>
              </EnrolmentCardBodyItem>
            </div>

            {isEnrolmentRejected ? (
              <>
                <Box
                  className={
                    classes.tabHeader +
                    " d-flex flex-row justify-content-between"
                  }
                >
                  <Typography className={classes.tabTitle}>
                    <IntlMessages id="pages.enrolmentDetail.rejectionDetails" />
                  </Typography>
                </Box>
                <div
                  className={cl(
                    styles.infoContent,
                    " d-flex flex-row justify-content-start"
                  )}
                >
                  <EnrolmentCardBodyItem
                    icon={<AccountBoxOutlined />}
                    label="pages.enrolmentDetail.rejectionReason.face"
                    containerClass="col-12 col-xs-12 col-sm-6 col-lg-6 col-xl-6 px-4 py-3"
                  >
                    {
                      rejectedReasons[
                        ENROL_REJECT_REASONS[
                          ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS
                        ]
                      ]
                    }
                  </EnrolmentCardBodyItem>
                  <EnrolmentCardBodyItem
                    icon={<CreditCardOutlined />}
                    label="pages.enrolmentDetail.rejectionReason.idDoc"
                    containerClass="col-12 col-xs-12 col-sm-6 col-lg-6 col-xl-6 px-4 py-3"
                  >
                    {
                      rejectedReasons[
                        ENROL_REJECT_REASONS[
                          ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS
                        ]
                      ]
                    }
                  </EnrolmentCardBodyItem>
                </div>
                {rejectedReasons[
                  ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.OTHER_REASONS]
                ] ? (
                  <div className="d-flex flex-row justify-content-between">
                    <EnrolmentCardBodyItem
                      icon={<MessageOutlined />}
                      label="pages.enrolmentDetail.rejectionReason.otherReasons"
                      containerClass="col-12 col-xs-12 col-sm-6 col-lg-6 col-xl-6 px-4 py-3"
                    >
                      <span className={cl(styles.text, " text-dark text")}>
                        {
                          rejectedReasons[
                            ENROL_REJECT_REASONS[
                              ENROL_REJECT_FLOW_STEP.OTHER_REASONS
                            ]
                          ]
                        }
                      </span>
                    </EnrolmentCardBodyItem>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}

            <Box
              className={
                classes.tabHeader + " d-flex flex-row justify-content-between"
              }
            >
              <Typography className={classes.tabTitle}>
                <IntlMessages id="pages.enrolmentDetail.appointment" />
              </Typography>
              <Button
                color="primary"
                className={classes.editButton}
                onClick={() => setToggleDrawerAppointment(true)}
                disabled={disableEdit}
                disableRipple
              >
                <IntlMessages id="common.edit" />
              </Button>
            </Box>
            <FiltersSidebar
              toggleDrawer={setToggleDrawerAppointment}
              opened={toggleDrawerAppointment}
              filtersTitle="pages.enrolmentDetail.editAppointment"
              deleteTitle="common.cancel"
              submitTitle="common.saveChanges"
              activateAppointment
              enrolment={enrolment}
              onDelete={onDeleteEnrolmentAppointment}
              onSubmit={saveEnrolmentAppointment}
            />
            <div
              className={cl(
                styles.infoContent,
                " d-flex flex-row justify-content-between"
              )}
            >
              <EnrolmentCardBodyItem
                label="common.day"
                icon={<CalendarTodayOutlined />}
              >
                <span className={cl(styles.text, " text-dark text")}>
                  {appointmentDay || <NotAvailable />}
                </span>
              </EnrolmentCardBodyItem>
              <EnrolmentCardBodyItem label="common.time" icon={<Timer />}>
                <span className={cl(styles.text, " text-dark text")}>
                  {appointmentTime || <NotAvailable />}
                </span>
              </EnrolmentCardBodyItem>
              <EnrolmentCardBodyItem
                label="common.agent"
                icon={<PersonOutline />}
              >
                <span className={cl(styles.text, " text-dark text")}>
                  {getFullName(enrolment?.assigned_agent) || <NotAvailable />}
                </span>
              </EnrolmentCardBodyItem>
            </div>
            <div
              className={cl(
                styles.infoContent,
                " d-flex flex-row justify-content-start"
              )}
            >
              <EnrolmentCardBodyItem
                label="pages.enrolmentDetail.timePreference"
                icon={<ScheduleOutlined />}
              >
                {preferenceTime ? (
                  <>
                    <span className={cl(styles.text, " text-dark text")}>
                      {preferenceTime}
                    </span>
                    <div className={styles.subText}>
                      <IntlMessages id="pages.enrolmentDetail.timePreference.basedOnYourTz" />
                    </div>
                  </>
                ) : (
                  <span className={cl(styles.text, " text-dark text")}>
                    <NotAvailable />
                  </span>
                )}
              </EnrolmentCardBodyItem>
              <EnrolmentCardBodyItem
                label="common.comments"
                icon={<CommentOutlined />}
              >
                <span className={cl(styles.text, " text-dark text")}>
                  {preferenceComments || <NotAvailable />}
                </span>
              </EnrolmentCardBodyItem>
            </div>

            <Box
              className={
                classes.tabHeader + " d-flex flex-row justify-content-between"
              }
            >
              <Typography className={classes.tabTitle}>
                <IntlMessages id="pages.enrolmentDetail.notes" />
              </Typography>
              <Button
                color="primary"
                className={classes.editButton}
                onClick={() => setToggleDrawerNotes(true)}
                disableRipple
              >
                <IntlMessages id="common.edit" />
              </Button>
            </Box>
            <FiltersSidebar
              toggleDrawer={setToggleDrawerNotes}
              opened={toggleDrawerNotes}
              filtersTitle="pages.enrolmentDetail.notes.add"
              deleteTitle="common.cancel"
              submitTitle="common.saveChanges"
              activateInformations
              enrolmentNotes={note}
              enrolmentLabel="pages.enrolmentDetail.notes"
              onDelete={onDeleteEnrolmentNotes}
              onSubmit={saveEnrolmentNotes}
            />
            <div className="d-flex flex-row justify-content-between">
              <EnrolmentCardBodyItem
                label=""
                containerClass={note && "col py-3"}
              >
                {note ? (
                  <p className="text-dark text text-break ms-2">{note}</p>
                ) : (
                  <NotAvailable textClassName="text-lighten-2" />
                )}
              </EnrolmentCardBodyItem>
            </div>
          </div>
        </TabPanel>
        <TabPanel index={2} value={value} className={classes.tabPanel}>
          <HistoryLog historyData={enrolment?.history_log} />
        </TabPanel>
      </div>
      {/* Bottom manage buttons */}
      {value === 0 && (
        <div className={cl(styles.buttonsBar + " enrolment-buttons d-lg-flex")}>
          {/* Reject */}
          <Button
            variant="contained"
            className={cl(classes.actionButton, classes.rejectButton)}
            startIcon={<Clear fontSize="small" />}
            onClick={handleRejectButtonClick}
            disabled={disableReject}
            disableElevation
            disableRipple
          >
            <IntlMessages id="enrolment.reject" />
          </Button>

          {/* Accept */}
          <Button
            variant="contained"
            className={cl(classes.actionButton, classes.approveButton)}
            startIcon={<Check fontSize="small" />}
            onClick={() =>
              manageEnrolmentFn({ status: ENROLMENT_STATUS.APPROVED })
            }
            disabled={disableAccept}
            disableElevation
            disableRipple
          >
            <IntlMessages id="enrolment.approve" />
          </Button>
        </div>
      )}
    </Box>
  );
};

export { EnrolmentContent };
