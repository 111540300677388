import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import React, { cloneElement } from "react";
import IntlMessages from "../../../util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    marginBottom: "0.5rem",
  },
  icon: {
    marginRight: "10px",
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));

const EnrolmentCardBodyItem = ({
  label,
  children,
  containerClass = "",
  icon,
}) => {
  const classes = useStyles();

  const containerClassNames =
    containerClass || "col-12 col-xs-12 col-sm-4 col-lg-4 col-xl-4 px-4 py-3";

  return (
    <Box className={containerClassNames}>
      <Box className={classes.labelContainer}>
        {icon &&
          cloneElement(icon, { color: "primary", className: classes.icon })}
        <Typography className={classes.label}>
          {label && <IntlMessages id={label} />}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
};

EnrolmentCardBodyItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
};

export { EnrolmentCardBodyItem };
