import { Box, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cl from "classnames";
import { ChipButton } from "../ChipButton";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    overflow: "auto",
    marginRight: "5px",
  },
  tabs: {
    minHeight: "unset",
  },
  scroller: {
    marginBottom: "0",
  },
  scrollButtons: {
    color: theme.palette.primary.main,
  },
  item: {
    padding: theme.spacing(0.25),
  },
}));

const ChipsPanel = ({
  options,
  idProperty,
  labelProperty,
  deleteOption,
  getTagProps,
  containerClass,
}) => {
  const classes = useStyles();

  return (
    <Box className={cl(classes.container, containerClass)}>
      <Tabs
        value={false}
        variant="scrollable"
        className={classes.tabs}
        classes={{
          scroller: classes.scroller,
          scrollButtons: classes.scrollButtons,
        }}
      >
        {options.map((option, index) => {
          const optionId = option[idProperty ?? "id"];
          const optionLabel = option[labelProperty ?? "label"];
          const tagProps = getTagProps ? getTagProps({ index }) : {};

          return (
            <li key={optionId} className={classes.item}>
              <ChipButton
                option={option}
                label={optionLabel}
                onDelete={deleteOption}
                {...tagProps}
              />
            </li>
          );
        })}
      </Tabs>
    </Box>
  );
};

export { ChipsPanel };
