export const ROUTES = {
  MAIN: "main",
  BENEFICIARY: "beneficiary",
  ENROLMENT: "enrolment",
  SCHEDULE: "schedule",
  SIGN_IN: "auth/signin",
  AUTH_SUCCESS: "auth/success",
  FORBIDDEN: "forbidden",
  MAINTENANCE: "maintenance",
  MANAGER_USERS: "manager-users",
  MY_PROFILE: "my-profile",
};

export const APP_ROOT = "/app/";

export const absolutePath = (path) => {
  return `${APP_ROOT}${path}`;
};

export const FAQ_URL =
  "https://www.unjspf.org/digital-certificate-of-entitlement-faq/";
