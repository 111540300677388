import { Check, Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { ChipsPanel } from "../../generic/ChipsPanel";
import { LinkButton } from "../../generic/LinkButton";
import { NoResults } from "../../generic/NoResults";
import { SearchBox } from "../../generic/SearchBox";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: "1rem",
    marginBottom: "10px",
  },
  selectAll: {
    marginTop: "20px",
    textAlign: "right",
  },
  agentInfo: {
    marginBottom: "10px",
  },
  selectedMultiple: {
    backgroundColor: "#F2F4F7",
    borderRadius: "4px",
  },
  selectedSingle: {
    "&.Mui-selected": {
      backgroundColor: "unset",
      border: "1px solid #7E7E7E80",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "unset",
        cursor: "default",
      },
    },
  },
  secondaryActionSingle: {
    right: "10px",
  },
  buttonMultiple: {
    backgroundColor: "#F2F4F7",
  },
  buttonSelectedMultiple: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  buttonSelectedSingle: {
    padding: "6px",
  },
  clearIcon: {
    fontSize: "1.25rem",
  },
}));

export const SelectionMode = {
  None: "None",
  Single: "Single",
  Multiple: "Multiple",
};

const AgentsList = ({
  agents,
  agentsChanged,
  selectionMode = SelectionMode.Multiple,
  selectedAgent,
  showSelectAll,
  showSelectedAsChips,
  selectionRequired,
}) => {
  // #region Hooks
  const classes = useStyles();
  const [agentsList, setAgentsList] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [agentsSelected, setAgentsSelected] = useState(selectedAgent);

  const isSingleSelection = selectionMode === SelectionMode.Single;
  const isMultipleSelection = selectionMode === SelectionMode.Multiple;

  useEffect(() => {
    if (agents) filterAgents();
  }, [agents]);
  // #endregion

  // #region Search and filter methods
  const onSearch = (value) => {
    setSearchString(value);
    filterAgents(value);
  };

  const filterAgents = (value = searchString) => {
    const filteredAgents = value
      ? agents.filter((agent) =>
          agent.fullName?.toLowerCase().includes(value.toLowerCase())
        )
      : [...agents];

    setAgentsList(filteredAgents);
  };
  // #endregion

  // #region Selection methods
  const onSelectAll = (event) => {
    event.preventDefault();
    selectUnselectAll(true);
  };

  const toggleSelected = (agent) => {
    if (selectionMode !== SelectionMode.None) {
      agents
        .filter((a) => a.id === agent.id)
        .map((a) => {
          a.selected = !agent.selected;
          return a;
        });

      emitSelectedAgents();
    }
  };

  const selectUnselectAll = (select) => {
    agents.map((agent) => {
      agent.selected = select;
      return agent;
    });

    setAgentsList(agents);
    emitSelectedAgents();
  };

  const emitSelectedAgents = () => {
    setAgentsSelected(
      isMultipleSelection
        ? agents?.filter((a) => a.selected)
        : agents?.find((a) => a.selected)
    );
    agentsChanged(
      isMultipleSelection ? [...agents] : agents.find((a) => a.selected)
    );
  };
  // #endregion

  // #region Render methods
  const renderListItem = (agent) => {
    const { id, fullName, timezone, selected } = agent;
    const isAgentSelected = (isSingleSelection && !!agentsSelected) || selected;
    return (
      <ListItem
        key={id}
        button
        selected={isAgentSelected}
        className={classes.agentInfo}
        classes={{ selected: classes[`selected${selectionMode}`] }}
        onClick={() => !isAgentSelected && toggleSelected(agent)}
        disableRipple
      >
        <ListItemText id={id} primary={fullName} secondary={timezone} />
        {isMultipleSelection || !!agentsSelected ? (
          <ListItemSecondaryAction
            className={
              isAgentSelected ? classes[`secondaryAction${selectionMode}`] : ""
            }
          >
            <IconButton
              aria-labelledby={id}
              classes={{
                root: isAgentSelected
                  ? classes[`buttonSelected${selectionMode}`]
                  : classes[`button${selectionMode}`],
              }}
              onClick={() =>
                isMultipleSelection || !!agentsSelected
                  ? toggleSelected(agent)
                  : selectUnselectAll(false)
              }
            >
              {isMultipleSelection ? (
                <Check />
              ) : (
                <Close className={classes.clearIcon} />
              )}
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  };
  // #endregion

  return (
    <Box>
      {isSingleSelection && agentsSelected ? (
        <List className={classes.agentsList}>
          {renderListItem(agentsSelected)}
        </List>
      ) : (
        <Box>
          {/* Required message */}
          {selectionMode !== SelectionMode.None &&
            selectionRequired &&
            (!agentsSelected || !agentsSelected.length) && (
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.subtitle}
              >
                {isMultipleSelection && (
                  <IntlMessages id="pages.schedule.agentsList.selectionMultiple.required.message" />
                )}
                {isSingleSelection && (
                  <IntlMessages id="pages.schedule.agentsList.selectionSingle.required.message" />
                )}
              </Typography>
            )}
          {/* Search box */}
          <SearchBox value={searchString} search={onSearch} />
          {/* Agents selected */}
          {isMultipleSelection && showSelectedAsChips && (
            <ChipsPanel
              options={agentsList.filter((agent) => agent.selected)}
              labelProperty="fullName"
              deleteOption={(agent) => toggleSelected(agent)}
            />
          )}
          {/* Agents list */}
          {agentsList.length ? (
            <>
              <Box className={classes.selectAll}>
                {isMultipleSelection && showSelectAll && (
                  <LinkButton
                    label="pages.schedule.filters.selectAll"
                    click={onSelectAll}
                  />
                )}
              </Box>
              <List className={classes.agentsList}>
                {agentsList.map((agent) => {
                  return renderListItem(agent);
                })}
              </List>
            </>
          ) : (
            <NoResults />
          )}
        </Box>
      )}
    </Box>
  );
};

export { AgentsList };
