import { Button, DialogContentText } from "@mui/material";
import IntlMessages from "../../../../../util/IntlMessages";
import { ActionDialog } from "../../../../generic/ActionDialog";

const RecoverSecurityCodeDialog = ({
  beneficiary,
  recoverSecurityCode,
  open,
  handleClose,
}) => {
  const handleSubmit = async () => {
    recoverSecurityCode();
    handleClose();
  };

  return (
    <ActionDialog
      open={open}
      onClose={handleClose}
      title={<IntlMessages id="appModule.recoverSecurityCode" />}
      content={
        <DialogContentText>
          <IntlMessages
            id="recoverBeneficiarySecurityCode.dialog"
            values={{ email: beneficiary?.email }}
          />
        </DialogContentText>
      }
      actions={
        <Button
          id="submit"
          variant="contained"
          color="primary"
          className="float-right"
          onClick={handleSubmit}
        >
          <IntlMessages id="common.submit" />
        </Button>
      }
    />
  );
};

export { RecoverSecurityCodeDialog };
