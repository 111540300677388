import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Tabs } from "../../../generic/Tabs";
import { UserInfoList } from "../../../generic/UserInfoList";
import { UserProfileInfoProvider } from "./UserProfileInfoProvider";

const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    padding: "0 30px",
  },
  userInfoList: {
    height: "calc(100% - 40px)",
    overflow: "hidden auto",
  },
}));

const ProfileInfo = ({ beneficiary, enrolmentDetail }) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    { id: "overview-tab", label: "pages.beneficiaryDetail.tab.overview" },
    { id: "benefit-tab", label: "pages.beneficiaryDetail.tab.dceBenefit" },
    { id: "contact-tab", label: "pages.beneficiaryDetail.tab.contact" },
  ];

  const tabData = UserProfileInfoProvider({
    beneficiary,
    enrolment: enrolmentDetail,
  });

  return (
    <Box className={classes.container}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => setTabIndex(value)}
        tabs={tabs}
      />
      <Box className={classes.userInfoList}>
        <UserInfoList
          userData={tabData.filter((_, index) => index === tabIndex)[0]}
        />
      </Box>
    </Box>
  );
};

export { ProfileInfo };
