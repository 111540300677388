import { AddOutlined, CloseOutlined } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserInvitation,
  deleteUserInvitation,
  loadUserInvitations,
} from "../../../../actions/Beneficiaries";
import {
  CLEAR_INVITATIONS,
  CREATE_INVITATION_SUCCESS,
  DELETE_INVITATION_SUCCESS,
} from "../../../../constants/ActionTypes";
import {
  userInvitationsCountSelector,
  userInvitationsLoadingSelector,
  userInvitationsSelector,
} from "../../../../reducers/selectors";
import { getFormattedDate } from "../../../../util/dates";
import IntlMessages from "../../../../util/IntlMessages";
import { DataTable } from "../../../generic/DataTable/DataTable";
import { StatusDot } from "../../../generic/Status/StatusDot";
import { useIntl } from "react-intl";

const DATE_FORMAT = "DD/MM/YY";
const DATE_TIME_FORMAT = "DD/MM/YY HH:mm";

const getColor = (status) => {
  if (status !== null) {
    return status ? "success" : "danger";
  } else return null;
};

const useStyles = makeStyles(() => ({
  cell: {
    borderBottom: 0,
  },
  statusLabel: {
    fontSize: "14px",
  },
  deleteIcon: {
    color: "#7E7E7E",
  },
  buttonContainer: {
    position: "absolute",
    bottom: 0,
    padding: "30px 0",
    width: "calc(100% - 60px)",
    backgroundColor: "white",
  },
  createButton: {
    padding: "12px 24px",
    textTransform: "none",
  },
}));

const UserInvitationsList = ({ beneficiary, defaultPagination }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const invitations = useSelector(userInvitationsSelector);
  const count = useSelector(userInvitationsCountSelector);
  const loading = useSelector(userInvitationsLoadingSelector);

  defaultPagination = defaultPagination || {
    ordering: "-created",
    size: 1000,
  };

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_INVITATIONS });
  };

  useEffect(() => clear, []);
  // #endregion

  // #region Load user invitations
  const fetchData = () => {
    if (!loading && beneficiary) {
      dispatch(
        loadUserInvitations({ ...defaultPagination, userId: beneficiary?.id })
      );
    }
  };

  useEffect(() => fetchData(), [beneficiary]);
  // #endregion

  // #region Actions
  const createInvitation = async (event) => {
    event.preventDefault();
    const userId = beneficiary?.id;
    const result = await dispatch(createUserInvitation({ userId }));
    if (result.type === CREATE_INVITATION_SUCCESS) {
      fetchData();
      return NotificationManager.success(
        <IntlMessages id="pages.profile.invitations.createSuccess" />
      );
    }

    if (result.error?.response?.status === 400) {
      return NotificationManager.error(
        <IntlMessages id="pages.profile.invitations.createError" />
      );
    }
    return NotificationManager.error(
      <IntlMessages id="appModule.errorTryAgainLater" />
    );
  };

  const deleteInvitation = async (event, id) => {
    event.preventDefault();
    const result = await dispatch(deleteUserInvitation({ id }));
    if (result.type === DELETE_INVITATION_SUCCESS) {
      fetchData();
      NotificationManager.success(
        <IntlMessages id="pages.profile.invitations.deleteSuccess" />
      );
    } else {
      NotificationManager.error(
        <IntlMessages id="appModule.errorTryAgainLater" />
      );
    }
  };
  // #endregion

  const columnData = [
    {
      id: "code",
      align: "left",
      label: "common.code",
    },
    {
      id: "redeemed",
      align: "left",
      label: "common.redeemed",
      handler: (row) => (
        <StatusDot
          showLabel
          status={row.redeemed}
          statusValues={{ true: "common.yes", false: "common.no" }}
          colorClass={getColor(row.redeemed)}
          labelClass={classes.statusLabel}
        />
      ),
    },
    {
      id: "created",
      align: "left",
      label: "common.created",
      handler: (row) => getFormattedDate(row.created, DATE_FORMAT),
    },
    {
      id: "modified",
      align: "left",
      label: "common.lastUpdated",
      handler: (row) => getFormattedDate(row.modified, DATE_TIME_FORMAT),
    },
    {
      id: "valid_until",
      align: "left",
      label: "common.validUntil",
      handler: (row) =>
        row.redeemed
          ? "-"
          : getFormattedDate(row.valid_until, DATE_TIME_FORMAT),
    },
    {
      id: "is_valid",
      align: "left",
      label: "common.valid",
      handler: (row) => (
        <StatusDot
          showLabel
          status={row.is_valid && !row.redeemed}
          statusValues={{ true: "common.yes", false: "common.no" }}
          colorClass={getColor(row.is_valid && !row.redeemed)}
          labelClass={classes.statusLabel}
        />
      ),
    },
  ];

  const actions = [
    {
      id: "delete",
      label: "",
      handler: (row) => (
        <IconButton
          title={intl.formatMessage({ id: "common.delete" })}
          onClick={(event) => deleteInvitation(event, row.id)}
        >
          <CloseOutlined fontSize="small" className={classes.deleteIcon} />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      {!loading && beneficiary && (
        <DataTable
          id="invitationsList"
          columnData={columnData}
          tableData={invitations}
          actions={actions}
          initialPagination={defaultPagination}
          noSearchResultsText={
            <IntlMessages id="pages.profile.invitations.noInvitations" />
          }
          count={count}
          cellClass={classes.cell}
          showOnlyTable
          hideFooter
        />
      )}
      <Box className={classes.buttonContainer}>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<AddOutlined />}
          onClick={createInvitation}
          className={classes.createButton}
          disabled={!beneficiary?.email || beneficiary?.email === ""}
          fullWidth
          disableElevation
          disableRipple
        >
          <IntlMessages id="pages.profile.invitations.create" />
        </Button>
      </Box>
    </Box>
  );
};

export { UserInvitationsList };
