import { makeStyles } from "@mui/styles";
import { getFormattedDate } from "../../../../../util/dates";
import { getAgent } from "../../../../../util/enrolment";
import { EnrolmentSupportStatus } from "../../../../Enrolment/EnrolmentSupportStatus";
import { EnrolmentsList } from "../../../../Enrolment/EnrolmentsList/EnrolmentsList";
import { NotAvailable } from "../../../../generic/NotAvailable/NotAvailable";

const DATE_TIME_FORMAT = "DD/MM/YY HH:mm";

const useStyles = makeStyles({
  cell: {
    borderBottom: 0,
  },
});

const ProfileEnrolments = ({ beneficiary }) => {
  const classes = useStyles();

  const columnData = [
    {
      id: "created",
      align: "left",
      label: "common.requested",
      handler: (row) => getFormattedDate(row.created, DATE_TIME_FORMAT),
    },
    {
      id: "unjspf_uid",
      align: "left",
      label: "common.id",
      handler: (row) => row?.user?.unjspf_uuid,
    },
    {
      id: "status",
      align: "left",
      label: "common.status",
      handler: (row) => (
        <EnrolmentSupportStatus status={row?.workflow_status_unjspf} />
      ),
    },
    {
      id: "appointment_datetime",
      align: "left",
      label: "common.appointment",
      sortable: true,
      handler: (row) => {
        const apptStartDateTime = row?.appointment?.slot?.start_datetime;
        const appointmentInfo = apptStartDateTime
          ? getFormattedDate(apptStartDateTime, DATE_TIME_FORMAT)
          : null;
        return appointmentInfo || <NotAvailable />;
      },
    },
    {
      id: "assigned_agent",
      align: "left",
      label: "common.agent",
      sortable: true,
      handler: (row) => {
        return getAgent(row) || <NotAvailable />;
      },
    },
  ];

  const enrolmentsListProps = {
    beneficiary,
    defaultPagination: { ordering: "-created", size: 1000 },
    tableProps: { columnData, cellClass: classes.cell, hideFooter: true },
    showOnlyTable: true,
  };

  return beneficiary && <EnrolmentsList {...enrolmentsListProps} />;
};

export { ProfileEnrolments };
