import { Box } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { EnrolmentDetail } from "../../components/Enrolment/EnrolmentDetail/EnrolmentDetail";
import { EnrolmentsList } from "../../components/Enrolment/EnrolmentsList/EnrolmentsList";
import { ContainerHeader } from "../../components/generic/ContainerHeader/ContainerHeader";
import { ROUTES, absolutePath } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";

const Enrolment = () => {
  const location = useLocation();
  const urlMatch = location.pathname.split(ROUTES.ENROLMENT);

  const pagination = {
    ordering: "-created",
    page: 1,
    size: 10,
  };

  return (
    <Box className="app-wrapper">
      <ContainerHeader
        match={absolutePath(ROUTES.ENROLMENT)}
        title={
          <IntlMessages
            id={`pages.${urlMatch[1] === "" ? "enrolments" : "enrolmentDetail"}`}
          />
        }
      />
      <Routes>
        <Route
          path="/"
          element={<EnrolmentsList defaultPagination={pagination} />}
        />
        <Route path=":id" element={<EnrolmentDetail />} />
      </Routes>
    </Box>
  );
};

export { Enrolment };
