import { TextField } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector, useStore } from "react-redux";
import { userRecoverPassword } from "../../actions/Auth";
import { SubmitButton } from "../generic/Forms/SubmitButton/SubmitButton";
import {
  RECOVER_PASSWORD_FAIL,
  RECOVER_PASSWORD_SUCCESS,
} from "../../constants/ActionTypes";
import { ROUTES } from "../../constants/Routes";
import { getErrorMessage } from "../../util";
import IntlMessages from "../../util/IntlMessages";
import { useNavigate } from "react-router-dom";

const RecoverPassword = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState();
  const { loader } = useSelector(({ auth }) => auth);

  const intl = useIntl();

  const formIsValid = () => !!username;

  const submit = async () => {
    if (formIsValid()) {
      const result = await dispatch(userRecoverPassword({ username }));
      if (result.type === RECOVER_PASSWORD_SUCCESS) {
        NotificationManager.success(
          <IntlMessages id="recoverPassword.requestSuccess" />
        );
        navigate(`/${ROUTES.AUTH_SUCCESS}`);
      } else if (result.type === RECOVER_PASSWORD_FAIL) {
        let message = intl.formatMessage({ id: "recoverPassword.requestFail" });
        const errors = store.getState().auth.errors;
        if (errors) {
          message = `${message}: ${getErrorMessage(errors)}`;
        }
        NotificationManager.error(message);
      }
    }
  };

  return (
    <>
      <div className="app-login-header mb-4">
        <h1>
          <IntlMessages id="recoverPassword.requestTitle" />
        </h1>
      </div>

      <div>
        <IntlMessages id="recoverPassword.requestText" />:
      </div>

      <div className="app-login-form">
        <form>
          <fieldset>
            <TextField
              id="username"
              label={<IntlMessages id="appModule.login" />}
              fullWidth
              onChange={(event) => setUsername(event.target.value)}
              defaultValue={username}
              variant="standard"
              margin="normal"
              className="mt-1 my-sm-3"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  submit();
                }
              }}
            />
            <SubmitButton onSubmit={submit} loader={loader} />
          </fieldset>
        </form>
      </div>
    </>
  );
};

export { RecoverPassword };
