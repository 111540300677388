//#region Auth
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_SUCCESS";
export const SIGNIN_USER_FAIL = "signin_user_FAIL";
export const SIGNOUT_USER = "signout_user";
export const RECOVER_PASSWORD = "recover_password";
export const RECOVER_PASSWORD_SUCCESS = "recover_password_SUCCESS";
export const RECOVER_PASSWORD_FAIL = "recover_password_FAIL";
export const RECOVER_BENEFICIARY_SECURITY_CODE =
  "RECOVER_BENEFICIARY_SECURITY_CODE";
export const RECOVER_BENEFICIARY_SECURITY_CODE_SUCCESS =
  "RECOVER_BENEFICIARY_SECURITY_CODE_SUCCESS";
export const RECOVER_BENEFICIARY_SECURITY_CODE_FAIL =
  "RECOVER_BENEFICIARY_SECURITY_CODE_FAIL";
export const SET_PASSWORD = "set_password";
export const SET_PASSWORD_SUCCESS = "set_password_SUCCESS";
export const SET_PASSWORD_FAIL = "set_password_FAIL";
export const TOKEN_REFRESH = "TOKEN_REFRESH";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";
//#endregion

//#region User
export const LOAD_MY_USER_DATA = "LOAD_MY_USER_DATA";
export const LOAD_MY_USER_DATA_SUCCESS = "LOAD_MY_USER_DATA_SUCCESS";
export const LOAD_MY_USER_DATA_FAIL = "LOAD_MY_USER_DATA_FAIL";
export const LOAD_USERS_DATA = "LOAD_USERS_DATA";
export const LOAD_USERS_DATA_SUCCESS = "LOAD_USERS_DATA_SUCCESS";
export const LOAD_USERS_DATA_FAIL = "LOAD_USERS_DATA_FAIL";
export const CLEAR_USERS_DATA = "CLEAR_USERS_DATA";
export const CLEAR_MY_USER_DATA = "CLEAR_MY_USER_DATA";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const SET_MY_USER_DATA = "SET_MY_USER_DATA";
export const SET_MY_USER_DATA_SUCCESS = "SET_MY_USER_DATA_SUCCESS";
export const SET_MY_USER_DATA_FAIL = "SET_MY_USER_DATA_FAIL";
export const LOAD_USER_DETAIL = "LOAD_USER_DETAIL";
export const LOAD_USER_DETAIL_SUCCESS = "LOAD_USER_DETAIL_SUCCESS";
export const CLEAR_USER_DETAIL = "CLEAR_USER_DETAIL";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const SET_USER_OPT_OUT = "SET_USER_OPT_OUT";
export const SET_USER_OPT_OUT_SUCCESS = "SET_USER_OPT_OUT_SUCCESS";
export const SET_USER_OPT_OUT_FAIL = "SET_USER_OPT_OUT_FAIL";
export const USERS_FILTERS_LIST = "USERS_FILTERS_LIST";
//#endregion

//#region BeneficiariesList
export const LOAD_BENEFICIARIES = "LOAD_BENEFICIARIES";
export const LOAD_BENEFICIARIES_SUCCESS = "LOAD_BENEFICIARIES_SUCCESS";
export const LOAD_BENEFICIARIES_FAIL = "LOAD_BENEFICIARIES_FAIL";
export const CLEAR_BENEFICIARIES = "CLEAR_BENEFICIARIES";
export const BENEFICIARIES_FILTERS_LIST = "BENEFICIARIES_FILTERS_LIST";
//#endregion

//#region Beneficiary detail
export const LOAD_BENEFICIARY_DETAIL = "LOAD_BENEFICIARY_DETAIL";
export const LOAD_BENEFICIARY_DETAIL_SUCCESS =
  "LOAD_BENEFICIARY_DETAIL_SUCCESS";
export const LOAD_BENEFICIARY_DETAIL_FAIL = "LOAD_BENEFICIARY_DETAIL_FAIL";
export const CLEAR_BENEFICIARY_DETAIL = "CLEAR_BENEFICIARY_DETAIL";
//#endregion

//#region Enrolment
export const ENROLMENT_FILTERS_LIST = "ENROLMENT_FILTERS_LIST";
export const ENROLMENT_TOGGLE_ATTENTION = "ENROLMENT_TOGGLE_ATTENTION";
export const ENROLMENT_TOGGLE_ASSIGNED = "ENROLMENT_TOGGLE_ASSIGNED";
//#endregion

//#region On-boardings
export const LOAD_ENROLMENTS = "LOAD_ENROLMENTS";
export const LOAD_ENROLMENTS_SUCCESS = "LOAD_ENROLMENTS_SUCCESS";
export const LOAD_ENROLMENTS_FAIL = "LOAD_ENROLMENTS_FAIL";
export const CLEAR_ENROLMENTS = "CLEAR_ENROLMENTS";
export const LOAD_APPROVED_ENROLMENTS = "LOAD_APPROVED_ENROLMENTS";
export const LOAD_APPROVED_ENROLMENTS_SUCCESS =
  "LOAD_APPROVED_ENROLMENTS_SUCCESS";
export const LOAD_APPROVED_ENROLMENTS_FAIL = "LOAD_APPROVED_ENROLMENTS_FAIL";
export const CLEAR_APPROVED_ENROLMENTS = "CLEAR_APPROVED_ENROLMENTS";
export const BULK_ASSIGN_ENROLMENTS = "BULK_ASSIGN_ENROLMENTS";
export const BULK_ASSIGN_ENROLMENTS_SUCCESS = "BULK_ASSIGN_ENROLMENTS_SUCCESS";
export const BULK_ASSIGN_ENROLMENTS_FAIL = "BULK_ASSIGN_ENROLMENTS_FAIL";
//#endregion

//#region On-boarding detail
export const LOAD_ENROLMENT_DETAIL = "LOAD_ENROLMENT_DETAIL";
export const LOAD_ENROLMENT_DETAIL_SUCCESS = "LOAD_ENROLMENT_DETAIL_SUCCESS";
export const LOAD_ENROLMENT_DETAIL_FAIL = "LOAD_ENROLMENT_DETAIL_FAIL";
export const CLEAR_ENROLMENT_DETAIL = "CLEAR_ENROLMENT_DETAIL";

export const APPROVE_ENROLMENT = "APPROVE_ENROLMENT";
export const APPROVE_ENROLMENT_SUCCESS = "APPROVE_ENROLMENT_SUCCESS";
export const REJECT_ENROLMENT = "REJECT_ENROLMENT";
export const REJECT_ENROLMENT_SUCCESS = "REJECT_ENROLMENT_SUCCESS";

export const UPDATE_ENROLMENT = "UPDATE_ENROLMENT";
export const UPDATE_ENROLMENT_SUCCESS = "UPDATE_ENROLMENT_SUCCESS";
export const UPDATE_ENROLMENT_FAIL = "UPDATE_ENROLMENT_FAIL";
//#endregion

//#region Invitations
export const LOAD_INVITATIONS = "LOAD_INVITATIONS";
export const LOAD_INVITATIONS_SUCCESS = "LOAD_INVITATIONS_SUCCESS";
export const LOAD_INVITATIONS_FAIL = "LOAD_INVITATIONS_FAIL";
export const CREATE_INVITATION = "CREATE_INVITATION";
export const CREATE_INVITATION_SUCCESS = "CREATE_INVITATION_SUCCESS";
export const DELETE_INVITATION = "DELETE_INVITATION";
export const DELETE_INVITATION_SUCCESS = "DELETE_INVITATION_SUCCESS";
export const CLEAR_INVITATIONS = "CLEAR_INVITATIONS";
//#endregion

//#region Digital CoEs
export const LOAD_CERTIFICATES = "LOAD_CERTIFICATES";
export const LOAD_CERTIFICATES_SUCCESS = "LOAD_CERTIFICATES_SUCCESS";
export const LOAD_CERTIFICATES_FAIL = "LOAD_CERTIFICATES_FAIL";
export const CLEAR_CERTIFICATES_OF_ENTITLEMENT =
  "CLEAR_CERTIFICATES_OF_ENTITLEMENT";
export const VALIDATE_DCOE = "VALIDATE_DCOE";
export const VALIDATE_DCOE_SUCCESS = "VALIDATE_DCOE_SUCCESS";
export const VALIDATE_DCOE_FAIL = "VALIDATE_DCOE_FAIL";
//#endregion

//#region App installs
export const LOAD_APP_INSTALLS = "LOAD_APP_INSTALLS";
export const LOAD_APP_INSTALLS_SUCCESS = "LOAD_APP_INSTALLS_SUCCESS";
export const LOAD_APP_INSTALLS_FAIL = "LOAD_APP_INSTALLS_FAIL";
export const CLEAR_APP_INSTALLS = "CLEAR_APP_INSTALLS";
export const REVOKE_APP_INSTALL = "REVOKE_APP_INSTALL";
export const REVOKE_APP_INSTALL_SUCCESS = "REVOKE_APP_INSTALL_SUCCESS";
//#endregion

//#region Instant call
export const CREATE_INSTANT_CALL = "CREATE_INSTANT_CALL";
export const CREATE_INSTANT_CALL_SUCCESS = "CREATE_INSTANT_CALL_SUCCESS";
export const CREATE_INSTANT_CALL_FAIL = "CREATE_INSTANT_CALL_FAIL";
//#endregion

//#region Call events
export const CALL_EVENTS_REQUEST = "CALL_EVENTS_REQUEST";
export const LOAD_CALL_EVENTS = "LOAD_CALL_EVENTS";
export const LOAD_CALL_EVENTS_SUCCESS = "LOAD_CALL_EVENTS_SUCCESS";
export const LOAD_CALL_EVENTS_FAIL = "LOAD_CALL_EVENTS_FAIL";
export const CLEAR_CALL_EVENTS = "CLEAR_CALL_EVENTS";
//#endregion

//#region Schedule
export const LOAD_AVAILABILITY_SLOTS = "LOAD_AVAILABILITY_SLOTS";
export const LOAD_AVAILABILITY_SLOTS_SUCCESS =
  "LOAD_AVAILABILITY_SLOTS_SUCCESS";
export const LOAD_AVAILABILITY_SLOTS_FAIL = "LOAD_AVAILABILITY_SLOTS_FAIL";
export const CREATE_OPERATOR_AVAILABILITY_SLOTS =
  "CREATE_OPERATOR_AVAILABILITY_SLOTS";
export const CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS =
  "CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS";
export const CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL =
  "CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL";
export const DELETE_OPERATOR_AVAILABILITY_SLOT_SUCCESS =
  "DELETE_OPERATOR_AVAILABILITY_SLOT_SUCCESS";
export const DELETE_OPERATOR_AVAILABILITY_SLOT_FAIL =
  "DELETE_OPERATOR_AVAILABILITY_SLOT_FAIL";
export const CLEAR_AVAILABILITY_SLOTS = "CLEAR_AVAILABILITY_SLOTS";
export const LOAD_USER_VIDEO_URL = "LOAD_USER_VIDEO_URL";
export const LOAD_USER_VIDEO_URL_SUCCESS = "LOAD_USER_VIDEO_URL_SUCCESS";
export const LOAD_USER_VIDEO_URL_FAIL = "LOAD_USER_VIDEO_URL_FAIL";
export const CLEAR_VIDEO_CALL_URL = "CLEAR_VIDEO_CALL_URL";

export const LOAD_VERIFICATION_APPOINTMENTS = "LOAD_VERIFICATION_APPOINTMENTS";
export const LOAD_VERIFICATION_APPOINTMENTS_SUCCESS =
  "LOAD_VERIFICATION_APPOINTMENTS_SUCCESS";
export const LOAD_VERIFICATION_APPOINTMENTS_FAIL =
  "LOAD_VERIFICATION_APPOINTMENTS_FAIL";

export const DELETE_OPERATOR_APPOINTMENT = "DELETE_OPERATOR_APPOINTMENT";
export const DELETE_OPERATOR_APPOINTMENT_SUCCESS =
  "DELETE_OPERATOR_APPOINTMENT_SUCCESS";
export const DELETE_OPERATOR_APPOINTMENT_FAIL =
  "DELETE_OPERATOR_APPOINTMENT_FAIL";

export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";
export const BOOK_APPOINTMENT_SUCCESS = "BOOK_APPOINTMENT_SUCCESS";
export const BOOK_APPOINTMENT_FAIL = "BOOK_APPOINTMENT_FAIL";

export const CLEAR_VERIFICATION_APPOINTMENTS =
  "CLEAR_VERIFICATION_APPOINTMENTS";
//#endregion

//#region Common data
export const LOAD_ORGANIZATIONS = "LOAD_ORGANIZATIONS";
export const LOAD_ORGANIZATIONS_SUCCESS = "LOAD_ORGANIZATIONS_SUCCESS";
export const LOAD_ORGANIZATIONS_FAIL = "LOAD_ORGANIZATIONS_FAIL";
export const CLEAR_ORGANIZATIONS = "CLEAR_ORGANIZATIONS";

export const LOAD_REPORTED_COUNTRIES = "LOAD_REPORTED_COUNTRIES";
export const LOAD_REPORTED_COUNTRIES_SUCCESS =
  "LOAD_REPORTED_COUNTRIES_SUCCESS";
export const LOAD_REPORTED_COUNTRIES_FAIL = "LOAD_REPORTED_COUNTRIES_FAIL";
//#endregion

//#region Generic
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//#endregion

//#region Customizer
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";

export const LANGUAGE = "language";

export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const INSIDE_THE_HEADER = "inside_the_header";
export const HIDE_MESSAGE = "hide_message";
export const ON_SHOW_LOADER = "on_show_loader";
//#endregion
