import { Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ENROLMENT_WORKFLOW_STATUS_DISPLAY } from "../../../constants/common";
import IntlMessages from "../../../util/IntlMessages";
import { getColor } from "../../Enrolment/EnrolmentSupportStatus";

const StatusDot = ({
  status,
  statusValues = ENROLMENT_WORKFLOW_STATUS_DISPLAY,
  colorClass,
  colorStyle,
  showLabel,
  labelClass,
  alignItems,
}) => {
  const color = colorStyle || getColor(status);

  return (
    <Box display="flex" alignItems={alignItems ?? "center"}>
      {colorClass ? (
        <Circle className={`jr-fs-md m-0 text-${colorClass}`} />
      ) : (
        <Circle className="jr-fs-md m-0" style={{ color }} />
      )}
      {showLabel && (
        <Typography className={labelClass} style={{ paddingLeft: "10px" }}>
          <IntlMessages id={statusValues[status] ?? "common.notDefined"} />
        </Typography>
      )}
    </Box>
  );
};

export { StatusDot };
