import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressWithLabel = ({ children, value, ...props }) => {
  return (
    <CircularProgressbarWithChildren
      {...props}
      value={value}
      counterClockwise
      styles={{
        path: {
          stroke: getScoreColor(value),
        },
        trail: {
          stroke: "#F2F4F7",
        },
      }}
    >
      {isScoreValid(value) ? children : "NAN"}
    </CircularProgressbarWithChildren>
  );
};

const isScoreValid = (score) => {
  // Only validates percentage
  return score >= 0 && score <= 100;
};

const getScoreColor = (score) => {
  if (score >= 0 && score < 30) return "#C62222";
  if (score >= 30 && score < 60) return "#DBA33E";
  if (score >= 60 && score <= 100) return "#4DA551";
  return "#F2F4F7";
};

export { CircularProgressWithLabel };
