import { CreateOutlined, LockOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IntlMessages from "../../../../util/IntlMessages";

const useStyles = makeStyles({
  container: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    backgroundColor: "white",
    width: "100%",
    padding: "30px",
  },
  button: {
    padding: "12px 24px",
    textTransform: "none",
    "&:not(:first-child)": {
      marginLeft: "10px",
    },
  },
});

const ProfileActions = ({ edit, recoverSecurityCode }) => {
  const classes = useStyles();

  const actions = [
    {
      name: "edit",
      label: "pages.beneficiaryDetail.editUserInfo",
      icon: <CreateOutlined />,
      onClick: edit,
    },
    {
      name: "resetPinCode",
      label: "pages.beneficiaryDetail.resetPinCode",
      icon: <LockOutlined />,
      onClick: recoverSecurityCode,
    },
  ];

  return (
    <Box className={classes.container}>
      {actions.map((action) => (
        <Button
          key={action.name}
          variant="outlined"
          color="primary"
          endIcon={action.icon}
          onClick={() => action.onClick()}
          className={classes.button}
          fullWidth
          disableElevation
          disableRipple
        >
          <IntlMessages id={action.label} />
        </Button>
      ))}
    </Box>
  );
};

export { ProfileActions };
