import React from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../../actions/User";
import { CREATE_USER_SUCCESS } from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import { userErrors } from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Widget } from "../../generic/Widget/Widget";
import styles from "../User.module.scss";
import { UserForm } from "../UserForm";

const UserCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrors = useSelector(userErrors);

  const goToList = () => {
    return navigate(absolutePath(ROUTES.MANAGER_USERS));
  };

  const saveUser = async (data) => {
    const result = await dispatch(createUser({ data }));
    if (result?.type === CREATE_USER_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.user.createSuccess" />
      );
      goToList();
    }
  };

  return (
    <Widget styleName={styles.widget}>
      <UserForm
        apiErrors={apiErrors}
        saveAction={saveUser}
        cancelAction={goToList}
      />
    </Widget>
  );
};

export { UserCreate };
