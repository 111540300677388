import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadUserData,
  setOptOutForUser,
  updateUser,
} from "../../../actions/User";
import {
  CLEAR_USER_DETAIL,
  SET_USER_OPT_OUT_SUCCESS,
  UPDATE_USER_SUCCESS,
} from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import {
  userErrors,
  userLoadingSelector,
  usersDetailSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Widget } from "../../generic/Widget/Widget";
import { BeneficiaryUpdateForm } from "./BeneficiaryUpdateForm";

const BeneficiaryUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(usersDetailSelector);
  const userLoading = useSelector(userLoadingSelector);
  const apiErrors = useSelector(userErrors);

  const clear = () => {
    dispatch({ type: CLEAR_USER_DETAIL });
  };

  // Clear data on leave
  useEffect(() => clear, []);

  useEffect(() => {
    if (!user && !userLoading) {
      dispatch(loadUserData({ id }));
    }
  }, []);

  const goToProfile = () => {
    return navigate(absolutePath(`${ROUTES.BENEFICIARY}/${id}`));
  };

  const saveUser = async (data) => {
    const updateUserData = { ...data };
    delete updateUserData.opt_out_enabled;
    delete updateUserData.note;
    const resultUpdate = await dispatch(
      updateUser({ id, data: updateUserData })
    );

    const optOutData = {
      opt_out_enabled: data?.opt_out_enabled,
      opt_out_note: data?.opt_out_note || "-",
    };

    const resultSetOptOut = await dispatch(
      setOptOutForUser({ id, data: optOutData })
    );

    if (
      resultUpdate.type === UPDATE_USER_SUCCESS &&
      resultSetOptOut.type === SET_USER_OPT_OUT_SUCCESS
    ) {
      NotificationManager.success(
        <IntlMessages id="pages.user.updateSuccess" />
      );
      goToProfile();
    } else {
      NotificationManager.success(<IntlMessages id="pages.user.updateError" />);
    }
  };

  return (
    <Widget styleName="jr-card-profile">
      <div className="mb-3 col-m-6 d-sm-flex justify-content-sm-between">
        <h2 className="entry-heading mb-2 mb-md-3">
          <IntlMessages id="pages.user.update.title" />
        </h2>
      </div>
      {user && (
        <BeneficiaryUpdateForm
          beneficiary={user}
          apiErrors={apiErrors}
          saveAction={saveUser}
          cancelAction={goToProfile}
        />
      )}
    </Widget>
  );
};

export { BeneficiaryUpdate };
