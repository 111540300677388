import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userSetPassword } from "../../actions/Auth";
import {
  SET_PASSWORD_FAIL,
  SET_PASSWORD_SUCCESS,
} from "../../constants/ActionTypes";
import { ROUTES } from "../../constants/Routes";
import { getErrorMessage } from "../../util";
import IntlMessages from "../../util/IntlMessages";
import { AuthFormTextField } from "../generic/Forms/AuthFormTextField/AuthFormTextField";
import { SubmitButton } from "../generic/Forms/SubmitButton/SubmitButton";

const SetPassword = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useParams();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const { loader } = useSelector(({ auth }) => auth);
  const passwordInputRef = useRef(null);

  const intl = useIntl();

  const formIsValid = () => {
    if (password1 && password2) {
      if (password1 === password2) {
        return true;
      }
      NotificationManager.error(
        <IntlMessages id="recoverPassword.passwordsDoNotMatch" />
      );
    }
    return false;
  };

  const submit = async () => {
    if (formIsValid()) {
      const result = await dispatch(
        userSetPassword({ code, password: password1 })
      );
      if (result.type === SET_PASSWORD_SUCCESS) {
        NotificationManager.success(
          intl.formatMessage({ id: "recoverPassword.setPasswordSuccess" })
        );
        navigate(`/${ROUTES.AUTH_SUCCESS}`);
      } else if (result.type === SET_PASSWORD_FAIL) {
        let message = intl.formatMessage({
          id: "recoverPassword.setPasswordFail",
        });
        const errors = store.getState().auth.errors;
        if (errors) {
          message = `${message}: ${getErrorMessage(errors)}`;
        }
        NotificationManager.error(message);
      }
    }
  };
  return (
    <>
      <div className="app-login-header mb-4">
        <h1>
          <IntlMessages id="recoverPassword.setPasswordTitle" />
        </h1>
      </div>

      <div className="app-login-form">
        <form>
          <fieldset>
            <AuthFormTextField
              id="password1"
              type="password"
              label={<IntlMessages id="appModule.password" />}
              onChange={(event) => setPassword1(event.target.value)}
              defaultValue={password1}
              inputRef={passwordInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
            />

            <AuthFormTextField
              id="password2"
              type="password"
              label={<IntlMessages id="appModule.repeatPassword" />}
              onChange={(event) => setPassword2(event.target.value)}
              defaultValue={password2}
              inputRef={passwordInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  submit();
                }
              }}
            />

            <SubmitButton onSubmit={submit} loader={loader} />
          </fieldset>
        </form>
      </div>
    </>
  );
};

export { SetPassword };
