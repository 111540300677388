export default [
  {
    title: "common.yes",
    value: true,
  },
  {
    title: "common.no",
    value: false,
  },
];
