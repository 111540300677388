import { Button, FormHelperText, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../actions/User";
import {
  CLEAR_ERRORS,
  RESET_PASSWORD_SUCCESS,
} from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import { userErrors } from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Widget } from "../../generic/Widget/Widget";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiErrors = useSelector(userErrors);

  const intl = useIntl();
  const errorMessages = {
    cannotBeEmpty: intl.formatMessage({ id: "validation.fieldCannotBeEmpty" }),
    passwordsDontMatch: intl.formatMessage({
      id: "recoverPassword.passwordsDoNotMatch",
    }),
  };

  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errors, setErrors] = useState({});

  const clear = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  // Clear data on leave
  useEffect(() => clear, []);

  const goToMyProfile = () => {
    navigate(absolutePath(ROUTES.MY_PROFILE));
  };

  const displayErrors = (field) => {
    if (!errors[field]) {
      return "";
    }
    return errors[field].join(" ");
  };

  const isFormValid = () => {
    const formErrors = {};
    if (!newPassword) {
      formErrors.new_password = [errorMessages.cannotBeEmpty];
    }
    if (!newPassword2) {
      formErrors.new_password2 = [errorMessages.cannotBeEmpty];
    }

    if (newPassword !== newPassword2) {
      formErrors.new_password = [errorMessages.passwordsDontMatch];
      formErrors.new_password2 = [errorMessages.passwordsDontMatch];
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    const result = await dispatch(resetPassword({ newPassword }));
    if (result.type === RESET_PASSWORD_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="recoverPassword.setPasswordSuccess" />
      );
      goToMyProfile();
    }
  };

  useEffect(() => {
    if (apiErrors && Object.keys(apiErrors).length > 0) {
      setErrors(apiErrors);
    }
  }, [apiErrors]);

  return (
    <Widget styleName="jr-card-profile">
      <div className="user-profile">
        <div className="user-list card-strip">
          <div className="">
            <form onSubmit={handleSubmit}>
              <FormHelperText
                id="organization-error-text"
                error={!!errors.non_field_errors}
              >
                {errors.non_field_errors}
              </FormHelperText>
              <div className="description">
                <TextField
                  id="new_password"
                  name="new_password"
                  label={<IntlMessages id="pages.profile.newPassword" />}
                  type="password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  error={!!errors.new_password}
                  helperText={displayErrors("new_password")}
                />
              </div>

              <div className="description pt-3 mb-3">
                <TextField
                  id="new_password2"
                  name="new_password2"
                  label={<IntlMessages id="pages.profile.repeatPassword" />}
                  type="password"
                  onChange={(e) => setNewPassword2(e.target.value)}
                  size="small"
                  required
                  fullWidth
                  error={!!errors.new_password2}
                  helperText={displayErrors("new_password2")}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="d-flex flex-md-row flex-column justify-content-around pt-3">
          <Button
            id="cancel_button"
            color="secondary"
            variant="contained"
            onClick={goToMyProfile}
          >
            <IntlMessages id="common.cancel" />
          </Button>
          <Button
            id="save_button"
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            <IntlMessages id="common.save" />
          </Button>
        </div>
      </div>
    </Widget>
  );
};

export { ResetPasswordForm };
