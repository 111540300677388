import { DCE_SOLUTION_START_YEAR } from "../constants/common";

const solutionYears = () => {
  const thisYear = new Date().getFullYear();
  const arrayLength = DCE_SOLUTION_START_YEAR
    ? thisYear - DCE_SOLUTION_START_YEAR + 1
    : 0;
  return arrayLength > 0
    ? Array(arrayLength)
        .fill()
        .map((value, position) => DCE_SOLUTION_START_YEAR + position)
        .sort((prev, next) => next - prev)
    : [];
};

export { solutionYears };
