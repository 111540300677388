import { HelpOutlineOutlined, LockOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import packageJson from "../../../../package.json";
import { FAQ_URL } from "../../../constants/Routes";
import { ORG_SHORT_NAME } from "../../../util/appInfo";
import { MenuItem } from "../MenuItem";

const CURRENT_YEAR = new Date().getFullYear();

const FOOTER_ITEMS = [
  {
    id: "faq",
    route: FAQ_URL,
    icon: <HelpOutlineOutlined />,
    title: "appModule.FAQ",
    openBlank: true,
  },
  {
    id: "privacyPolicy",
    absoluteRoute: "/doc/privacy",
    icon: <LockOutlined />,
    title: "appModule.privacy",
  },
];

const Footer = () => {
  const intl = useIntl();
  const copyright = intl.formatMessage(
    { id: "copyright" },
    { org: ORG_SHORT_NAME, year: CURRENT_YEAR }
  );

  return (
    <footer className="app-footer">
      <ul className="nav-menu">
        {FOOTER_ITEMS.map((menuItem) => (
          <MenuItem key={menuItem.id} menuItem={menuItem} />
        ))}
      </ul>
      <Box className="separator" />
      <div className="mb-2">{copyright}</div>
      <div>{`v${packageJson.version}`}</div>
    </footer>
  );
};

export default Footer;
