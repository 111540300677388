import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadUserData } from "../../../actions/User";
import { CLEAR_USER_DETAIL } from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import {
  userLoadingSelector,
  usersDetailSelector,
} from "../../../reducers/selectors";
import { Widget } from "../../generic/Widget/Widget";
import styles from "../User.module.scss";
import { UserInfo } from "../UserInfo";

const UserProfile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(usersDetailSelector);
  const userLoading = useSelector(userLoadingSelector);

  useEffect(() => {
    !userLoading && dispatch(loadUserData({ id }));
  }, []);

  const actions = [
    {
      title: "pages.profile.edit",
      handler: () =>
        navigate(absolutePath(`${ROUTES.MANAGER_USERS}/${id}/update`)),
    },
  ];

  // #region Clear data on leave
  useEffect(() => clear, []);

  const clear = () => {
    dispatch({ type: CLEAR_USER_DETAIL });
  };
  // #endregion

  return (
    <Widget styleName={styles.widget}>
      {user ? <UserInfo user={user} actions={actions} /> : <></>}
    </Widget>
  );
};

export { UserProfile };
