import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  loadMyUserData,
  loadUserData,
  updateUser,
} from "../../../actions/User";
import {
  CLEAR_USER_DETAIL,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import {
  userDataSelector,
  userErrors,
  userIsBeneficiarySelector,
  userLoadingSelector,
  usersDetailSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Widget } from "../../generic/Widget/Widget";
import styles from "../User.module.scss";
import { UserForm } from "../UserForm";

const UserUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isBeneficiary = useSelector(userIsBeneficiarySelector);
  const currentUser = useSelector(userDataSelector);
  const user = useSelector(usersDetailSelector);
  const userLoading = useSelector(userLoadingSelector);
  const apiErrors = useSelector(userErrors);

  const clear = () => {
    dispatch({ type: CLEAR_USER_DETAIL });
  };

  // Clear data on leave
  useEffect(() => clear, []);

  useEffect(() => {
    if (!userLoading) {
      dispatch(loadUserData({ id }));
    }
  }, []);

  const goToProfile = () => {
    if (isBeneficiary) {
      return navigate(absolutePath(`${ROUTES.BENEFICIARY}/${id}`));
    }
    return navigate(absolutePath(`${ROUTES.MANAGER_USERS}/${id}`));
  };

  const saveUser = async (data) => {
    const result = await dispatch(updateUser({ id, data }));
    if (result.type === UPDATE_USER_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.user.updateSuccess" />
      );
      if (currentUser?.id === +id) {
        await dispatch(loadMyUserData());
      }
      goToProfile();
    } else if (result.type === UPDATE_USER_FAIL) {
      NotificationManager.error(<IntlMessages id="pages.user.updateError" />);
    }
  };

  return (
    <Widget styleName={styles.widget}>
      {user ? (
        <UserForm
          user={user}
          apiErrors={apiErrors}
          saveAction={saveUser}
          cancelAction={goToProfile}
          isBeneficiary={isBeneficiary}
        />
      ) : (
        <></>
      )}
    </Widget>
  );
};

export { UserUpdate };
