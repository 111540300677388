import {
  Add,
  AssignmentLateOutlined,
  DescriptionOutlined,
  NoSimOutlined,
  Remove,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import cl from "classnames";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { cloneElement } from "react";
import { getFormattedDate } from "../../../../../util/dates";
import IntlMessages from "../../../../../util/IntlMessages";
import { StatusDot } from "../../../../generic/Status/StatusDot";
import { useIntl } from "react-intl";

dayjs.extend(isSameOrAfter);

const LONG_DATE_TIME_FORMAT = "MMMM Do, YYYY - hh:mm a";
const SHORT_DATE_TIME_FORMAT = "DD/MM/YY hh:mm a";

const useStyles = makeStyles((theme) => ({
  accordion: {
    borderRadius: "10px",
    border: "1px solid #7E7E7E80",
    boxShadow: "none",
    marginBottom: "16px",
    "&::before": {
      backgroundColor: "unset",
    },
  },
  accordionSummary: {
    padding: "0 20px",
    "&.expanded": {
      borderBottom: "1px solid #7E7E7E80",
    },
  },
  accordionSummaryContent: {
    margin: "16px 0",
    "&.Mui-expanded": {
      margin: "16px 0",
    },
  },
  iconContainer: {
    height: "60px",
    aspectRatio: "1/1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.success.main,
    color: "white",
    borderRadius: "100px",
    "&.expired": {
      backgroundColor: "#7E7E7E",
    },
    "&.hasErrors": {
      backgroundColor: theme.palette.error.main,
    },
  },
  icon: {
    width: "50%",
    height: "unset",
    aspectRatio: "1/1",
  },
  summaryItem: {
    marginLeft: "12px",
  },
  summaryTitle: {
    color: "#202020",
    fontWeight: 700,
    marginBottom: "5px",
  },
  accordionDetails: {
    padding: "20px 30px",
  },
  detailContainer: {
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  detailContLarge: {
    marginBottom: "30px",
  },
  detailTitle: {
    color: "#7E7E7E",
  },
}));

const CertificateOfEntitlement = ({ certificate, expanded, handleChange }) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    country: countryCode,
    liveness_confirmation: proof,
    expiration_date: expirationDate,
    created: creationDate,
  } = certificate;

  const { country, biometric_validation_passed: biometricsPassed } = proof;

  const certificateHasExpired = dayjs().isSameOrAfter(dayjs(expirationDate));
  const proofDateTime =
    creationDate && getFormattedDate(creationDate, LONG_DATE_TIME_FORMAT);
  const expirationDateTime =
    expirationDate && getFormattedDate(expirationDate, SHORT_DATE_TIME_FORMAT);
  const proofHasErrors = !proof.task_status;
  const icon = proofHasErrors ? (
    <NoSimOutlined />
  ) : certificateHasExpired ? (
    <AssignmentLateOutlined />
  ) : (
    <DescriptionOutlined />
  );
  const title = proofHasErrors
    ? "pages.profile.dce.status.hasErrors"
    : certificateHasExpired
      ? "pages.profile.dce.status.expired"
      : "pages.profile.dce.status.valid";
  const issuedDate = intl.formatMessage(
    { id: "pages.profile.dce.issuedOn" },
    { date: proofDateTime }
  );

  const details = [
    {
      field: "countryCode",
      title: "pages.profile.dce.attr.countryCode",
      value: countryCode,
    },
    {
      field: "expirationDate",
      title: "pages.profile.dce.attr.expirationDate",
      value: expirationDateTime,
      largeMargin: true,
    },
    {
      field: "biometricTest",
      title: "pages.profile.dce.attr.biometricTest",
      render: (
        <StatusDot
          showLabel
          status={biometricsPassed}
          statusValues={{
            true: "pages.profile.dce.attr.biometricTest.correct",
            false: "pages.profile.dce.attr.biometricTest.error",
          }}
          colorClass={biometricsPassed ? "success" : "danger"}
        />
      ),
    },
    {
      field: "country",
      title: "pages.profile.dce.attr.country",
      value: country.name,
    },
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className={classes.accordion}
      square
    >
      <AccordionSummary
        expandIcon={expanded ? <Remove /> : <Add />}
        className={cl(classes.accordionSummary, expanded && "expanded")}
        classes={{ content: classes.accordionSummaryContent }}
      >
        <Grid container>
          <Grid
            item
            className={cl(
              classes.iconContainer,
              certificateHasExpired && "expired",
              proofHasErrors && "hasErrors"
            )}
          >
            {cloneElement(icon, { color: "inherit", className: classes.icon })}
          </Grid>
          <Grid item xs className={classes.summaryItem}>
            <Typography variant="h5" className={classes.summaryTitle}>
              <IntlMessages id={title} />
            </Typography>
            <Typography variant="body2">{issuedDate}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid container>
          {details &&
            details.map((detail) => {
              return (
                <Grid
                  key={detail.field}
                  container
                  item
                  xs={12}
                  className={
                    detail.largeMargin
                      ? classes.detailContLarge
                      : classes.detailContainer
                  }
                >
                  <Grid item xs={4}>
                    <Typography className={classes.detailTitle}>
                      <IntlMessages id={detail.title} />
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {detail.render ?? <Typography>{detail.value}</Typography>}
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export { CertificateOfEntitlement };
