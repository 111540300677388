export const ENROLMENT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
  WITHOUT: 3,
};

export const ENROLMENT_WORKFLOW_STATUS = {
  REQUIRES_ATTENTION: 10,
  AGENT_ASSIGNED: 20,
  UNDER_REVIEW: 30,
  APPROVED: 40,
  REJECTED: 50,
};

export const ENROLMENT_WORKFLOW_STATUS_DISPLAY = {
  [ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION]:
    "enrolment.status.requiresAttention",
  [ENROLMENT_WORKFLOW_STATUS.AGENT_ASSIGNED]: "enrolment.status.agentAssigned",
  [ENROLMENT_WORKFLOW_STATUS.UNDER_REVIEW]: "enrolment.status.underReview",
  [ENROLMENT_WORKFLOW_STATUS.APPROVED]: "enrolment.status.approved",
  [ENROLMENT_WORKFLOW_STATUS.REJECTED]: "enrolment.status.rejected",
  [null]: "enrolment.status.noEnrolment",
  [undefined]: "enrolment.status.noEnrolment",
};

export const ENROL_REJECT_FLOW_STEP = {
  FACE_PIC_STATUS: 0,
  ID_DOC_STATUS: 1,
  OTHER_REASONS: 2,
};

export const ENROL_REJECT_REASONS = {
  [ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS]: "face",
  [ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS]: "issue_id",
  [ENROL_REJECT_FLOW_STEP.OTHER_REASONS]: "other",
};

export const ENROL_REJECT_FACE = {
  BLURRED: 0,
  MISMATCH_IDDOC: 1,
  VALID: 2,
};

export const ENROL_REJECT_ID_DOC = {
  VALID: 0,
  EXPIRED: 1,
  BLURRED: 2,
};

export const ENROL_REJECT_FACE_OPTIONS = [
  {
    value: ENROL_REJECT_FACE.BLURRED,
    title: "pages.enrolmentDetail.rejectionReason.face.blurred",
  },
  {
    value: ENROL_REJECT_FACE.MISMATCH_IDDOC,
    title: "pages.enrolmentDetail.rejectionReason.face.mismatchIdDoc",
  },
  {
    value: ENROL_REJECT_FACE.VALID,
    title: "pages.enrolmentDetail.rejectionReason.face.valid",
    separator: true,
    labelStyle: { color: "#4DA551" },
  },
];

export const ENROL_REJECT_ID_DOC_OPTIONS = [
  {
    value: ENROL_REJECT_ID_DOC.EXPIRED,
    title: "pages.enrolmentDetail.rejectionReason.idDoc.expired",
  },
  {
    value: ENROL_REJECT_ID_DOC.BLURRED,
    title: "pages.enrolmentDetail.rejectionReason.idDoc.blurred",
  },
  {
    value: ENROL_REJECT_ID_DOC.VALID,
    title: "pages.enrolmentDetail.rejectionReason.idDoc.valid",
    separator: true,
    labelStyle: { color: "#4DA551" },
  },
];

export const CSRF_TOKEN_HEADER = "X-CSRFToken";
export const CSRF_TOKEN_COOKIE_NAME = "csrftoken";

export const APP_INSTALL_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REVOKED: 2,
};

export const API_MAX_PAGE_SIZE = 100;

export const DATE_TIME_SHORT_FORMAT = "MM/D/YY, h:mm a";
export const DATE_TIME_FORMAT = "MMM D YYYY, h:mm:ss a";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";

export const FORM_SUBMISSION_STATUS = {
  LOCAL_DATA: 0,
  SUBMITTED: 1,
  ERROR: 2,
  SUCCESS: 3,
  LOCAL_ERROR: 4,
};

export const EVENT_REPETITION = {
  NONE: "none",
  DAILY: "daily",
  WEEKLY: "weekly",
  MONTHLY: "monthly",
};

export const EVENT_REPETITION_INCREMENT = {
  [EVENT_REPETITION.NONE]: null,
  [EVENT_REPETITION.DAILY]: "days",
  [EVENT_REPETITION.WEEKLY]: "weeks",
  [EVENT_REPETITION.MONTHLY]: "months",
};

export const CALENDAR_START_TIME = "6:56:00";
export const CALENDAR_SLOT_DURATION = "00:15:00";
export const CALENDAR_HEIGHT = "45rem";

export const DCE_SOLUTION_START_YEAR = 2021;

export const CALENDAR_VIEWS = {
  MONTH: "dayGridMonth",
  WEEK: "timeGridWeek",
  DAY: "timeGridDay",
  LIST: "listWeek",
};

export const ID_DOCUMENT_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
};

export const CALL_EVENT_TYPE = {
  REQUEST: "req",
  ANSWERED: "ans",
  ENDED: "end",
};

export const CALL_STATUS = {
  PERFORMED: "performed",
  ATTEMPTED: "attempted",
  INTERRUPTED: "interrupted",
};

export const CALL_STATUS_VALUES = Object.fromEntries(
  Object.values(CALL_STATUS).map((status) => {
    return [status, `common.call.status.${status}`];
  })
);
