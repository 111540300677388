import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import { authSuccessMessageSelector } from "../../reducers/selectors";
import IntlMessages from "../../util/IntlMessages";

const Success = () => {
  const navigate = useNavigate();
  const successMessage = useSelector(authSuccessMessageSelector);
  const redirectToSignIn = () => navigate(`/${ROUTES.SIGN_IN}`);

  useEffect(() => {
    if (!successMessage) {
      redirectToSignIn();
      return;
    }
    setTimeout(redirectToSignIn, 4000);
  }, []);

  return successMessage ? (
    <>
      <div className="app-login-header mb-4">
        <h1>
          <IntlMessages id="sweetAlerts.success" />
        </h1>
      </div>
      <div className="app-login-form">
        <span>
          <IntlMessages id={successMessage} />.
        </span>
      </div>
    </>
  ) : (
    <></>
  );
};

export { Success };
