import { Box } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { BeneficiariesList } from "../../components/Beneficiary/BeneficiariesList/BeneficiariesList";
import { BeneficiaryDetail } from "../../components/Beneficiary/BeneficiaryDetail";
import { BeneficiaryUpdate } from "../../components/Beneficiary/BeneficiaryUpdate/BeneficiaryUpdate";
import { ContainerHeader } from "../../components/generic/ContainerHeader/ContainerHeader";
import { ROUTES, absolutePath } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";

const Beneficiary = () => {
  const location = useLocation();
  const urlMatch = location.pathname.split(ROUTES.BENEFICIARY);
  const pagination = {
    ordering: "-created",
    size: 10,
  };

  return (
    <Box className="app-wrapper">
      <ContainerHeader
        match={absolutePath(ROUTES.BENEFICIARY)}
        title={
          <IntlMessages
            id={`pages.${urlMatch[1] === "" ? "beneficiaries" : "beneficiaryDetail"}`}
          />
        }
      />
      <Routes>
        <Route
          path="/"
          element={<BeneficiariesList defaultPagination={pagination} />}
        />
        <Route path=":id" element={<BeneficiaryDetail />} />
        <Route path=":id/update" element={<BeneficiaryUpdate />} />
      </Routes>
    </Box>
  );
};

export { Beneficiary };
