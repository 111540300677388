import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedNav } from "../../actions/Settings";
import Footer from "../../components/generic/Footer/Footer";
import { Logo } from "../../components/generic/Logo";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../../constants/ActionTypes";
import {
  drawerTypeSelector,
  languageSelector,
  navCollapsedSelector,
  navigationStyleSelector,
} from "../../reducers/selectors";
import { SidenavContent } from "./SidenavContent";

const SideNav = () => {
  const dispatch = useDispatch();
  const drawerType = useSelector(drawerTypeSelector);
  const navCollapsed = useSelector(navCollapsedSelector);
  const navigationStyle = useSelector(navigationStyleSelector);
  const language = useSelector(languageSelector);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, [dispatch]);

  const onToggleCollapsedNav = () => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  let drawerStyle = drawerType?.includes(FIXED_DRAWER)
    ? "d-xl-flex"
    : drawerType?.includes(COLLAPSED_DRAWER)
      ? ""
      : "d-flex";
  let type = "permanent";
  if (
    drawerType?.includes(COLLAPSED_DRAWER) ||
    (drawerType?.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = "temporary";
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = "";
    type = "temporary";
  }
  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes("temporary") ? navCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{ paper: "side-nav" }}
      >
        <Logo language={language} className="logo" />
        <SidenavContent />
        <Footer />
      </Drawer>
    </div>
  );
};

export { SideNav };
