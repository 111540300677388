import { NavigateBefore } from "@mui/icons-material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadEnrolmentDetailV2 } from "../../../actions/Beneficiaries";
import { CLEAR_ENROLMENT_DETAIL } from "../../../constants/ActionTypes";
import {
  enrolmentDetailSelector,
  enrolmentsLoadingSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { EnrolmentAppointment } from "../../generic/FilterSidebar/components/EnrolmentAppointment/EnrolmentAppointment";
import { SideBar } from "../../generic/SideBar";

const useStyles = makeStyles(() => ({
  goBack: {
    justifyContent: "start",
    fontSize: "16px",
    textTransform: "none",
  },
}));

const EditAppointment = ({
  appointment,
  goBack,
  saveAppointment,
  toggleDrawer,
}) => {
  // #region Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const enrolment = useSelector(enrolmentDetailSelector);
  const loading = useSelector(enrolmentsLoadingSelector);
  const [values, setValues] = useState(null);
  // #endregion

  // #region Load onboarding
  useEffect(() => {
    const { id } = appointment?.onboarding || {};
    if (!enrolment && !loading) dispatch(loadEnrolmentDetailV2({ id }));

    // Cleanup
    return () => dispatch({ type: CLEAR_ENROLMENT_DETAIL });
  }, [appointment]);
  // #endregion

  return (
    <>
      <SideBar
        title="pages.schedule.appointment.title"
        toggleDrawer={toggleDrawer}
        cancelLabel="common.cancel"
        cancelFn={() => goBack(appointment)}
        saveLabel="common.saveChanges"
        saveFn={() => saveAppointment(values)}
        saveDisabled={
          !enrolment ||
          !values ||
          !values.selectedDate ||
          !values.selectedSlot ||
          !values.selectedAgent
        }
      >
        {/* Go back */}
        <Button
          color="primary"
          className={classes.goBack}
          startIcon={<NavigateBefore />}
          onClick={() => goBack(appointment)}
          disableElevation
        >
          <IntlMessages id="pages.schedule.appointment.edit" />
        </Button>
        {/* Enrolment appointment */}
        {!!enrolment && (
          <EnrolmentAppointment
            enrolment={enrolment}
            setAppointment={setValues}
          />
        )}
      </SideBar>
    </>
  );
};

export { EditAppointment };
