import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Link } from "react-router-dom";
import { showAuthLoader, userSignIn } from "../../actions/Auth";
import { SIGNIN_USER_FAIL } from "../../constants/ActionTypes";
import { getErrorMessage } from "../../util";
import IntlMessages from "../../util/IntlMessages";
import { AuthFormTextField } from "../generic/Forms/AuthFormTextField/AuthFormTextField";
import { SubmitButton } from "../generic/Forms/SubmitButton/SubmitButton";

const SignIn = () => {
  const store = useStore();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const { loader } = useSelector(({ auth }) => auth);
  const intl = useIntl();

  const passwordInputRef = useRef(null);

  const formIsValid = () => email && password;

  const submit = async () => {
    if (formIsValid()) {
      dispatch(showAuthLoader());
      const result = await dispatch(userSignIn({ email, password }));
      if (result.type === SIGNIN_USER_FAIL) {
        let message = intl.formatMessage({ id: "signIn.error" });
        const errors = store.getState().auth.errors;
        if (errors) {
          message = `${message}: ${getErrorMessage(errors)}`;
        }
        NotificationManager.error(message);
      }
    }
  };

  return (
    <>
      <div className="app-login-header mb-4">
        <h1>
          <IntlMessages id="appModule.logIn" />
        </h1>
      </div>

      <div className="app-login-form">
        <form>
          <fieldset>
            <AuthFormTextField
              id="username"
              label={<IntlMessages id="appModule.email" />}
              onChange={(event) => setEmail(event.target.value)}
              defaultValue={email}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  passwordInputRef.current.focus();
                }
              }}
            />

            <AuthFormTextField
              id="password"
              type="password"
              label={<IntlMessages id="appModule.password" />}
              onChange={(event) => setPassword(event.target.value)}
              defaultValue={password}
              inputRef={passwordInputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  submit();
                }
              }}
            />

            <SubmitButton onSubmit={submit} loader={loader} />

            <div className="mb-3 d-flex align-items-center justify-content-between text-center">
              <Link to="/auth/recoverpassword">
                <IntlMessages id="appModule.forgotPassword" />
              </Link>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};

export { SignIn };
