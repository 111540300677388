import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { cloneElement } from "react";
import IntlMessages from "../../../../util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "1.25em",
    color: theme.palette.primary.main,
    paddingRight: "10px",
  },
  label: {
    fontSize: "1rem",
  },
  value: {
    display: "flex",
    justifyContent: "end",
  },
}));

const DetailItem = ({ icon, label, value }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs className={classes.labelContainer}>
        {cloneElement(icon, { className: classes.icon })}
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.label}
        >
          <IntlMessages id={label} />
        </Typography>
      </Grid>
      <Grid item xs className={classes.value}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

export { DetailItem };
