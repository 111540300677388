import {
  LOAD_ORGANIZATIONS,
  LOAD_REPORTED_COUNTRIES,
} from "../constants/ActionTypes";
import { API_MAX_PAGE_SIZE } from "../constants/common";

export const loadOrganizations = () => ({
  type: LOAD_ORGANIZATIONS,
  payload: {
    request: {
      url: "unjspfadmin/manager/organization",
      method: "GET",
      params: {
        ordering: "name",
        size: API_MAX_PAGE_SIZE,
      },
    },
  },
});

export const loadReportedCountries = () => ({
  type: LOAD_REPORTED_COUNTRIES,
  payload: {
    request: {
      url: "unjspfadmin/manager/reported_countries",
      method: "GET",
    },
  },
});
