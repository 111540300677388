module.exports = `
<p><strong><span style="font-size:21px;line-height:107%;">UNITED NATIONS JOINT STAFF PENSION FUND&rsquo;S PRIVACY POLICY&nbsp;</span></strong></p>
<p>The UNJSPF website is designed as a resource for participants and beneficiaries of the United Nations Joint Staff Pension Fund (&ldquo;UNJSPF&rdquo; or &ldquo;Fund&rdquo;), as well as the member organizations of the Fund. UNJSPF is committed to protecting the privacy of its participants and beneficiaries and to responsible information handling. &nbsp;&nbsp;</p>
<p>The terms and conditions of use for this website are outlined in the United Nations Joint Staff Pension Fund&rsquo;s Privacy Policy. When you use or access information on this site, you agree to the terms and conditions stated here. <strong>If you do not agree to these terms, do not use/access information on this site. &nbsp;</strong></p>
<p>The UNJSPF website and computer systems are for legal and authorized use only. Individuals using this system without authority, or in excess of their authority, are subject to have their activities monitored and recorded. If we believe an attempt is being made through the Internet to break into or otherwise abuse our computer systems or business information, we will use the information in our computer logs, and other means available, to identify those responsible. As part of these legal efforts, we may share the information we gather with law enforcement agencies or other authorized entities. &nbsp;</p>
<h1>DISCLAIMER</h1>
<p>As a subsidiary organ established by the United Nations General Assembly, UNJSPF enjoys the same privileges and immunities as the United Nations organization. Therefore, UNJSPF is not bound by legislation in any jurisdiction, nor by the jurisdiction of national courts. UNJSPF is governed solely by its Regulations and Rules. &nbsp;&nbsp;</p>
<p>While UNJSPF makes every attempt to ensure the information provided on this site and in the Member Self-Service portal (&ldquo;MSS&rdquo;) is reliable, human or mechanical error remains a possibility. Therefore, UNJSPF does not guarantee the accuracy, completeness, timeliness, or correct sequencing of information, and will not be held responsible for any errors or omissions, or for the use of results obtained from the use of this information. &nbsp;</p>
<p>If there is a conflict between the information provided on our website and the UNJSPF Regulations and Rules, any decisions will be based on the UNJSPF Regulations and Rules, and not the information on this website.</p>
<h1>PRIVACY &nbsp;</h1>
<p>Your privacy is important to us. This Privacy Policy explains how the UNJSPF collects, protects, uses, and shares information. We may update this Privacy Policy from time to time as we adopt new privacy policies or personal data practices.</p>
<p>By accessing this site, certain information about you, such as Internet protocol (&ldquo;IP&rdquo;) addresses, navigation through the Site, the software used and the time spent, along with other similar information, will be stored on UNJSPF servers. This will not specifically identify you. This information will be used internally only for web site traffic analysis.<span style="font-size:15px;line-height:104%;">&nbsp;</span></p>
<p>You may also choose to provide personal information to UNJSPF while using the website. Personal information means personally identifiable information such as:</p>
<ul style="list-style-type: undefined;margin-left:17px;">
  <li>Information you provide via forms or other online fields. &nbsp;</li>
  <li>Information you provide to us via secure channels. &nbsp;</li>
</ul>
<p>We use information provided to us towards the purposes we specifically disclose at the time you provide or we collect your information.</p>
<p>In accordance with UNJSPF Administrative Rules, information provided by or in respect of you shall not be disclosed without your written consent or authorization. &nbsp;</p>
<p>We keep your personal information for as long as is necessary for the purposes of:</p>
<ul style="list-style-type: undefined;margin-left:17px;">
  <li>Our relationship with you or your Employing Organization</li>
  <li>Payment of benefits arising from your participation in the Fund</li>
  <li>Complying with UNJSPF Regulations and Rules</li>
  <li>Internal administrative or security needs</li>
</ul>
<h1>SECURITY</h1>
<p>The Fund takes information security seriously and has dedicated staff overseeing and maintaining the information management systems and all of the Fund&rsquo;s external and internal processes. The Fund is ISO 27001 compliant and has more than 100 controls regulating all aspects of the Fund&rsquo;s software and hardware, from cryptography to asset management. The Rules and Regulations of the Fund and the Information Security Policy emphasize and define how confidential data must be treated, how information is to be protected and what measures must be taken to guarantee business continuity if there is a disaster.</p>
<p>We protect your account information by placing it in the MSS portal, which is a secure portion of our website. That is why you have to use your unique and secure login credentials to access your account information. &nbsp;</p>
<p>While UNJSPF works continuously to keep your information safe and secure, you also play an important role in securing your own information. It is your responsibility to safeguard your username and password to the MSS portal. &nbsp;</p>
<p>UNJSPF will never send or ask for personal information such as account numbers, PIN, or passwords via e-mail or text messages.</p>
<p>Please see <u><span style="color:blue;">Your Pension Data Security</span></u> for more information on possible fraud and reporting. &nbsp;</p>
<p><strong>&nbsp;</strong></p>
<p><strong>&nbsp;</strong></p>
<h1>UN PRINCIPLES ON PERSONAL DATA PROTECTION AND PRIVACY</h1>
<p>The UNJSPF Privacy Policy is guided by a set of principles established for all United Nations System Organizations, which may be viewed in their entirety at <u><span style='font-size:15px;line-height:104%;font-family:"Arial",sans-serif;color:blue;'>UN Principles on Personal Data</span></u><span style='font-size:15px;line-height:104%;font-family:"Arial",sans-serif;color:blue;'>&nbsp;<u>Protection and Privacy</u></span><span style='font-size:15px;line-height:104%;font-family:"Arial",sans-serif;'>.&nbsp;</span></p>
<h1>SOCIAL MEDIA &nbsp;&nbsp;</h1>
<p>UNJSPF&rsquo;s social media pages exist for informational purposes only. The information contained on UNJSPF&rsquo;s social media pages is not intended as a substitute for UNJSPF&rsquo;s Regulations and Rules, nor will its interpretation prevail should a conflict arise between it and UNJSPF&rsquo;s Regulations and Rules.</p>
<p>All use of UNJSPF&rsquo;s social media pages shall be in accordance with the terms of use of each social media site, as well as all applicable laws.</p>
<p>UNJSPF reserves the right to remove content at any time at its sole discretion. Any posts naming specific individuals, including UNJSPF employees, will be removed. Posts containing political or campaign statements, false or misleading statements, as well as any form of advertising, will also be removed.</p>
<p>Comments posted by third parties on UNJSPF&rsquo;s social media pages do not reflect the views of UNJSPF, its board, or employees.</p>
<p>Please do not post personal or confidential information on UNJSPF social media pages. If you are a UNJSPF participant or beneficiary and have a specific question about your account, please contact UNJSPF directly through <u><span style="color:blue;">Contact Us</span></u>.</p>
<h1>MOBILE APPLICATIONS</h1>
<p>Use of UNJSPF mobile applications (including the UNJSPF Digital Certificate of Entitlement mobile application) is subject to acceptance of the United Nations Joint Staff Pension Fund&rsquo;s Privacy</p>
<p>Policy. &nbsp;&nbsp;</p>
<h1>UPDATES TO THE PRIVACY POLICY</h1>
<p>As UNJSPF tools and services change from time to time, this Privacy Policy is expected to change as well. We reserve the right to amend the Privacy Statement at any time, for any reason, without notice. We may email periodic reminders of our privacy policies and may email participants and beneficiaries of material changes. However, you should check our website frequently to see the current Privacy Policy that is in effect. We will revise the effective date stated at the bottom of this page when any material changes are made to the policy.</p>
<h1>EFFECTIVE DATE<span style="font-weight:normal;">: 4 May 2020</span></h1>
`;
