import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loadBeneficiaries,
  setFiltersList,
} from "../../../actions/Beneficiaries";
import {
  loadOrganizations,
  loadReportedCountries,
} from "../../../actions/Common";
import { CLEAR_BENEFICIARIES } from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import { ROLES } from "../../../constants/User";
import {
  ENROLMENT_WORKFLOW_STATUS,
  ENROLMENT_WORKFLOW_STATUS_DISPLAY,
} from "../../../constants/common";
import { INIT_STATE } from "../../../reducers/Beneficiaries";
import {
  beneficiariesCountSelector,
  beneficiariesFiltersListSelector,
  beneficiariesLoadingSelector,
  beneficiariesSelector,
  loadingOrganizationsSelector,
  loadingReportedCountriesSelector,
  organizationsSelector,
  reportedCountriesSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { getFormattedDate } from "../../../util/dates";
import { solutionYears } from "../../../util/solutionYears";
import { getFullName } from "../../../util/user";
import {
  EnrolmentSupportStatus,
  EnrolmentSupportStatusDot,
} from "../../Enrolment/EnrolmentSupportStatus";
import { DataTable } from "../../generic/DataTable/DataTable";
import { FiltersSidebar } from "../../generic/FilterSidebar/FilterSidebar";
import { FiltersHeader } from "../../generic/FiltersHeader/FiltersHeader";
import { NotAvailable } from "../../generic/NotAvailable/NotAvailable";
import { Status } from "../../generic/Status/Status";
import { Widget } from "../../generic/Widget/Widget";

const BeneficiariesList = ({ defaultPagination }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const beneficiaries = useSelector(beneficiariesSelector);
  const beneficiariesFiltersList = useSelector(
    beneficiariesFiltersListSelector
  );
  const organizations = useSelector(organizationsSelector);
  const reportedCountries = useSelector(reportedCountriesSelector);
  const loadingReportedCountries = useSelector(
    loadingReportedCountriesSelector
  );
  const count = useSelector(beneficiariesCountSelector);
  const loading = useSelector(beneficiariesLoadingSelector);
  const loadingOrganizations = useSelector(loadingOrganizationsSelector);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);

  defaultPagination = defaultPagination || {
    ordering: "-created",
    page: 1,
    size: 10,
  };

  const getBeneficiaryDetailPath = (row) => {
    return `${ROUTES.BENEFICIARY}/${row.id}`;
  };

  const openBeneficiary = (row) => {
    const beneficiaryPath = getBeneficiaryDetailPath(row);
    navigate(absolutePath(beneficiaryPath));
  };

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_BENEFICIARIES });
  };

  useEffect(() => clear, []);
  // #endregion

  const useMountEffect = (fun) => useEffect(fun, []);

  const loadCommonData = () => {
    if (!organizations && !loadingOrganizations) {
      dispatch(loadOrganizations());
    }
    if (!reportedCountries && !loadingReportedCountries) {
      dispatch(loadReportedCountries());
    }
  };

  useMountEffect(loadCommonData);

  // #region Load beneficiaries
  const fetchData = () => {
    if (beneficiariesFiltersList) {
      dispatch(
        loadBeneficiaries({
          ...defaultPagination,
          ...beneficiariesFiltersList,
          groups__name: ROLES.BENEFICIARY,
        })
      );
    }
  };

  useEffect(() => fetchData(), [beneficiariesFiltersList]);
  // #endregion

  const columnData = [
    {
      id: "name",
      align: "left",
      label: "common.beneficiary",
      sortable: true,
      sort_field: "first_name",
      handler: (row) => {
        const fullName = getFullName(row);
        return (
          <div className="user-profile d-flex flex-row align-items-center">
            <div className="user-detail">
              <h5 className="user-name">{fullName}</h5>
              <p className="user-description">{row.username} </p>
            </div>
          </div>
        );
      },
    },
    {
      id: "requested",
      align: "left",
      label: "common.latestEnrolment",
      sortable: true,
      handler: (row) =>
        row.requested ? (
          getFormattedDate(row.requested, "MMM D YYYY, h:mm:ss a")
        ) : (
          <NotAvailable />
        ),
    },
    {
      id: "unjspf_uuid",
      align: "left",
      label: "common.id",
      sortable: true,
      handler: (row) => {
        const UID = row.unjspf_uuid || <NotAvailable />;
        return (
          <div className="user-profile d-flex flex-row align-items-center">
            <div className="user-detail">
              <p className="user-name">{UID}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: "on_hold",
      align: "left",
      label: "common.onHold",
      sortable: false,
      handler: (row) => {
        const onHold = Boolean(row.on_hold);
        return (
          <div>
            <Status
              status={onHold}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "danger" : "light")}
            />
          </div>
        );
      },
    },
    {
      id: "can_enrol",
      align: "left",
      label: "common.allowedToEnrol",
      sortable: false,
      handler: (row) => {
        const allowedToEnrol = Boolean(row.allowed_to_enroll);
        return (
          <div className="can-enrol">
            <Status
              status={allowedToEnrol}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "success" : "danger")}
            />
          </div>
        );
      },
    },
    {
      id: "can_submit_DCE",
      align: "left",
      label: "common.enabledForDce",
      sortable: false,
      handler: (row) => {
        const canSubmitDCE = Boolean(row.dce);
        return (
          <div className="can-submit-dce">
            <Status
              status={canSubmitDCE}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "success" : "danger")}
            />
          </div>
        );
      },
    },
    {
      id: "opted_out",
      align: "left",
      label: "common.optedOut",
      sortable: false,
      handler: (row) => {
        const optedOut = Boolean(row.opt_out);
        return (
          <div className="opted-out">
            <Status
              status={optedOut}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "danger" : "light")}
            />
          </div>
        );
      },
    },
    {
      id: "comm_exhausted",
      align: "left",
      label: "common.commExhausted",
      sortable: false,
      handler: (row) => {
        const optedOut = Boolean(row.communication_exhausted);
        return (
          <div className="communication-exhausted">
            <Status
              status={optedOut}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "danger" : "light")}
            />
          </div>
        );
      },
    },
    {
      id: "invitation_redeemed",
      align: "left",
      label: "common.invitationRedeemed",
      sortable: false,
      handler: (row) => {
        return (
          <div className="enrolment-status">
            <Status
              status={row.invitation_redeemed}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "success" : "danger")}
            />
          </div>
        );
      },
    },
    {
      id: "on_boarding",
      align: "left",
      label: "common.enrolmentStatus",
      sortable: false,
      handler: (row) => {
        return (
          <div className="enrolment-status">
            <EnrolmentSupportStatus status={row.workflow_status} />
          </div>
        );
      },
    },
    {
      id: "valid_coes",
      align: "left",
      label: "common.validDce",
      sortable: false,
      handler: (row) => {
        const coeValid = row.valid_coe;
        return (
          <div className="enrolment-status">
            <Status
              status={coeValid}
              statusValues={{ true: "common.yes", false: "common.no" }}
              getColor={(status) => (status ? "success" : "danger")}
            />
          </div>
        );
      },
    },
  ];

  const yearDCEFilteringOptions = solutionYears()
    .map((year) => [
      {
        label: "common.issuedOn",
        valid: true,
        year,
      },
      {
        label: "common.notIssuedOn",
        valid: false,
        year,
      },
    ])
    .flat()
    .map((item) => ({
      titleTranslated: intl.formatMessage(
        { id: item.label },
        { year: item.year }
      ),
      value: {
        valid: item.valid,
        year: item.year,
      },
      groupBy: item.year,
    }));

  const filters = [
    {
      id: "organization_id",
      title: "common.organization",
      isAutocomplete: true,
      options: organizations?.map((organization) => {
        return {
          title: `${organization.acronym} - ${organization.name}`,
          value: organization.id,
          label: organization.acronym,
        };
      }),
    },
    {
      id: "reported_country",
      title: "common.reportedCountry",
      isAutocomplete: true,
      options: reportedCountries?.map((country) => {
        const countryName = country
          .toLowerCase()
          .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
        return {
          title: countryName,
          value: country,
          label: countryName,
        };
      }),
    },
    {
      id: "workflow_status",
      title: "common.enrolmentStatus",
      isCheckboxArray: true,
      options: Object.values(ENROLMENT_WORKFLOW_STATUS).map((status) => ({
        title: ENROLMENT_WORKFLOW_STATUS_DISPLAY[status],
        rendered: <EnrolmentSupportStatusDot status={status} />,
        value: status,
      })),
      mutuallyExclusive: ["no_enrolments_requested"],
    },
    {
      id: "no_enrolments_requested",
      title: "enrolment.status.noEnrolment",
      isSwitch: true,
      mutuallyExclusive: ["workflow_status"],
    },
    {
      id: "on_hold",
      title: "common.onHold",
      isRadioButton: true,
    },
    {
      id: "has_model",
      title: "common.biometricModel",
      isRadioButton: true,
    },
    {
      id: "allowed_to_enroll",
      title: "common.allowedToEnrol",
      isRadioButton: true,
    },
    {
      id: "can_submit_DCE",
      title: "common.enabledForDce",
      isRadioButton: true,
    },
    {
      id: "opt_out",
      title: "common.optedOut",
      isRadioButton: true,
    },
    {
      id: "communication_exhausted",
      title: "common.commExhausted",
      isRadioButton: true,
    },
    {
      id: "invitation_redeemed",
      title: "common.invitationRedeemed",
      isRadioButton: true,
    },
    {
      id: "has_appointment",
      title: "common.hasAppointment",
      isRadioButton: true,
    },
    {
      id: "valid_coe_year",
      title: "common.validDceOnExercise",
      valueIsObject: true,
      isRadioButton: true,
      options: [...yearDCEFilteringOptions],
    },
  ];

  const onSearch = (search) => {
    dispatch(
      setFiltersList({
        ...beneficiariesFiltersList,
        page: 1,
        search: search || undefined,
      })
    );
  };

  const onClearFilters = (value) => {
    setClearFilters(value);
    dispatch(setFiltersList(INIT_STATE.beneficiariesFiltersList));
  };

  const onApplyFilters = (checkedList) => {
    dispatch(setFiltersList({ ...checkedList, page: 1 }));
  };

  return (
    <Widget styleName="jr-card-profile">
      <FiltersHeader
        onToggleDrawer={setToggleDrawer}
        filters={filters}
        selectedFilters={beneficiariesFiltersList}
        checkedList={beneficiariesFiltersList}
        setFiltersList={setFiltersList}
        searchString={beneficiariesFiltersList?.search ?? ""}
        onSearch={onSearch}
      />
      <FiltersSidebar
        toggleDrawer={setToggleDrawer}
        opened={toggleDrawer}
        filters={filters}
        initialList={beneficiariesFiltersList}
        activateFilters
        clearFilters={clearFilters}
        onDelete={onClearFilters}
        onSubmit={onApplyFilters}
      />
      <DataTable
        id="beneficiaries"
        columnData={columnData}
        tableData={beneficiaries}
        initialPagination={defaultPagination}
        setFiltersList={(filters) => dispatch(setFiltersList(filters))}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        noSearchResultsText={
          <IntlMessages id="beneficiaries.noBeneficiaries" />
        }
        rowClickAction={openBeneficiary}
        selectedFilters={beneficiariesFiltersList}
        search={beneficiariesFiltersList?.search}
        count={count}
        loading={loading}
      />
    </Widget>
  );
};

export { BeneficiariesList };
