import { CalendarToday, Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import cl from "classnames";
import { useState } from "react";
import { useIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";

const DATE_FORMAT = "MMMM Do, YYYY";

const useStyles = makeStyles({
  label: {
    paddingBottom: "8px",
  },
  pickerRoot: {
    cursor: "pointer",
    padding: "15px 10px",
    "&.dense": {
      padding: "10px",
    },
  },
  fieldset: {
    border: "1px solid rgba(126, 126, 126, 0.5)",
    borderRadius: "8px",
  },
  icon: {
    "&.dense": {
      "& button": {
        marginLeft: "-8px",
      },
    },
    "& button": {
      marginLeft: "6px",
    },
  },
  inputAdornment: {
    marginRight: "8px",
    "&.dense": {
      marginRight: "5px",
    },
  },
  input: {
    fontSize: "18px",
    padding: "7px 0",
    "&:not(.Mui-disabled)": {
      cursor: "pointer",
    },
    "&::placeholder": {
      fontSize: "18px",
    },
    "&.dense": {
      fontSize: "16px",
    },
  },
  clearButton: {
    padding: "6px",
  },
  clearIcon: {
    fontSize: "1.25rem",
  },
});

const DatePicker = ({
  id,
  label,
  value,
  placeholder,
  onChange,
  disabled,
  loading,
  clearButton,
  dense,
  containerClass,
  ...props
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);

  const clearDate = (event) => {
    event.stopPropagation();
    onChange(null);
  };
  return (
    <Box className={cl(containerClass)}>
      {!!label && (
        <Typography className={classes.label}>
          <IntlMessages id={label} />
        </Typography>
      )}
      <MuiDatePicker
        {...props}
        id={id}
        open={open}
        format={DATE_FORMAT}
        value={value}
        slots={{ openPickerIcon: CalendarToday }}
        slotProps={{
          inputAdornment: {
            position: "start",
          },
          openPickerButton: {
            color: "primary",
          },
          textField: {
            InputProps: {
              classes: {
                adornedStart: cl(classes.icon, dense && "dense"),
                input: cl(classes.input, dense && "dense"),
                inputAdornedStart: cl(classes.inputAdornment, dense && "dense"),
                notchedOutline: classes.fieldset,
                root: cl(classes.pickerRoot, dense && "dense"),
              },
              disableUnderline: true,
              endAdornment: !!clearButton && !!value && (
                <IconButton
                  aria-label="clear"
                  onClick={clearDate}
                  className={classes.clearButton}
                  disabled={disabled || loading}
                  disableRipple
                >
                  <Close className={classes.clearIcon} />
                </IconButton>
              ),
              placeholder: formatMessage({
                id: placeholder ?? "common.select",
              }),
              readOnly: true,
              onClick: () => setOpen(true),
            },
          },
        }}
        onChange={onChange}
        onClose={() => setOpen(false)}
        disabled={disabled || loading}
      />
    </Box>
  );
};

export { DatePicker };
