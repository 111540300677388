import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { manageEnrolment } from "../../../../actions/Beneficiaries";
import {
  APPROVE_ENROLMENT_SUCCESS,
  REJECT_ENROLMENT_SUCCESS,
} from "../../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../../constants/Routes";
import { ENROLMENT_STATUS } from "../../../../constants/common";
import IntlMessages from "../../../../util/IntlMessages";
import { EnrolmentContent } from "./EnrolmentContent/EnrolmentContent";
import { EnrolmentHeaderContent } from "./EnrolmentHeaderContent/EnrolmentHeaderContent";
import { EnrolmentImage } from "./EnrolmentImage/EnrolmentImage";
import { ManageEnrolmentDialog } from "./ManageEnrolmentDialog/ManageEnrolmentDialog";

const useStyles = makeStyles((theme) => ({
  item: {
    height: "calc(100vh - 123px)",
    overflow: "hidden",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white",
    borderRadius: "0.5rem",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
  },
  userImage: {
    height: "50%",
    minHeight: "350px",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.text.secondary,
  },
  userInfo: {
    overflow: "hidden auto",
    padding: "0 32px",
  },
}));

const EnrolmentDetailContent = ({ enrolment, reload }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  // For editing purposes
  const [setEditing] = useState(false);
  const [userEdited, setUserEdited] = useState({});
  const [enrolmentEdited, setEnrolmentEdited] = useState(enrolment);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);

  const edit = (value) => {
    setEditing(value);
  };

  // Hooks hell. The enrolment is not ready on component creation, but later
  useEffect(() => {
    if (!userEdited || !Object.keys(userEdited).length) {
      setUserEdited(enrolment?.user);
    }

    if (!enrolmentEdited || !Object.keys(enrolmentEdited).length) {
      setEnrolmentEdited(enrolment);
    }
  }, [enrolment]);

  const handleRejectButtonClick = () => {
    setRejectDialogOpen(true);
  };

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  };

  const manageEnrolmentFn = async ({ status, reasons }) => {
    const result = await dispatch(
      manageEnrolment({
        status,
        enrolmentId: enrolment.id,
        reasons,
      })
    );
    if (
      [APPROVE_ENROLMENT_SUCCESS, REJECT_ENROLMENT_SUCCESS].includes(
        result.type
      )
    ) {
      NotificationManager.success(
        <IntlMessages id="pages.enrolmentDetail.updateSuccess" />
      );
      navigate(absolutePath(`${ROUTES.BENEFICIARY}/${enrolment?.user?.id}`));
    } else {
      NotificationManager.error(
        <IntlMessages id="appModule.errorTryAgainLater" />
      );
    }
  };

  return (
    <Grid container spacing={3} justifyContent="space-between">
      <Grid item xs={5} className={classes.item}>
        {/* Card header */}
        <Box className={classes.cardContainer}>
          <Box className={classes.userImage}>
            <EnrolmentImage enrolment={enrolment} />
          </Box>
          <Box className={classes.userInfo}>
            <EnrolmentHeaderContent enrolment={enrolment} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs className={classes.item}>
        {/* Card body */}
        <Box className={classes.cardContainer}>
          <EnrolmentContent
            {...{
              manageEnrolmentFn,
              handleRejectButtonClick,
              enrolment,
              edit,
              enrolmentEdited,
              reload,
            }}
          />
        </Box>

        {rejectDialogOpen && (
          <ManageEnrolmentDialog
            {...{
              manageEnrolmentFn,
              open: rejectDialogOpen,
              handleClose: handleRejectDialogClose,
              enrolment,
              status: ENROLMENT_STATUS.REJECTED,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export { EnrolmentDetailContent };
