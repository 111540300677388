import React from "react";
import { connect } from "react-redux";
import { loadMyUserData, setMyTimeZone } from "../actions/User";
import { userAuthSelector, userDataSelector } from "../reducers/selectors";

export function withLoadUserInfo(WrappedComponent) {
  class WithUserInfo extends React.Component {
    constructor(props) {
      super(props);
      this.load = this.load.bind(this);
      this.loadData = this.loadData.bind(this);
    }

    loadData = async () => {
      await this.props.getUserInfo(this.props.authData?.token);
    };

    setTimeZone = async (timeZone) => {
      await this.props.setUserTimeZone(timeZone);
    };

    async checkTimeZone() {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (
        !this.props.user.profile ||
        !this.props.user.profile.timezone ||
        localTimezone.toString() !== this.props.user.profile.timezone.toString()
      ) {
        await this.setTimeZone(localTimezone);
      }
    }

    async load() {
      if (this.props.authData.token && !this.props.user) {
        await this.loadData();
      }
    }

    async componentDidMount() {
      await this.load();
      await this.checkTimeZone();
    }

    render() {
      return <WrappedComponent {...this.props} load={this.load} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      authData: userAuthSelector(state),
      user: userDataSelector(state),
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      getUserInfo: (token) => dispatch(loadMyUserData({ token })),
      setUserTimeZone: (timeZone) => dispatch(setMyTimeZone(timeZone)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithUserInfo);
}
