import {
  ENROLMENT_FILTERS_LIST,
  ENROLMENT_TOGGLE_ASSIGNED,
  ENROLMENT_TOGGLE_ATTENTION,
} from "../constants/ActionTypes";

export const setFiltersList = (filtersList) => {
  return {
    type: ENROLMENT_FILTERS_LIST,
    payload: filtersList,
  };
};

export const toggleAttention = (checked) => {
  return {
    type: ENROLMENT_TOGGLE_ATTENTION,
    payload: { workflow_status: checked },
  };
};

export const toggleAssigned = (checked) => {
  return {
    type: ENROLMENT_TOGGLE_ASSIGNED,
    payload: { assign_me: checked },
  };
};
