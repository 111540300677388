import { configureStore as configureStoreTK } from "@reduxjs/toolkit";
import axios from "axios";
import { routerMiddleware } from "connected-react-router";
import { NotificationManager } from "react-notifications";
import axiosMiddleware from "redux-axios-middleware";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import { userSignOut, userTokenRefresh } from "../actions/Auth";
import { TOKEN_REFRESH_SUCCESS } from "../constants/ActionTypes";
import { ROUTES } from "../constants/Routes";
import { reducers } from "../reducers/reducers";
import IntlMessages from "../util/IntlMessages";
import authHeader from "../util/authHeader";
import { debounce } from "../util/debounce";

const routeMiddleware = routerMiddleware(history);

const axiosClient = axios.create({
  baseURL: `${window.location.origin}/api`,
  responseType: "json",
});

let isRefreshingToken = false;
let refreshSubscribers = [];

const isPathExcludedForAuthInterceptors = (url) => {
  return url.includes("auth/");
};

const addAuthorizationHeader = (req, token) => {
  req.headers = { ...req.headers, ...authHeader(token) };
  return req;
};

const pushNotification = debounce(() => {
  NotificationManager.error(<IntlMessages id="extraPages.500Msg" />);
}, 100);

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      ({ getState }, req) => {
        addAuthorizationHeader(req, getState().auth.userToken);
        return req;
      },
    ],
    response: [
      {
        error: ({ getState, dispatch }, error) => {
          if (isPathExcludedForAuthInterceptors(error.config.url)) {
            return Promise.reject(error);
          }

          if (error.response?.status === 500) {
            pushNotification();
            return Promise.reject(error);
          }

          if (error.response?.status === 403) {
            if (error.config?.method === "get") {
              history.push(`/${ROUTES.FORBIDDEN}`);
            }
            return Promise.reject(error);
          }

          if (error.response?.status === 503) {
            history.push(`/${ROUTES.MAINTENANCE}`);
            return Promise.reject(error);
          }

          if (
            error.response?.status === 401 &&
            error.response?.data?.code === "token_not_valid"
          ) {
            const { refreshToken } = getState().auth;
            const originalConfig = error.config;

            if (!isRefreshingToken) {
              isRefreshingToken = true;
              dispatch(userTokenRefresh({ refreshToken })).then((result) => {
                if (result.type === TOKEN_REFRESH_SUCCESS) {
                  isRefreshingToken = false;
                  const { userToken } = getState().auth;
                  refreshSubscribers.map((req) => req(userToken));
                  refreshSubscribers = [];
                } else {
                  isRefreshingToken = false;
                  refreshSubscribers.map((req) => req(null));
                  refreshSubscribers = [];
                  dispatch(userSignOut());
                }
              });
            }

            return new Promise((resolve, reject) => {
              refreshSubscribers.push((token) => {
                if (token) {
                  addAuthorizationHeader(originalConfig, token);
                  resolve(axios.request(originalConfig));
                } else {
                  reject(error);
                }
              });
            });
          } // is token error (401)

          return Promise.reject(error);
        },
      },
    ],
  },
};

// Middlewares setup, tuned for each environment
const middlewares = [
  routeMiddleware,
  axiosMiddleware(axiosClient, axiosMiddlewareConfig),
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(createLogger());
}

export default function configureStore(initialState) {
  const store = configureStoreTK({
    reducer: reducers(),
    middleware: (getDefaultMiddlewares) =>
      getDefaultMiddlewares({ serializableCheck: false }).concat(middlewares),
    preloadedState: initialState,
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/reducers", () => {
      const nextRootReducer = require("../reducers/reducers");
      store.replaceReducer(nextRootReducer);
    });
  }

  const persistor = persistStore(store);
  return { store, persistor };
}
