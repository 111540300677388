import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import { NotFound } from "../components/generic/Error/NotFound";
import { ROUTES, absolutePath } from "../constants/Routes";
import { withLoadUserInfo } from "../util/loadUserInfo";
import { Beneficiary } from "./Beneficiary/Beneficiary";
import { Enrolment } from "./Enrolment/Enrolment";
import { MyProfile } from "./MyProfile/MyProfile";
import { Schedule } from "./Schedule/Schedule";
import { User } from "./User/User";

const Routes = withLoadUserInfo(() => {
  return (
    <RouterRoutes>
      <Route path="/" element={<Navigate to={absolutePath(ROUTES.MAIN)} />} />
      <Route
        path={ROUTES.MAIN}
        element={<Navigate to={absolutePath(ROUTES.BENEFICIARY)} />}
      />
      <Route path={`${ROUTES.BENEFICIARY}/*`} element={<Beneficiary />} />
      <Route path={`${ROUTES.ENROLMENT}/*`} element={<Enrolment />} />
      <Route path={`${ROUTES.MY_PROFILE}/*`} element={<MyProfile />} />
      <Route path={`${ROUTES.SCHEDULE}/*`} element={<Schedule />} />
      <Route path={`${ROUTES.MANAGER_USERS}/*`} element={<User />} />
      <Route path="*" element={<NotFound />} />
    </RouterRoutes>
  );
});

export { Routes };
