import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { loadOrganizations } from "../../../actions/Common";
import {
  CLEAR_ERRORS,
  CLEAR_ORGANIZATIONS,
} from "../../../constants/ActionTypes";
import {
  loadingOrganizationsSelector,
  organizationsSelector,
  userErrors,
} from "../../../reducers/selectors";
import { capitalizeFirst } from "../../../util";
import { getFormattedDate } from "../../../util/dates";
import IntlMessages from "../../../util/IntlMessages";
import { getOrganizationFullTitle } from "../../../util/user";
import { DatePicker } from "../../generic/Pickers";

const TOMORROW = dayjs(new Date()).add(1, "days");

const BeneficiaryUpdateForm = ({ beneficiary, saveAction, cancelAction }) => {
  const intl = useIntl();
  const errorMessages = {
    cannotBeEmpty: intl.formatMessage({ id: "validation.fieldCannotBeEmpty" }),
    mustBeNumber: intl.formatMessage({ id: "validation.mustBeNumber" }),
    mustHaveLength: (length) =>
      intl.formatMessage({ id: "validation.mustHaveLength" }, { length }),
  };
  const dispatch = useDispatch();
  const organizations = useSelector(organizationsSelector);
  const loadingOrganizations = useSelector(loadingOrganizationsSelector);
  const apiErrors = useSelector(userErrors);

  const [phoneNumber, setPhoneNumber] = useState(
    beneficiary?.phone_number || null
  );
  const [optOutCheck, setOptOutCheck] = useState(Boolean(beneficiary?.opt_out));
  const [optOutNote, setOptOutNote] = useState(beneficiary?.opt_out_note);
  const [commExhaustedCheck, setCommExhaustedCheck] = useState(
    Boolean(beneficiary?.communication_exhausted)
  );
  const [commExhaustedNote, setCommExhaustedNote] = useState(
    beneficiary?.communication_exhausted_note
  );
  const [onHold, setOnHold] = useState(beneficiary?.on_hold);
  const [onHoldUntil, setOnHoldUntil] = useState(
    beneficiary?.on_hold
      ? dayjs(beneficiary?.on_hold_until, "YYYY-MM-DD")
      : undefined
  );
  const [errors, setErrors] = useState({});

  const clear = () => {
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: CLEAR_ORGANIZATIONS });
  };

  // Clear data on leave
  useEffect(() => clear, []);

  const displayErrors = (field) => {
    if (!errors[field]) {
      return "";
    }
    return errors[field].join(", ");
  };

  const isFormValid = () => {
    const formErrors = {};

    if (!phoneNumber) {
      formErrors.phone_number = [errorMessages.cannotBeEmpty];
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    const data = {
      opt_out_enabled: optOutCheck,
      opt_out_note: optOutNote,
      communication_exhausted: commExhaustedCheck,
      communication_exhausted_note: commExhaustedNote,
      on_hold_until: onHold
        ? getFormattedDate(onHoldUntil, "YYYY-MM-DD")
        : null,
    };

    if (phoneNumber) {
      data.phone_number = phoneNumber.toLowerCase().trim();
    }

    await saveAction(data);
  };

  useEffect(() => {
    if (!organizations && !loadingOrganizations) {
      dispatch(loadOrganizations());
    }
  }, []);

  useEffect(() => {
    if (apiErrors && Object.keys(apiErrors).length > 0) {
      setErrors(apiErrors);
    }
  }, [apiErrors]);

  return (
    <div className="user-profile">
      <div className="user-list card-strip">
        <div className="">
          <form onSubmit={handleSubmit}>
            <FormHelperText
              id="organization-error-text"
              error={!!errors.non_field_errors}
            >
              {errors.non_field_errors}
            </FormHelperText>

            {/* Username */}
            {
              <div className="description">
                <TextField
                  id="username"
                  name="username"
                  defaultValue={beneficiary.username}
                  label={intl.formatMessage({ id: "common.login" })}
                  size="small"
                  variant="standard"
                  fullWidth
                  inputProps={{
                    maxLength: 150,
                  }}
                  disabled
                />
              </div>
            }

            {/* First name */}
            <div className="description pt-4">
              <TextField
                id="first_name"
                name="first_name"
                label={intl.formatMessage({ id: "common.firstName" })}
                defaultValue={capitalizeFirst(beneficiary.first_name)}
                size="small"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 30,
                }}
                disabled
              />
            </div>

            {/* Middle name */}
            <div className="description pt-4">
              <TextField
                id="middle_name"
                name="middle_name"
                label={intl.formatMessage({ id: "common.middleName" })}
                defaultValue={capitalizeFirst(beneficiary.middle_name)}
                size="small"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 150,
                }}
                disabled
              />
            </div>

            {/* Last name */}
            <div className="description pt-4">
              <TextField
                id="last_name"
                name="last_name"
                label={intl.formatMessage({ id: "common.lastName" })}
                defaultValue={capitalizeFirst(beneficiary.last_name)}
                size="small"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 150,
                }}
                disabled
              />
            </div>

            {/* Email */}
            <div className="description pt-4">
              <TextField
                id="email"
                name="email"
                type="email"
                label={intl.formatMessage({ id: "common.contactEmail" })}
                defaultValue={beneficiary.email}
                size="small"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 250,
                }}
                disabled
              />
            </div>

            {/* Phone number */}
            <div className="description pt-4">
              <TextField
                id="phone_number"
                name="phone_number"
                label={intl.formatMessage({ id: "common.phoneNumber" })}
                defaultValue={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                size="small"
                variant="standard"
                fullWidth
                inputProps={{
                  maxLength: 20,
                }}
                error={!!errors.phone_number}
                helperText={displayErrors("phone_number")}
              />
            </div>

            {/* Organization */}
            {organizations && (
              <div className="description pt-3">
                <FormControl
                  id="organizations_fc"
                  fullWidth
                  margin="normal"
                  disabled
                >
                  <Autocomplete
                    id="organization"
                    name="organization_id"
                    value={beneficiary.organization}
                    options={organizations}
                    getOptionLabel={(org) => getOrganizationFullTitle(org)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${intl.formatMessage({ id: "common.organization" })} *`}
                        variant="standard"
                      />
                    )}
                    size="small"
                    disabled
                  />
                </FormControl>
              </div>
            )}

            {/* Opt out */}
            <div className="description pt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={optOutCheck}
                    onChange={(event) => {
                      setOptOutCheck(event.target?.checked);
                    }}
                    name="opt-out-check"
                    color="primary"
                  />
                }
                label={intl.formatMessage({ id: "common.optedOut" })}
              />
            </div>

            {/* Opt out note */}
            <div className="description pt-3">
              <TextField
                id="note-input-text"
                value={optOutNote}
                placeholder={intl.formatMessage({
                  id: "common.optedOut.details",
                })}
                label={intl.formatMessage({ id: "common.optedOut.reason" })}
                onChange={(event) => {
                  setOptOutNote(event.target?.value);
                }}
                fullWidth
                margin="normal"
                variant="standard"
                multiline
              />
            </div>

            {/* Communication exhausted */}
            <div className="description pt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={commExhaustedCheck}
                    onChange={(event) => {
                      setCommExhaustedCheck(event.target?.checked);
                    }}
                    name="comm-exhausted-check"
                    color="primary"
                  />
                }
                label={intl.formatMessage({
                  id: "common.communicationExhausted",
                })}
              />
            </div>

            {/* Communication exhausted note */}
            <div className="description pt-3">
              <TextField
                id="comm-exhausted-note-input-text"
                value={commExhaustedNote}
                label={intl.formatMessage({
                  id: "common.communicationExhausted.details",
                })}
                placeholder={intl.formatMessage({
                  id: "common.communicationExhausted.reason",
                })}
                onChange={(event) => {
                  setCommExhaustedNote(event.target?.value);
                }}
                fullWidth
                margin="normal"
                variant="standard"
                multiline
              />
            </div>

            {/* On hold */}
            <div className="description pt-3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onHold}
                    onChange={(event) => {
                      setOnHold(event.target?.checked);
                      setOnHoldUntil(
                        beneficiary?.on_hold_until &&
                          dayjs(
                            beneficiary?.on_hold_until,
                            "YYYY-MM-DD"
                          ).isSameOrAfter(TOMORROW)
                          ? dayjs(beneficiary?.on_hold_until, "YYYY-MM-DD")
                          : TOMORROW
                      );
                    }}
                    name="on-hold-check"
                    color="primary"
                  />
                }
                label={intl.formatMessage({ id: "common.onHold" })}
              />
            </div>

            {/* On hold until */}
            {onHold && (
              <div className="description pt-3">
                <DatePicker
                  label="pages.profile.onHoldUntil"
                  minDate={TOMORROW}
                  value={onHoldUntil}
                  onChange={setOnHoldUntil}
                  dense
                />
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="d-flex flex-md-row flex-column justify-content-around pt-3">
        <Button
          id="button_cancel"
          color="secondary"
          variant="contained"
          onClick={cancelAction}
          className="mb-3 mb-md-0"
        >
          <IntlMessages id="common.cancel" />
        </Button>
        <Button
          id="button_save"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          <IntlMessages id="common.save" />
        </Button>
      </div>
    </div>
  );
};

export { BeneficiaryUpdateForm };
