import { EmbeddedHTML } from "../EmbeddedHTML/EmbeddedHTML";

const __html = require("../../../assets/html/privacy_policy");
const template = { __html: __html };

const PrivacyPolicy = () => {
  return <EmbeddedHTML html={template} />;
};

export { PrivacyPolicy };
