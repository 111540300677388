const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");

    cookieValue = cookies
      .filter((cookie) => cookie.includes(name))
      .map((cookie) => {
        if (cookie.substring(0, name.length + 1) === name + "=") {
          const cookieValue = cookie.substring(name.length + 1);
          return decodeURIComponent(cookieValue);
        }
      })[0];
  }
  return cookieValue;
};

export default getCookie;
