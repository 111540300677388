import { Check, Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import IntlMessages from "../../../../util/IntlMessages";
import { getDateFromTimestamp, getFormattedDate } from "../../../../util/dates";

const DCoEValidationModal = ({
  loading,
  success,
  trxInfo,
  close,
  fullName = "",
}) => {
  const transactionInfo = [
    { title: "common.validDce.trxHash", value: trxInfo?.hash },
    {
      title: "common.validDce.trxTime",
      value: trxInfo?.txnTime
        ? getFormattedDate(
            getDateFromTimestamp(trxInfo.txnTime),
            "YYYY / MM / DD HH:mm:ss",
            "CET"
          )
        : null,
    },
    { title: "common.validDce.trxSeqNumber", value: trxInfo?.seqNo },
  ];

  return (
    <Dialog open maxWidth="xs">
      <DialogTitle sx={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {loading ? (
            <CircularProgress size={60} className="mb-3" />
          ) : (
            <Avatar
              alt={`${success ? "valid" : "invalid"} DCE`}
              sx={{
                backgroundColor: success ? "#4DA551" : "#C62222",
                height: "60px",
                marginBottom: "12px",
                width: "60px",
              }}
            >
              {success ? <Check /> : <Close />}
            </Avatar>
          )}
          <Box className="d-grid gap-1">
            <Typography variant="h6" fontWeight={700}>
              <IntlMessages
                id={
                  success
                    ? "common.validDce"
                    : loading
                      ? "pages.profile.coe.loading.title"
                      : "pages.profile.coe.fail.title"
                }
              />
            </Typography>
            <Typography>
              {success ? (
                fullName
              ) : (
                <IntlMessages
                  id={
                    loading
                      ? "pages.profile.coe.loading.text"
                      : "pages.profile.coe.fail.text"
                  }
                  values={loading ? {} : { fullName }}
                />
              )}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      {success && (
        <Box sx={{ borderTop: "1px solid rgba(126, 126, 126, 0.50)" }} />
      )}
      {!loading && (
        <DialogContent className="d-grid gap-4" sx={{ padding: "20px 30px" }}>
          {success && (
            <Box className="d-grid gap-2">
              {transactionInfo.map((trx, index) => (
                <Box key={index} className="d-grid gap-1">
                  <Typography sx={{ color: "#565656" }}>
                    <IntlMessages id={trx.title} />
                  </Typography>
                  <Typography
                    sx={{ color: "#202020", wordBreak: "break-word" }}
                  >
                    {trx.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          <Button
            variant="contained"
            sx={{ padding: "16px 24px" }}
            onClick={close}
          >
            <IntlMessages id="pages.profile.coe.button" />
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export { DCoEValidationModal };
