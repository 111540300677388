// import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { App } from "./containers/App";
import configureStore from "./store/store";

const MainApp = () => {
  const { store, persistor } = configureStore();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          {/* <ConnectedRouter history={history}> */}
          <Routes>
            <Route path="*" element={<App />} />
            {/* <Route path="/" element={<div>App</div>} /> */}
          </Routes>
          {/* </ConnectedRouter> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default MainApp;
