import { Box } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { UserList } from "../../components/User/UserList";
import { UserCreate } from "../../components/User/UserProfile/UserCreate";
import { UserProfile } from "../../components/User/UserProfile/UserProfile";
import { UserUpdate } from "../../components/User/UserProfile/UserUpdate";
import { ContainerHeader } from "../../components/generic/ContainerHeader/ContainerHeader";
import { ROUTES, absolutePath } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";

const User = () => {
  const location = useLocation();
  const urlMatch = location.pathname.split(ROUTES.MANAGER_USERS);

  return (
    <Box className="app-wrapper">
      <ContainerHeader
        match={absolutePath(ROUTES.MANAGER_USERS)}
        title={
          <IntlMessages
            id={`pages.${urlMatch[1] === "" ? "managers" : "managerDetail"}`}
          />
        }
      />
      <Routes>
        <Route path="/" element={<UserList />} />
        <Route path=":id" element={<UserProfile />} />
        <Route path=":id/update" element={<UserUpdate />} />
        <Route path="create" element={<UserCreate />} />
      </Routes>
    </Box>
  );
};

export { User };
