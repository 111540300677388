import { Tab, Tabs as TabsMui } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";

const useStyles = makeStyles(() => ({
  tab: {
    minWidth: "fit-content",
    flex: 1,
  },
}));

const Tabs = ({ tabs, ...props }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <TabsMui variant="scrollable" indicatorColor="primary" {...props}>
      {tabs.map((tab) => {
        const { id, label } = tab;
        const tabProps = { id, className: classes.tab };
        return (
          <Tab
            key={id}
            label={intl.formatMessage({ id: label })}
            {...tabProps}
          />
        );
      })}
    </TabsMui>
  );
};

export { Tabs };
