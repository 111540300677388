import React from "react";
import IntlMessages from "../../../util/IntlMessages";

const NotAvailable = ({ textClassName }) => {
  const spanClassName = textClassName || "text-light-grey";
  return (
    <span className={spanClassName}>
      <IntlMessages id="common.notAvailable" />
    </span>
  );
};

export { NotAvailable };
