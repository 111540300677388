import { Close } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  delete: {
    color: "#fff",
    width: "18px",
  },
}));

const ChipButton = ({ option, label, onDelete, ...props }) => {
  const classes = useStyles();

  return (
    <Chip
      // avatar={<Avatar alt={optionLabel} src={option.picture} />}
      label={label}
      color="primary"
      deleteIcon={<Close />}
      onDelete={() => onDelete(option)}
      classes={{ deleteIconColorPrimary: classes.delete }}
      {...props}
    />
  );
};

export { ChipButton };
