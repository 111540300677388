import { faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import IntlMessages from "../../../../../../util/IntlMessages";
import {
  CircularProgressWithLabel,
  LinearProgressWithLabel,
} from "../../../../../generic/Progress";
import { ImageModal } from "../../ImageModal";
import styles from "./IdDocConfidence.module.scss";

const IdDocConfidence = ({ enrolment }) => {
  const [documentIDModalOpen, setDocumentIDModalOpen] = useState(false);

  const documentIDPicture = enrolment?.document_scan;
  const confidence = enrolment?.confidence || {};
  const noConfidence = !Object.values(confidence).filter((value) => value > 0)
    .length;
  const { photo, name, surname, dob, total } = confidence;

  const scores = [
    {
      id: "photo",
      value: Math.round(photo * 100),
      label: "pages.enrolmentDetail.verification.photo",
    },
    {
      id: "firstName",
      value: Math.round(name * 100),
      label: "pages.enrolmentDetail.verification.firstName",
    },
    {
      id: "lastName",
      value: Math.round(surname * 100),
      label: "pages.enrolmentDetail.verification.lastName",
    },
    {
      id: "birthDate",
      value: Math.round(dob * 100),
      label: "pages.enrolmentDetail.verification.birthDate",
    },
  ];

  return (
    <Grid container className={styles.container}>
      {/* ID Document */}
      <Grid item xs={12} className={styles.item}>
        <Box className={styles.imageWrapper}>
          <img
            src={documentIDPicture}
            alt="id-document"
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              borderRadius: "10px",
            }}
          />
          <IconButton
            className={styles.expandButton}
            onClick={() => setDocumentIDModalOpen(true)}
          >
            <FontAwesomeIcon
              icon={faUpRightAndDownLeftFromCenter}
              flip="horizontal"
            />
          </IconButton>
          {/* ID Document modal */}
          {documentIDModalOpen && (
            <ImageModal
              className="expanded-card-header-pic"
              open={documentIDModalOpen}
              image={documentIDPicture}
              altText="ID Document picture"
              handleCloseModal={() => setDocumentIDModalOpen(false)}
            />
          )}
        </Box>
      </Grid>
      {/* No confidence values */}
      {noConfidence && (
        <Grid item xs={12} className={styles.item}>
          <Typography variant="overline">
            <IntlMessages id="pages.enrolmentDetail.verification.noConfidence" />
          </Typography>
        </Grid>
      )}
      {/* Scores */}
      {!noConfidence && (
        <Grid
          item
          container
          xs={12}
          className={`${styles.item} ${styles.scoresContainer}`}
        >
          {/* Total score */}
          <Grid
            item
            sm={12}
            md={6}
            className={`${styles.item} ${styles.totalScoreContainer}`}
          >
            <Box className={styles.totalScoreWrapper}>
              <CircularProgressWithLabel value={total * 100}>
                <Box textAlign="center">
                  <Typography className={styles.totalScoreTitle}>
                    <IntlMessages id="pages.enrolmentDetail.verification.totalScore" />
                  </Typography>
                  <Typography
                    className={styles.totalScoreValue}
                  >{`${Math.round(total * 100)}%`}</Typography>
                </Box>
              </CircularProgressWithLabel>
            </Box>
          </Grid>
          {/* Individual scores */}
          <Grid
            item
            sm={12}
            md={6}
            className={styles.individualScoresContainer}
          >
            {scores.map((score) => (
              <LinearProgressWithLabel key={score.id} {...score} />
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export { IdDocConfidence };
