import {
  CalendarToday,
  CreateOutlined,
  DeleteOutline,
  Language,
  MailOutline,
  PageviewOutlined,
  PersonOutline,
  Schedule,
  VideocamOffOutlined,
} from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadEnrolments } from "../../../actions/Beneficiaries";
import { ROUTES } from "../../../constants/Routes";
import { ENROLMENT_WORKFLOW_STATUS } from "../../../constants/common";
import {
  enrolmentsCountSelector,
  enrolmentsLoadingSelector,
  enrolmentsSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { getDateStr, getTimeSlotStr } from "../../../util/schedule";
import { getFullName } from "../../../util/user";
import { SideBar } from "../../generic/SideBar";
import { EnrolmentsShortList } from "../EnrolmentsShortList";
import { DetailItem } from "./DetailItem";

const useStyles = makeStyles(() => ({
  detailsBox: {
    marginBottom: "40px",
  },
  detailsTitle: {
    fontWeight: "700",
    marginBottom: "20px",
  },
  appointmentTitle: {
    fontWeight: "700",
  },
  localTime: {
    fontSize: "1rem",
    marginTop: "20px",
    marginBottom: "40px",
  },
  appointmentActionsBox: {
    width: "100%",
  },
  appointmentActionButton: {
    padding: "12px 16px",
    textTransform: "none",
  },
}));

const AppointmentDetails = ({
  appointment,
  openDelete,
  deleteAppointment,
  openEditAppointment,
  joinVideoCall,
  loadingJoin,
  canJoinVideoCall,
  bookAppointment,
  toggleDrawer,
}) => {
  // #region Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hasOnboarding, setHasOnboarding] = useState(!!appointment?.onboarding);
  const [isPast, setIsPast] = useState(false);
  const [localDateTimeString, setLocalDateTimeString] = useState("");
  const [agentDetails, setAgentDetails] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState([]);
  const enrolments = useSelector(enrolmentsSelector);
  const enrolmentsLoading = useSelector(enrolmentsLoadingSelector);
  const count = useSelector(enrolmentsCountSelector);
  const defaultPagination = { ordering: "-created", size: 10 };
  const defaultFilters = {
    workflow_status: ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION,
  };
  const assignedToAgentFilter = {
    workflow_status: ENROLMENT_WORKFLOW_STATUS.AGENT_ASSIGNED,
    assigned_operator_id: appointment?.operator?.id,
  };
  const [currentPagination, setCurrentPagination] = useState(defaultPagination);
  const [selectedEnrolment, setSelectedEnrolment] = useState(null);

  useEffect(() => {
    setAppointmentDetailsData();
  }, [appointment]);
  // #endregion

  // #region Load enrolments
  const fetchEnrolments = (pagination, search, assignedToAgent) => {
    if (!hasOnboarding && pagination && !enrolmentsLoading) {
      setCurrentPagination(pagination);

      // If page didn't change, reset it
      if (pagination.page && pagination.page === currentPagination.page) {
        pagination.page = 1;
      }
      const filters = assignedToAgent ? assignedToAgentFilter : defaultFilters;
      dispatch(loadEnrolments({ ...pagination, ...filters, search }));
    }
  };
  // #endregion

  // #region Actions methods
  const openEnrolment = () => {
    const enrolmentId = appointment?.onboarding?.id;
    const url = `${window.location.origin}/app/${ROUTES.ENROLMENT}/${enrolmentId}`;
    window.open(url, "_blank");
  };
  // #endregion

  // #region Aux methods
  function setAppointmentDetailsData() {
    const localDate = getDateStr(appointment);
    const localTimeSlot = getTimeSlotStr(appointment);
    const localDateTime = `${localDate} ${localTimeSlot}`;

    setHasOnboarding(!!appointment?.onboarding);
    setIsPast(getAppointmentIsInPast());
    setLocalDateTimeString(localDateTime);
    setAgentDetails(getAgentDetailsArray());
    if (appointment?.onboarding) {
      setBeneficiaryDetails(getBeneficiaryDetailsArray());
    }
  }

  function getAgentDetailsArray() {
    const { timezone } = appointment?.operator || {};

    return [
      {
        key: "day",
        icon: <CalendarToday />,
        label: "pages.schedule.appointment.agentDetails.day",
        value: getDateStr(appointment, timezone),
      },
      {
        key: "localTime",
        icon: <Schedule />,
        label: "pages.schedule.appointment.agentDetails.localTime",
        value: getTimeSlotStr(appointment, timezone),
      },
      {
        key: "name",
        icon: <PersonOutline />,
        label: "pages.schedule.appointment.agentDetails.name",
        value: getOperatorFullName(),
      },
      {
        key: "timezone",
        icon: <Language />,
        label: "pages.schedule.appointment.agentDetails.timezone",
        value: timezone || "N/D",
      },
    ];
  }

  function getBeneficiaryDetailsArray() {
    const { username, timezone } = appointment?.onboarding?.user || {};

    return [
      {
        key: "name",
        icon: <PersonOutline />,
        label: "pages.schedule.appointment.agentDetails.name",
        value: getBeneficiaryFullName(),
      },
      {
        key: "email",
        icon: <MailOutline />,
        label: "pages.schedule.appointment.beneficiaryDetails.email",
        value: username,
      },
      {
        key: "localTime",
        icon: <Schedule />,
        label: "pages.schedule.appointment.agentDetails.localTime",
        value: getTimeSlotStr(appointment, timezone),
      },
      {
        key: "timezone",
        icon: <Language />,
        label: "pages.schedule.appointment.agentDetails.timezone",
        value: timezone || "N/D",
      },
    ];
  }

  function getOperatorFullName() {
    return getFullName(appointment?.operator);
  }

  function getBeneficiaryFullName() {
    return getFullName(appointment?.onboarding?.user);
  }

  function getAppointmentIsInPast() {
    const startDate = dayjs(appointment?.slot?.start_datetime);
    return startDate.isBefore(new Date(), "day");
  }
  // #endregion

  return (
    <>
      <SideBar
        title={`pages.schedule.${
          hasOnboarding ? "appointment" : "slotAvailable"
        }.title`}
        toggleDrawer={toggleDrawer}
        cancelLabel={`pages.schedule.${
          hasOnboarding ? "appointment.delete" : "slotAvailable.remove"
        }`}
        cancelFn={() =>
          hasOnboarding
            ? openDelete(appointment)
            : deleteAppointment(appointment)
        }
        cancelIcon={<DeleteOutline />}
        cancelDisabled={isPast}
        saveLabel={`pages.schedule.${
          hasOnboarding ? "appointment.edit" : "slotAvailable.confirm"
        }`}
        saveFn={() =>
          hasOnboarding
            ? openEditAppointment(appointment)
            : bookAppointment(appointment, selectedEnrolment)
        }
        saveIcon={hasOnboarding ? <CreateOutlined /> : null}
        saveProps={{
          variant: hasOnboarding ? "text" : "contained",
          style: { justifyContent: hasOnboarding ? "start" : "none" },
        }}
        saveDisabled={(!hasOnboarding && !selectedEnrolment) || isPast}
      >
        {/* Appointment details */}
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.localTime}
        >
          {localDateTimeString}
        </Typography>
        {/* Agent details */}
        <Box className={classes.detailsBox}>
          <Typography variant="h6" className={classes.detailsTitle}>
            <IntlMessages id="pages.schedule.appointment.agentDetails.title" />
          </Typography>
          {agentDetails &&
            agentDetails.map((detail) => {
              return (
                <DetailItem
                  key={detail.key}
                  icon={detail.icon}
                  label={detail.label}
                  value={detail.value}
                />
              );
            })}
        </Box>
        {/* Beneficiary details */}
        {hasOnboarding ? (
          <Box>
            <Box className={classes.detailsBox}>
              <Typography variant="h6" className={classes.detailsTitle}>
                <IntlMessages id="pages.schedule.appointment.beneficiaryDetails.title" />
              </Typography>
              {beneficiaryDetails &&
                beneficiaryDetails.map((detail) => {
                  return (
                    <DetailItem
                      key={detail.key}
                      icon={detail.icon}
                      label={detail.label}
                      value={detail.value}
                    />
                  );
                })}
            </Box>
            {/* Appointment actions */}
            <Box className={classes.appointmentActionsBox}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="">
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.appointmentActionButton}
                    endIcon={<PageviewOutlined />}
                    onClick={() => openEnrolment(appointment)}
                    fullWidth
                    disableElevation
                  >
                    <IntlMessages id="pages.schedule.appointment.openEnrolment" />
                  </Button>
                </Grid>
                <Grid item xs={12} className="">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.appointmentActionButton}
                    endIcon={<VideocamOffOutlined />}
                    onClick={() => joinVideoCall(appointment)}
                    disabled={loadingJoin || !canJoinVideoCall}
                    fullWidth
                    disableElevation
                  >
                    <IntlMessages id="pages.schedule.appointment.startVideoCall" />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box className={classes.detailsBox}>
            <Typography variant="h6" className={classes.appointmentTitle}>
              <IntlMessages id="pages.schedule.slotAvailable.addAppointment.title" />
            </Typography>
            <EnrolmentsShortList
              appointment={appointment}
              enrolments={enrolments}
              defaultPagination={defaultPagination}
              fetchEnrolments={fetchEnrolments}
              count={count}
              selectedEnrolmentChange={setSelectedEnrolment}
            />
          </Box>
        )}
      </SideBar>
    </>
  );
};

export { AppointmentDetails };
