import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ScheduleCalendar } from "../../components/Schedule";
import { ContainerHeader } from "../../components/generic/ContainerHeader/ContainerHeader";
import { ROUTES, absolutePath } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";

const Schedule = () => {
  return (
    <Box className="app-wrapper">
      <ContainerHeader
        match={absolutePath(ROUTES.SCHEDULE)}
        title={<IntlMessages id="pages.schedule" />}
      />
      <Routes>
        <Route path="/" element={<ScheduleCalendar />} />
      </Routes>
    </Box>
  );
};

export { Schedule };
