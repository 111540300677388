import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { cloneElement } from "react";
import IntlMessages from "../../../util/IntlMessages";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "30px 5px",
  },
  gridContainer: {
    margin: "-8px",
    "&:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  gridItem: {
    display: "flex",
    padding: "8px !important",
  },
  icon: {
    marginRight: "12px",
  },
  label: {
    color: theme.palette.text.secondary,
  },
}));

const UserInfoList = ({ userData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {userData.map((field) => {
        const keyField = field.name ?? field.id;
        const icon = field.icon;
        const label = field.label ?? field.title;
        const value = field.value ?? field.description;
        const component = field.component;

        return (
          <Grid
            container
            spacing={2}
            key={keyField}
            className={classes.gridContainer}
          >
            <Grid
              item
              xs={12}
              sm={5}
              md={12}
              lg={5}
              className={classes.gridItem}
            >
              {cloneElement(icon, {
                color: "primary",
                className: classes.icon,
              })}
              <Typography className={classes.label}>
                <IntlMessages id={label} />
              </Typography>
            </Grid>
            <Grid id={keyField} item xs sx={{ padding: "8px !important" }}>
              {component || <Typography>{value}</Typography>}
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export { UserInfoList };
