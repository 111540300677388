import { Language, ScheduleOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { listOperatorsAvailabilityV2 } from "../../../../../actions/SupportSchedule";
import { loadUsersData } from "../../../../../actions/User";
import { ROLES } from "../../../../../constants/User";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../../constants/common";
import {
  availabilityRequestInProgress,
  availabilitySlotsSelectorV2,
  getUserInfo,
  operatorsListForSelectSelector,
  usersLoadingSelector,
} from "../../../../../reducers/selectors";
import IntlMessages from "../../../../../util/IntlMessages";
import { getFormattedDate } from "../../../../../util/dates";
import {
  AgentsList,
  SelectionMode,
} from "../../../../Schedule/AgentsList/AgentsList";
import { Dropdown } from "../../../Dropdown";
import { DatePicker } from "../../../Pickers";

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat.default);

const useStyles = makeStyles(() => ({
  timezoneContainer: {
    display: "flex",
    backgroundColor: "#F2F4F7",
    padding: "0.75rem",
    borderRadius: "4px",
  },
  timezoneTitle: {
    color: "#7E7E7E",
    paddingLeft: "12px",
  },
  timezone: {
    paddingLeft: "6px",
  },
  sectionContainer: {
    marginTop: "30px",
  },
  sectionTitle: {
    fontSize: "1.125rem",
    marginBottom: "10px",
  },
  picker: {
    width: "100%",
    margin: "10px 0",
  },
}));

const EnrolmentAppointment = ({ enrolment, setAppointment }) => {
  // #region Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef();
  const intl = useIntl();

  // Dates
  const todaysDate = new Date();
  const defaultStartTime = dayjs(todaysDate).valueOf();
  const defaultEndTime = dayjs(todaysDate).add(1, "M").valueOf();
  const [startTime, setStartTime] = useState(defaultStartTime);
  const [endTime, setEndTime] = useState(defaultEndTime);

  // Availabilities and operators
  const availabilitySlots = useSelector(availabilitySlotsSelectorV2);
  const loadingAvailabilities = useSelector(availabilityRequestInProgress);
  const operators = useSelector(operatorsListForSelectSelector);
  const loadingOperators = useSelector(usersLoadingSelector);
  const [timeSlots, setTimeSlots] = useState([]);
  const [operatorsList, setOperatorsList] = useState(operators);

  // Appointment, user and agent
  const userTimezone = enrolment?.user?.profile?.timezone;
  const assignedAgent = enrolment?.assigned_agent;
  const { slot } = enrolment?.appointment || {};
  const hasAppointment = !!slot?.id;

  const { start_datetime, end_datetime } = slot;
  const appointmentSlot = { start_datetime, end_datetime };
  const startDate = getFormattedDate(start_datetime, DATE_FORMAT, userTimezone);
  const startTimeStr = start_datetime
    ? getFormattedDate(start_datetime, TIME_FORMAT, userTimezone)
    : "";

  const [selectedDate, setSelectedDate] = useState(
    start_datetime ? dayjs(start_datetime) : null
  );
  const [selectedSlot, setSelectedSlot] = useState(
    hasAppointment ? appointmentSlot : null
  );
  const [selectedAgent, setSelectedAgent] = useState({
    ...getUserInfo(assignedAgent),
    selected: true,
  });
  const [selectedHasChanged, setSelectedHasChanged] = useState(false);
  // #endregion

  // #region Load availabilities
  const fetchAvailabilities = () => {
    dispatch(
      listOperatorsAvailabilityV2({
        operatorsIds: operators?.map((operator) => operator.id),
        startTime,
        endTime,
      })
    );
  };

  useEffect(() => {
    fetchAvailabilities();
  }, [operators, startTime, endTime]);
  // #endregion

  // #region Load operators
  const fetchOperators = () => {
    if (!operators && !loadingOperators) {
      dispatch(
        loadUsersData({
          groups__name__in: [ROLES.OPERATOR, ROLES.TASK_FORCE].join(","),
          size: 1000,
        })
      );
    }
  };

  useEffect(() => {
    fetchOperators();
  }, []);
  // #endregion

  // #region Manage slots and agents
  const setTimeSlotsAndAgents = () => {
    const slots = [];
    const operatorIdsBySlots = [];

    if (availabilitySlots && selectedDate) {
      const day = getFormattedDate(selectedDate, DATE_FORMAT);

      for (const date in availabilitySlots) {
        // Iterate over all days because there can be slots from one day that
        // due to user timezone are coming from day before or day after
        availabilitySlots[date].forEach((slot) => {
          const { start_datetime, end_datetime, operator_id } = slot;
          const slotStartDateStr = getFormattedDate(
            start_datetime,
            DATE_FORMAT,
            userTimezone
          );

          if (slotStartDateStr === day) {
            const slotStartTimeStr = getFormattedDate(
              start_datetime,
              TIME_FORMAT,
              userTimezone
            );

            // Add slot if it belongs to the selected agent
            // or if there is no selected agent
            if (
              !selectedAgent ||
              (selectedAgent && operator_id?.id === selectedAgent.id)
            ) {
              // Add slot only if it wasn't added yet
              if (
                !slots.filter((slot) => slot.label === slotStartTimeStr).length
              ) {
                slots.push({
                  label: slotStartTimeStr,
                  value: { start_datetime, end_datetime },
                });
              }

              // Add agent if it has this time slot or if no time slot is selected
              if (
                !selectedSlot ||
                selectedSlot.start_datetime === start_datetime
              ) {
                operatorIdsBySlots.push(operator_id?.id);
              }
            }
          }
        });
      }
    }

    // Add appointment slot to slots list and agent to operators list
    // if it wasn't added yet and if the selected date is the date of the appointment
    if (
      hasAppointment &&
      assignedAgent &&
      assignedAgent.id === selectedAgent?.id &&
      selectedDate?.isSame(dayjs(start_datetime), "day") &&
      !slots.filter((slot) => slot.label === startTimeStr).length
    ) {
      slots.push({ label: startTimeStr, value: appointmentSlot });
      operatorIdsBySlots.push(assignedAgent?.id);
    }

    // Set time slots
    setTimeSlots(
      [...new Set(slots)].sort((a, b) => a.label.localeCompare(b.label))
    );

    // Set available operators for this slots list
    if (operators) {
      const operatorsList = [...new Set(operatorIdsBySlots)];
      setOperatorsList(
        selectedDate
          ? operators.filter((op) => operatorsList.includes(op.id))
          : operators
      );
    }
  };

  useEffect(() => {
    const {
      // availabilitySlots: prevAvailabilitySlots,
      selectedDate: prevSelectedDate,
      // selectedSlot: prevSelectedSlot,
      selectedAgent: prevSelectedAgent,
    } = ref?.current ?? {};

    // Update time slots every time selected date or selected agent changes
    setTimeSlotsAndAgents();

    if (selectedHasChanged) {
      // If agent removed
      if (!!prevSelectedAgent && !selectedAgent) {
        setSelectedDate(null);
        setSelectedSlot(null);
      }
      // If date removed or changed
      if (
        (!!prevSelectedDate && !selectedDate) ||
        prevSelectedDate !== selectedDate
      ) {
        setSelectedSlot(null);
      }
    }

    // Save current values
    ref.current = {
      availabilitySlots,
      selectedDate,
      selectedSlot,
      selectedAgent,
    };
  }, [availabilitySlots, selectedDate, selectedSlot, selectedAgent]);

  useEffect(() => {
    // Emit values to parent
    setAppointment({
      selectedDate,
      selectedSlot,
      selectedAgent,
      enrolment,
    });
  }, [selectedDate, selectedSlot, selectedAgent]);
  // #endregion

  // #region Handle datepicker
  const availableDaysByAgent = useMemo(() => {
    // Filter days by selected agent if there is one
    let slots = [];
    if (availabilitySlots) {
      slots = Object.keys(availabilitySlots).filter(
        (slot) =>
          !selectedAgent ||
          (selectedAgent &&
            availabilitySlots[slot].filter(
              (s) => s.operator_id?.id === selectedAgent.id
            ).length)
      );
    }
    // If start date of appointment has no availabilities
    if (
      hasAppointment &&
      !slots.includes(startDate) &&
      assignedAgent &&
      assignedAgent.id === selectedAgent?.id
    ) {
      slots.push(startDate);
    }
    return slots;
  }, [availabilitySlots, selectedAgent]);

  const disabledDays = (date) => {
    return !availableDaysByAgent.includes(getFormattedDate(date, DATE_FORMAT));
  };

  const onMonthChange = (date) => {
    const startTime = dayjs(date).valueOf();
    const endTime = dayjs(date).add(1, "M").valueOf();
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const renderDatePicker = useMemo(() => {
    return (
      <DatePicker
        value={selectedDate}
        placeholder="common.selectDateSlot"
        onChange={(date) => {
          setSelectedDate(date);
          !selectedHasChanged && setSelectedHasChanged(true);
        }}
        onMonthChange={onMonthChange}
        shouldDisableDate={disabledDays}
        loading={loadingAvailabilities}
        className={classes.picker}
        clearButton
        autoOk
      />
    );
  }, [availableDaysByAgent, loadingAvailabilities, selectedDate]);
  // #endregion

  return (
    <Box className={classes.sectionContainer}>
      {/* Beneficiary timezone */}
      <Box className={classes.timezoneContainer}>
        <Language color="primary" />
        <Typography className={classes.timezoneTitle}>
          <IntlMessages id="pages.enrolmentDetail.editAppointment.beneficiaryTimezone" />
        </Typography>
        <Typography className={classes.timezone}>{userTimezone}</Typography>
      </Box>
      {/* Date and time */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.sectionTitle}>
          <IntlMessages id="pages.enrolmentDetail.editAppointment.dateTime" />
        </Typography>
        {renderDatePicker}
        <Dropdown
          value={selectedSlot ?? null}
          options={timeSlots}
          placeholder={intl.formatMessage({ id: "common.selectTimeSlot" })}
          onChange={(slot) => {
            setSelectedSlot(slot);
            !selectedHasChanged && setSelectedHasChanged(true);
          }}
          disabled={!selectedDate}
          loading={loadingAvailabilities}
          leftIcon={<ScheduleOutlined />}
          maxListHeight="300px"
          className={classes.picker}
          hideArrow
          clearButton
        />
      </Box>
      {/* Agents */}
      <Box className={classes.sectionContainer}>
        <Typography className={classes.sectionTitle}>
          <IntlMessages id="pages.enrolmentDetail.editAppointment.agent" />
        </Typography>
        <AgentsList
          agents={operatorsList}
          agentsChanged={(agent) => {
            setSelectedAgent(agent);
            !selectedHasChanged && setSelectedHasChanged(true);
          }}
          selectionMode={SelectionMode.Single}
          selectedAgent={selectedAgent}
          selectionRequired
        />
      </Box>
    </Box>
  );
};

EnrolmentAppointment.propTypes = {
  enrolment: PropTypes.object,
  setAppointment: PropTypes.func,
};

export { EnrolmentAppointment };
