import { Person, PowerSettingsNew, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSignOut } from "../../../actions/Auth";
import { CLEAR_MY_USER_DATA } from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import { ROLES } from "../../../constants/User";
import {
  currentUserIsReadOnlySelector,
  userDataSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { getFullName } from "../../../util/user";

const useStyles = makeStyles({
  readOnly: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderRadius: "100px",
    color: "#FFF",
    height: "unset",
  },
  readOnlyIcon: {
    color: "#FFF",
    marginLeft: "12px",
    width: "12px",
  },
  readOnlyLabel: {
    padding: "4px 12px 4px 10px",
  },
});

const UserProfileWidget = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(userDataSelector);
  const userIsReadOnly = useSelector(currentUserIsReadOnlySelector);
  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  const goToProfile = () => {
    navigate(absolutePath(ROUTES.MY_PROFILE));
    handleRequestClose();
  };

  const signOut = async () => {
    handleRequestClose();
    await Promise.all([
      dispatch(userSignOut()),
      dispatch({ type: CLEAR_MY_USER_DATA }),
    ]);
  };

  return (
    <Box className="user-profile d-flex flex-row align-items-center">
      {userIsReadOnly && (
        <Chip
          id="read-only"
          label={<IntlMessages id={`common.role.${ROLES.READ_ONLY}`} />}
          icon={<Visibility />}
          className={classes.readOnly}
          classes={{ icon: classes.readOnlyIcon, label: classes.readOnlyLabel }}
        />
      )}
      <Button className="user-button" onClick={handleClick}>
        <Typography id="username" className="user-name">
          {getFullName(user)}
        </Typography>
        <Avatar id="avatar" alt="avatar" className="user-avatar" />
      </Button>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0,
          },
        }}
      >
        <MenuItem onClick={goToProfile}>
          <Person className="jr-fs-md me-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>

        <MenuItem onClick={signOut}>
          <PowerSettingsNew className="jr-fs-md me-2" />
          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu>
    </Box>
  );
};

export { UserProfileWidget };
