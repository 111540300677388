import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { SearchBox } from "../SearchBox";
import styles from "./DataTable.module.scss";

export const parseOrderAndOrderBy = (ordering) => {
  if (!ordering) {
    return {
      orderBy: null,
      order: null,
    };
  }
  const orderingParts = ordering.split("-");
  if (orderingParts.length === 1) {
    return {
      orderBy: ordering,
      order: "asc",
    };
  }
  return {
    orderBy: orderingParts[1],
    order: "desc",
  };
};

const useStyles = makeStyles(() => ({
  resultsItem: {
    display: "flex",
    alignItems: "center",
    marginRight: "4px",
  },
  tableRowHover: {
    cursor: "pointer",
  },
  tablePagination: {
    marginBottom: 0,
  },
}));

const DataTableHead = (props) => {
  const { id, columnData, ordering, onRequestSort, actions, cellClass } = props;
  const { order, orderBy } = parseOrderAndOrderBy(ordering);

  return (
    <TableHead>
      <TableRow>
        {columnData
          .filter((col) => !col.hide)
          .map((column) => {
            const headCellId = `${id}-head-${column.id}`;
            return (
              <TableCell
                key={headCellId}
                id={headCellId}
                align={column.align || "center"}
                className="pl-3"
                classes={{ root: cellClass }}
              >
                {column.sortable && ordering ? (
                  <Tooltip
                    title="Sort"
                    placement={column.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={
                        orderBy &&
                        [column.id, column.sort_field].includes(orderBy)
                      }
                      direction={order}
                      onClick={(event) =>
                        onRequestSort(event, column.sort_field || column.id)
                      }
                    >
                      <IntlMessages id={column.label} />
                    </TableSortLabel>
                  </Tooltip>
                ) : (
                  <IntlMessages id={column.label} />
                )}
              </TableCell>
            );
          })}

        {actions && (
          <TableCell key="actions" classes={{ root: cellClass }}>
            {" "}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

DataTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  columnData: PropTypes.array.isRequired,
};

const DataTableFilters = ({ id, filters, handleChangeFilter }) => {
  const minWidth = 130;
  const maxWidth = 250;
  const minLength = 12;
  const widthPerChar = 8;
  return (
    <div id={`${id}-filters`}>
      {filters.map((filter) => {
        const maxOptionLength =
          filter?.options &&
          Math.max(
            ...(filter?.options || {}).map((option) => option.title.length)
          );
        const calculatedWidth =
          minWidth +
          (Math.max(filter?.title.length, maxOptionLength) - minLength) *
            widthPerChar;
        const width = Math.min(maxWidth, calculatedWidth || maxWidth);
        const filterId = `${id}-filter-${filter?.id}`;
        return (
          <FormControl
            key={filterId}
            className="me-5"
            style={{ minWidth, width }}
          >
            {filter?.options && (
              <Autocomplete
                className="pt-1"
                id={filterId}
                name={filter?.id}
                onChange={(e, option) =>
                  handleChangeFilter(filter?.id, option?.value)
                }
                options={filter?.options}
                groupBy={(option) => option.groupBy}
                getOptionLabel={(option) => option?.label || option?.title}
                getOptionSelected={(option, selected) =>
                  selected && option?.value === selected.value
                }
                renderInput={(params) => (
                  <TextField {...params} label={filter.title} />
                )}
                size="small"
                renderOption={(option) => {
                  return option.rendered || option.title;
                }}
                required
              />
            )}
          </FormControl>
        );
      })}
    </div>
  );
};

DataTableFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
};

const DataTableSearch = ({ id, value, handleRequestSearch }) => {
  return (
    <SearchBox
      id={`${id}-search`}
      value={value ?? ""}
      search={handleRequestSearch}
      delay={700}
    />
  );
};

DataTableSearch.propTypes = {
  handleRequestSearch: PropTypes.func.isRequired,
};

const DataTable = (props) => {
  let {
    columnData,
    tableData,
    initialPagination,
    count,
    actions,
    rowsPerPageOptions,
    searchable,
    noSearchResultsText,
    rowClickAction,
    selectedFilters,
    slideOne,
    slideOneIncludedInFilters,
    slideTwo,
    slideTwoIncludedInFilters,
    hideHeader,
    hideFooter,
    hideResults,
    tableClass,
    cellClass,
    inlineActions,
    setFiltersList,
    showOnlyTable,
    loading,
  } = props;

  const classes = useStyles();
  const tableId = props.id || "dt" + Math.random().toString(16);
  const [internalPagination, setInternalPagination] =
    useState(initialPagination);

  rowsPerPageOptions = rowsPerPageOptions || [10, 25, 50];

  useEffect(() => {
    slideOne &&
      !slideOneIncludedInFilters &&
      Object.keys(slideOne).forEach((key) => {
        handleChangeFilter(key, slideOne[key]);
      });
  }, [slideOne]);

  useEffect(() => {
    slideTwo &&
      !slideTwoIncludedInFilters &&
      Object.keys(slideTwo).forEach((key) => {
        handleChangeFilter(key, slideTwo[key]);
      });
  }, [slideTwo]);

  const updateFiltersList = (filters) => {
    setFiltersList && setFiltersList(filters);
  };

  const handleChangePage = (_, newPage) => {
    const newInternalPagination = { ...internalPagination, page: newPage + 1 };
    setInternalPagination({ ...internalPagination, ...newInternalPagination });
    updateFiltersList({ ...selectedFilters, ...newInternalPagination });
  };

  const handleChangeRowsPerPage = (event) => {
    const newInternalPagination = {
      ...internalPagination,
      page: 1,
      size: parseInt(event.target.value),
    };
    setInternalPagination(newInternalPagination);
    updateFiltersList({ ...selectedFilters, ...newInternalPagination });
  };

  const handleRequestSort = (_, property) => {
    const { order, orderBy } = parseOrderAndOrderBy(selectedFilters.ordering);
    let newOrdering;
    if (property === orderBy) {
      newOrdering = order === "asc" ? `-${orderBy}` : orderBy;
    } else {
      newOrdering = (order === "desc" ? "-" : "") + property;
    }
    updateFiltersList({ ...selectedFilters, ordering: newOrdering });
  };

  const handleChangeFilter = (fieldName, value) => {
    const newFilters = { ...selectedFilters, page: 1 };

    if (value !== undefined) newFilters[fieldName] = value;
    else delete newFilters[fieldName];

    newFilters.page = 1;

    updateFiltersList(newFilters);
  };

  const handleRequestSearch = (value) => {
    const newInternalPagination = { ...internalPagination, page: 1 };
    setInternalPagination(newInternalPagination);
    updateFiltersList({
      ...selectedFilters,
      ...newInternalPagination,
      search: value || undefined,
    });
  };

  return loading ? (
    <Box className={styles.loading}>
      <CircularProgress color="inherit" className={styles.progress} />
      <IntlMessages id="table.loadingResults" />
    </Box>
  ) : (
    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      {!showOnlyTable && (
        <>
          <Grid container spacing={2} item xs={12} md={searchable ? 9 : 12}>
            {!hideResults && (
              <Grid item className={classes.resultsItem}>
                <Typography variant="h6" style={{ fontWeight: 700 }}>
                  {count}{" "}
                  <IntlMessages
                    id={`table.${count === 1 ? "result" : "results"}`}
                  />
                </Typography>
              </Grid>
            )}
            {!!inlineActions && inlineActions()}
          </Grid>
          {searchable && (
            <Grid item xs={12} md>
              <DataTableSearch
                id={tableId}
                value={selectedFilters?.search}
                handleRequestSearch={handleRequestSearch}
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        {tableData && tableData.length > 0 ? (
          <div className={`table-responsive-material ${tableClass}`}>
            <Table>
              {!hideHeader && (
                <DataTableHead
                  id={tableId}
                  columnData={columnData}
                  actions={actions}
                  ordering={
                    selectedFilters?.ordering || internalPagination?.ordering
                  }
                  onRequestSort={handleRequestSort}
                  rowCount={count}
                  cellClass={cellClass}
                />
              )}

              <TableBody>
                {tableData.map((dataRow, rowNumber) => {
                  const rowId = `${tableId}-row-${rowNumber}`;
                  let cellCount = 0;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rowId}
                      id={rowId}
                      classes={{ hover: classes.tableRowHover }}
                      onClick={(event) =>
                        rowClickAction && rowClickAction(dataRow, event)
                      }
                    >
                      {columnData &&
                        columnData.length > 0 &&
                        columnData
                          .filter((col) => !col.hide)
                          .map((column) => {
                            const cellContent =
                              (column?.handler
                                ? column.handler(dataRow)
                                : dataRow[column.id]) || "";
                            const cellId = `${rowId}-col-${cellCount++}`;
                            return (
                              <TableCell
                                key={cellId}
                                id={cellId}
                                align={column.align || "center"}
                                padding={
                                  column.disablePadding ? "none" : "normal"
                                }
                                className={
                                  column?.disabled && column?.disabled(dataRow)
                                    ? styles.cellDisabled
                                    : styles.cellEnabled + " pl-3"
                                }
                                classes={{ root: cellClass }}
                              >
                                {cellContent}
                              </TableCell>
                            );
                          })}

                      {actions &&
                        actions.map((action) => {
                          const cellId = `${rowId}-${cellCount++}`;
                          return (
                            <TableCell
                              align="center"
                              padding="checkbox"
                              key={cellId}
                              classes={{ root: cellClass }}
                            >
                              {action.handler(dataRow)}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
              </TableBody>

              {!hideFooter && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={count}
                      rowsPerPage={
                        selectedFilters?.size || internalPagination?.size || 30
                      }
                      page={
                        selectedFilters?.page || internalPagination?.page
                          ? (selectedFilters?.page ||
                              internalPagination?.page) - 1
                          : 0
                      }
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={rowsPerPageOptions}
                      labelRowsPerPage={<IntlMessages id="table.rowsPerPage" />}
                      classes={{
                        root: cellClass,
                        displayedRows: classes.tablePagination,
                        selectLabel: classes.tablePagination,
                      }}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </div>
        ) : (
          <div className="p-3">
            {noSearchResultsText || <IntlMessages id="table.noResults" />}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

DataTable.propTypes = {
  id: PropTypes.string,
  columnData: PropTypes.array.isRequired,
  initialPagination: PropTypes.object.isRequired,
  setFiltersList: PropTypes.func,
  count: PropTypes.number.isRequired,
  tableData: PropTypes.array,
  actions: PropTypes.array,
  rowsPerPageOptions: PropTypes.array,
  searchable: PropTypes.bool,
  noSearchResultsText: PropTypes.node,
  rowClickAction: PropTypes.func,
  inlineActions: PropTypes.func,
  loading: PropTypes.bool,
};

export { DataTable };
