import { Box } from "@mui/material";
import { useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import packageJson from "../../../package.json";
import { hideMessage } from "../../actions/Auth";
import {
  RecoverPassword,
  SetBeneficiarySecurityCode,
  SetPassword,
  SignIn,
  Success,
} from "../../components/Authentication";
import { Logo } from "../../components/generic/Logo";
import { ROUTES, absolutePath } from "../../constants/Routes";
import { languageSelector, userTokenSelector } from "../../reducers/selectors";
import IntlMessages from "../../util/IntlMessages";
import { ORG_SHORT_NAME } from "../../util/appInfo";

const AuthLayout = () => {
  const { alertMessage, showMessage } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector(userTokenSelector);
  const language = useSelector(languageSelector);

  useEffect(() => {
    if (userToken !== null) {
      navigate(absolutePath(ROUTES.MAIN));
    }
    if (showMessage) {
      if (alertMessage) {
        NotificationManager.error(<IntlMessages id={alertMessage} />);
        dispatch(hideMessage());
      }
    }
  }, [showMessage, userToken]);

  return (
    <Box
      bgcolor="primary.dark"
      className="app-login-container d-flex justify-content-center align-items-center"
    >
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Box
            className="logo-lg w-75 justify-content-center align-items-center d-flex flex-column"
            to="/"
            title={ORG_SHORT_NAME}
          >
            <Logo
              id="unjspf_logo"
              language={language}
              useSquare
              className="img-fluid mb-3"
            />
            <h3 className="text-center text-dark">
              <IntlMessages id="appModule.dCE" />
            </h3>
            <span className="text-light-grey text-center small">{`v${packageJson.version}`}</span>
          </Box>
        </div>

        <div className="app-login-content animated slideInUpTiny animation-duration-3">
          <Routes>
            <Route path="signin" element={<SignIn />} />
            <Route path="recoverpassword" element={<RecoverPassword />} />
            <Route path="setpassword/:code" element={<SetPassword />} />
            <Route path="success" element={<Success />} />
            <Route
              path="set-security-code/:code"
              element={<SetBeneficiarySecurityCode />}
            />
          </Routes>
        </div>
      </div>
      <NotificationContainer />
    </Box>
  );
};

export { AuthLayout };
