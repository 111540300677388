import { TextField } from "@mui/material";

const AuthFormTextField = (props) => {
  return (
    <TextField
      {...props}
      required
      margin="normal"
      variant="standard"
      className="mt-1 my-sm-3"
      fullWidth
    />
  );
};

export { AuthFormTextField };
