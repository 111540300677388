import {
  APP_TITLE,
  ORG_LOGO,
  ORG_LOGOS_BY_LANG,
  ORG_SHORT_NAME,
} from "../../../util/appInfo";

const Logo = ({ useSquare, language, title, ...props }) => {
  const orgName = ORG_SHORT_NAME.toLowerCase();
  const logoName = `${ORG_LOGO}${useSquare ? "-square" : ""}`;
  const logoLang = ORG_LOGOS_BY_LANG ? `-${language}` : "";
  const logo = `${logoName}${logoLang}.png`;

  return (
    <img
      {...props}
      alt={title ?? APP_TITLE}
      title={title ?? APP_TITLE}
      src={require(`../../../assets/images/${orgName}/${logo}`)}
    />
  );
};

export { Logo };
