import { Block } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSignOut } from "../../../actions/Auth";
import { CLEAR_MY_USER_DATA } from "../../../constants/ActionTypes";
import IntlMessages from "../../../util/IntlMessages";
import { withRouter } from "../../../util/withRouter";

const Forbidden = withRouter(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signOut = async () => {
    await Promise.all([
      dispatch(userSignOut()),
      dispatch({ type: CLEAR_MY_USER_DATA }),
    ]);
    navigate("/");
  };

  return (
    <div className="container-fluid text-center pt-5">
      <Block fontSize="large" />
      <h3 className="pt-3">
        <IntlMessages id="generic.forbidden" />
      </h3>
      <Button className="mt-3" color="primary" onClick={signOut}>
        <IntlMessages id="popup.logout" />
      </Button>
    </div>
  );
});

export { Forbidden };
