import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import moment from "moment";
import "moment/locale/fr";
import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { changeUserLanguage } from "../actions/Settings";
import { Maintenance } from "../components/generic/Error/Maintenance";
import { ROUTES } from "../constants/Routes";
import { unjspfTheme } from "../containers/themes/unjspfTheme";
import AppLocale from "../lngProvider";
import EnLang from "../lngProvider/entries/en-US";
import {
  isDirectionRTLSelector,
  languageSelector,
  userLanguageSelector,
  userTokenSelector,
} from "../reducers/selectors";
import { getLanguage } from "../reducers/Settings";
import { AppLayout } from "./AppLayout/AppLayout";
import { AuthLayout } from "./AuthLayout/AuthLayout";
import { DocumentsLayout } from "./DocumentsLayout/DocumentsLayout";
import { GenericLayout } from "./GenericLayout/GenericLayout";

export const RestrictedRoute = ({ component: Component, userToken }) =>
  userToken ? <Component /> : <Navigate to={`/${ROUTES.SIGN_IN}`} />;

const App = () => {
  const dispatch = useDispatch();
  const [currentAppLocale, setCurrentAppLocale] = useState(EnLang);
  const language = useSelector(languageSelector);
  const isDirectionRTL = useSelector(isDirectionRTLSelector);
  const userToken = useSelector(userTokenSelector, shallowEqual);
  const userLanguage = useSelector(userLanguageSelector);

  const applyTheme = createTheme(unjspfTheme);
  const muiCache = createCache({ key: "muiltr", prepend: true });

  if (isDirectionRTL) {
    applyTheme.direction = "rtl";
    document.body.classList.add("rtl");
  } else {
    document.body.classList.remove("rtl");
    applyTheme.direction = "ltr";
  }

  useEffect(() => {
    setCurrentAppLocale(AppLocale[language]);
    document.documentElement.setAttribute("lang", language);
    dayjs.locale(language);
    moment.locale(language);
  }, [language]);

  useEffect(() => {
    const newLanguage = getLanguage(userLanguage);
    dispatch(changeUserLanguage(newLanguage));
  }, [userLanguage]);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={applyTheme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={currentAppLocale.locale}
        >
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <div className="app-main">
              <Routes>
                <Route path="/" element={<Navigate to="/app" />} />
                <Route
                  path="/app/*"
                  element={
                    <RestrictedRoute
                      userToken={userToken}
                      component={AppLayout}
                    />
                  }
                />
                <Route path="/auth/*" element={<AuthLayout />} />
                <Route path="/doc/*" element={<DocumentsLayout />} />
                <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />
                <Route path="*" element={<GenericLayout />} />
              </Routes>
            </div>
          </IntlProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export { App };
