import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { absolutePath, ROUTES } from "../../../constants/Routes";
import { userDataSelector } from "../../../reducers/selectors";
import { Widget } from "../../generic/Widget/Widget";
import styles from "../User.module.scss";
import { UserInfo } from "../UserInfo";

const MyProfile = () => {
  const navigate = useNavigate();
  const user = useSelector(userDataSelector);
  const actions = [
    {
      title: "pages.profile.changeData",
      handler: () => navigate(absolutePath(`${ROUTES.MY_PROFILE}/update`)),
    },
    {
      title: "pages.profile.changePassword",
      handler: () =>
        navigate(absolutePath(`${ROUTES.MY_PROFILE}/reset-password`)),
    },
  ];
  return (
    <Widget styleName={styles.widget}>
      <UserInfo user={user} actions={actions} />
    </Widget>
  );
};

export { MyProfile };
