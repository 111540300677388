import { useEffect, useState } from "react";
import { SideBar } from "../../generic/SideBar";
import { AgentsList } from "../AgentsList";

const AgentsFilters = ({
  agents,
  agentsSelectedIds,
  setAgentsSelectedIds,
  toggleDrawer,
}) => {
  // #region Hooks
  const [agentsList, setAgentsList] = useState([]);

  useEffect(() => {
    agents.map((agent) => {
      agent.selected = agentsSelectedIds?.includes(agent.id);
      return agent;
    });

    setAgentsList(agents);
  }, [agents, agentsSelectedIds]);
  // #endregion

  // #region Actions methods
  const clearFilters = () => {
    setAgentsSelectedIds([]);
  };

  const applyFilters = () => {
    toggleDrawer(false);
    setAgentsSelectedIds(
      agents.filter((agent) => agent.selected).map((agent) => agent.id)
    );
  };
  // #endregion

  return (
    <>
      <SideBar
        title="pages.schedule.filters.title"
        toggleDrawer={toggleDrawer}
        cancelLabel="pages.schedule.filters.clear"
        cancelFn={clearFilters}
        saveLabel="pages.schedule.filters.apply"
        saveFn={applyFilters}
      >
        {/* Agents list */}
        <AgentsList
          agents={agentsList}
          agentsChanged={setAgentsList}
          showSelectAll
        />
      </SideBar>
    </>
  );
};

export { AgentsFilters };
