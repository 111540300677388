import {
  AccountBoxOutlined,
  AlternateEmailOutlined,
  CalendarToday,
  CardTravelOutlined,
  DateRangeOutlined,
  EmailOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { DATE_FORMAT } from "../../../../../constants/common";
import { enrolmentsLoadingSelector } from "../../../../../reducers/selectors";
import { getFormattedDate } from "../../../../../util/dates";
import { NotAvailable } from "../../../../generic/NotAvailable/NotAvailable";
import { UserInfoList } from "../../../../generic/UserInfoList";

const EnrolmentHeaderContent = ({ enrolment }) => {
  const loading = useSelector(enrolmentsLoadingSelector);

  const user = enrolment?.user;
  const login = user?.username;
  const email = user?.email;
  const unjspf_uuid = user?.unjspf_uuid || (
    <NotAvailable textClassName="text-lighten-2" />
  );
  const birthday = (user?.date_of_birth &&
    getFormattedDate(user.date_of_birth, DATE_FORMAT)) || (
    <NotAvailable textClassName="text-lighten-2" />
  );
  const organization = user?.organization?.name || "-";
  const organizationAcronym = user?.organization?.acronym || "-";
  const workedAt = `${organization} - ${organizationAcronym.toLocaleUpperCase()}`;

  const dateJoined = (user?.date_joined &&
    getFormattedDate(user.date_joined, DATE_FORMAT)) || (
    <NotAvailable textClassName="text-lighten-2" />
  );

  const userData = [
    {
      name: "login",
      label: "pages.profile.login",
      icon: <EmailOutlined />,
      value: login,
    },
    {
      name: "email",
      label: "pages.profile.contact",
      icon: <AlternateEmailOutlined />,
      value: email,
    },
    {
      name: "unjspf_uuid",
      label: "pages.profile.id",
      icon: <AccountBoxOutlined />,
      value: unjspf_uuid,
    },
    {
      name: "birthday",
      label: "pages.profile.dateOfBirth",
      icon: <DateRangeOutlined />,
      value: birthday,
    },
    {
      name: "workedAt",
      label: "pages.profile.workedAt",
      icon: <CardTravelOutlined />,
      value: workedAt,
    },
    {
      name: "joinedOn",
      label: "pages.profile.joinedOn",
      icon: <CalendarToday />,
      value: dateJoined,
    },
  ];

  return !loading && <UserInfoList {...{ userData }} />;
};

export { EnrolmentHeaderContent };
