import { SentimentDissatisfied } from "@mui/icons-material";
import IntlMessages from "../../../util/IntlMessages";

const NotFound = () => {
  return (
    <div className="container-fluid text-center pt-5">
      <SentimentDissatisfied fontSize="large" />
      <h3 className="pt-3">
        <IntlMessages id="generic.notFound" />.
      </h3>
    </div>
  );
};

export { NotFound };
