import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadCallEvents } from "../../../../../actions/Beneficiaries";
import { CLEAR_CALL_EVENTS } from "../../../../../constants/ActionTypes";
import {
  CALL_STATUS,
  CALL_STATUS_VALUES,
  TIME_FORMAT,
} from "../../../../../constants/common";
import {
  callsHistorySelector,
  loadingCallEvents,
} from "../../../../../reducers/selectors";
import IntlMessages from "../../../../../util/IntlMessages";
import { getFormattedDate } from "../../../../../util/dates";
import { DataTable } from "../../../../generic/DataTable/DataTable";
import { StatusDot } from "../../../../generic/Status/StatusDot";

dayjs.extend(duration);

const DATE_FORMAT = "DD/MM/YY";
const DURATION_FORMAT = "mm:ss";
const LONG_DURATION_FORMAT = "HH:mm:ss";
const ONE_HOUR = 60 * 60 * 1000;

const getColor = (status) => {
  switch (status) {
    case CALL_STATUS.PERFORMED:
      return "success";
    case CALL_STATUS.INTERRUPTED:
      return "warning";
    case CALL_STATUS.ATTEMPTED:
      return "danger";
    default:
      return null;
  }
};

const useStyles = makeStyles({
  cell: {
    borderBottom: 0,
  },
  statusLabel: {
    fontSize: "14px",
  },
});

const CallsHistory = ({ beneficiary }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const callsHistory = useSelector(callsHistorySelector);
  const loading = useSelector(loadingCallEvents);

  const defaultPagination = {
    ordering: "-created",
    size: 1000,
  };

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_CALL_EVENTS });
  };

  useEffect(() => clear, []);
  // #endregion

  // #region Load user calls history
  const fetchData = () => {
    if (!loading && beneficiary) {
      dispatch(
        loadCallEvents({ ...defaultPagination, beneficiaryId: beneficiary?.id })
      );
    }
  };

  useEffect(() => fetchData(), [beneficiary]);
  // #endregion

  const getTimeDiff = (start, end) => {
    if (start && end) {
      const diff = dayjs(end).diff(dayjs(start));
      const duration = dayjs.duration(diff);
      return getFormattedDate(
        duration.asMilliseconds(),
        diff < ONE_HOUR ? DURATION_FORMAT : LONG_DURATION_FORMAT
      );
    }
    return "-";
  };

  const columnData = [
    {
      id: "date",
      align: "left",
      label: "common.date",
      handler: (row) => getFormattedDate(row?.startDate, DATE_FORMAT),
    },
    {
      id: "status",
      align: "left",
      label: "common.status",
      handler: (row) => (
        <StatusDot
          showLabel
          status={row?.status}
          statusValues={CALL_STATUS_VALUES}
          colorClass={getColor(row?.status)}
          labelClass={classes.statusLabel}
        />
      ),
    },
    {
      id: "startTime",
      align: "left",
      label: "common.start",
      handler: (row) => getFormattedDate(row?.startDate, TIME_FORMAT),
    },
    {
      id: "duration",
      align: "left",
      label: "common.duration",
      handler: (row) =>
        getTimeDiff(row?.answerDate || row?.startDate, row?.endDate),
    },
    {
      id: "agent",
      align: "left",
      label: "common.agent",
    },
  ];

  return (
    <Box>
      {!loading && beneficiary && (
        <DataTable
          id="callEventsList"
          columnData={columnData}
          tableData={callsHistory}
          count={callsHistory?.length ?? 0}
          initialPagination={defaultPagination}
          noSearchResultsText={
            <IntlMessages id="pages.profile.callEvents.noHistory" />
          }
          cellClass={classes.cell}
          showOnlyTable
          hideFooter
        />
      )}
    </Box>
  );
};

export { CallsHistory };
