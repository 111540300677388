import { Box, Typography } from "@mui/material";
import styles from "./StepperProgress.module.scss";

const StepperProgress = ({ currentStep, numberSteps }) => {
  const steps = [...Array(numberSteps).keys()].map((key) => ++key);

  return (
    <Box className={styles.stepperContainer}>
      <Typography className={styles.progress}>
        {currentStep}/{numberSteps}
      </Typography>
      <Box className={styles.stepsContainer}>
        {steps.map((step) => (
          <Step key={step} index={step} currentIndex={currentStep} />
        ))}
      </Box>
    </Box>
  );
};

export const Step = ({ index, currentIndex }) => {
  const stepDisabledClass = currentIndex < index ? styles.disabled : "";

  return (
    <Box
      id={`step_${index}`}
      className={`${styles.step} ${stepDisabledClass}`}
    />
  );
};

export { StepperProgress };
