import React from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMyUserData } from "../../../actions/User";
import { SET_MY_USER_DATA_SUCCESS } from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import { userDataSelector } from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Widget } from "../../generic/Widget/Widget";
import styles from "../User.module.scss";
import { UserForm } from "../UserForm";

const MyProfileForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(userDataSelector);
  const navigate = useNavigate();

  const goToMyProfile = () => {
    navigate(absolutePath(ROUTES.MY_PROFILE));
  };

  const saveMyProfile = async (data) => {
    const result = await dispatch(setMyUserData({ data }));
    if (result.type === SET_MY_USER_DATA_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.user.updateSuccess" />
      );
      goToMyProfile();
    }
  };

  return (
    <Widget styleName={styles.widget}>
      <UserForm
        user={user}
        saveAction={saveMyProfile}
        cancelAction={goToMyProfile}
        isBeneficiary={false}
      />
    </Widget>
  );
};

export { MyProfileForm };
