import {
  CalendarTodayOutlined,
  CreditCardOutlined,
  PersonAddOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MenuItem } from "../../components/generic/MenuItem";
import { ROUTES } from "../../constants/Routes";
import {
  currentUserBelongsToSupportGroupSelector,
  currentUserIsAdminSelector,
} from "../../reducers/selectors";
import { withRouter } from "../../util/withRouter";

const mapStateToProps = (state) => {
  return {
    userIsInSupportGroup: currentUserBelongsToSupportGroupSelector(state),
  };
};

const SidenavContent = connect(mapStateToProps)(
  withRouter(() => {
    const location = useLocation();
    const userIsSupportGroup = useSelector(
      currentUserBelongsToSupportGroupSelector
    );
    const userIsAdmin = useSelector(currentUserIsAdminSelector);

    const menuItems = [
      {
        id: "beneficiaries",
        route: ROUTES.BENEFICIARY,
        icon: <CreditCardOutlined />,
        title: "pages.beneficiaries",
      },
      {
        id: "enrolments",
        route: ROUTES.ENROLMENT,
        icon: <PersonAddOutlined />,
        title: "pages.enrolments",
      },
      {
        id: "schedule",
        route: ROUTES.SCHEDULE,
        icon: <CalendarTodayOutlined />,
        title: "pages.schedule",
        hide: !userIsSupportGroup,
      },
      {
        id: "managers",
        route: ROUTES.MANAGER_USERS,
        icon: <VerifiedUserOutlined />,
        title: "pages.managers",
        hide: !userIsAdmin,
      },
    ];

    useEffect(() => handleFirst());

    const handleFirst = () => {
      const pathname = `${location.pathname}`; // get current path

      const menuLi = document.getElementsByClassName("menu");
      for (let i = 0; i < menuLi.length; i++) {
        menuLi[i].onclick = function (event) {
          const parentLiEle = closest(this, "li");
          if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
            event.stopPropagation();

            if (menuLi[i].classList.contains("open")) {
              menuLi[i].classList.remove("open", "active");
            } else {
              menuLi[i].classList.add("open", "active");
            }
          } else {
            const currentIsOpenBlank = this.classList.contains("open-blank");
            if (!currentIsOpenBlank) {
              for (let j = 0; j < menuLi.length; j++) {
                const parentLi = closest(this, "li");
                if (
                  menuLi[j] !== this &&
                  (parentLi === null || !parentLi.classList.contains("open"))
                ) {
                  menuLi[j].classList.remove("open");
                } else {
                  if (menuLi[j].classList.contains("open")) {
                    menuLi[j].classList.remove("open");
                  } else {
                    menuLi[j].classList.add("open");
                  }
                }
              }
            }
          }
        };
      }

      const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
      try {
        const activeNav = closest(activeLi, "ul"); // select closest ul
        if (activeNav?.classList.contains("sub-menu")) {
          closest(activeNav, "li").classList.add("open");
        } else {
          closest(activeLi, "li")?.classList.add("open");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const closest = (el, selector) => {
      try {
        let matchesFn;
        // find vendor prefix
        [
          "matches",
          "webkitMatchesSelector",
          "mozMatchesSelector",
          "msMatchesSelector",
          "oMatchesSelector",
        ].some(function (fn) {
          if (typeof document.body[fn] === "function") {
            matchesFn = fn;
            return true;
          }
          return false;
        });

        let parent;

        // traverse parents
        while (el) {
          parent = el.parentElement;
          if (parent && parent[matchesFn](selector)) {
            return parent;
          }
          el = parent;
        }
      } catch (e) {
        console.log(e);
      }

      return null;
    };

    return (
      <Box className="scrollbar">
        <ul className="nav-menu">
          {menuItems.map((menuItem) => (
            <MenuItem key={menuItem.id} menuItem={menuItem} />
          ))}
        </ul>
      </Box>
    );
  })
);

export { SidenavContent };
