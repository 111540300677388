import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { MyProfile as Profile } from "../../components/User/MyProfile/MyProfile";
import { MyProfileForm } from "../../components/User/MyProfile/MyProfileForm";
import { ResetPasswordForm } from "../../components/User/MyProfile/ResetPasswordForm";
import { ContainerHeader } from "../../components/generic/ContainerHeader/ContainerHeader";
import { ROUTES, absolutePath } from "../../constants/Routes";
import IntlMessages from "../../util/IntlMessages";

const MyProfile = () => {
  return (
    <Box className="app-wrapper">
      <ContainerHeader
        match={absolutePath(ROUTES.MY_PROFILE)}
        title={<IntlMessages id="popup.profile" />}
      />
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="update" element={<MyProfileForm />} />
        <Route path="reset-password" element={<ResetPasswordForm />} />
      </Routes>
    </Box>
  );
};

export { MyProfile };
