import { PersonAddOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loadOrganizations } from "../../actions/Common";
import { loadUsersData, setFiltersList } from "../../actions/User";
import { CLEAR_USERS_DATA } from "../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../constants/Routes";
import { WEB_USER_ROLES } from "../../constants/User";
import { INIT_STATE } from "../../reducers/Users";
import {
  loadingOrganizationsSelector,
  organizationsSelector,
  usersCountSelector,
  usersFiltersListSelector,
  usersListSelector,
  usersLoadingSelector,
} from "../../reducers/selectors";
import { capitalizeFirst } from "../../util";
import IntlMessages from "../../util/IntlMessages";
import { getFormattedDate } from "../../util/dates";
import { getOrganizationFullTitle } from "../../util/user";
import { DataTable } from "../generic/DataTable/DataTable";
import { FiltersSidebar } from "../generic/FilterSidebar/FilterSidebar";
import { FiltersHeader } from "../generic/FiltersHeader/FiltersHeader";
import { NotAvailable } from "../generic/NotAvailable/NotAvailable";
import { Widget } from "../generic/Widget/Widget";
import styles from "./User.module.scss";
import { useIntl } from "react-intl";

const UserList = ({ defaultPagination }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const users = useSelector(usersListSelector);
  const usersFiltersList = useSelector(usersFiltersListSelector);
  const organizations = useSelector(organizationsSelector);
  const count = useSelector(usersCountSelector);
  const loading = useSelector(usersLoadingSelector);
  const loadingOrganizations = useSelector(loadingOrganizationsSelector);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);

  defaultPagination = defaultPagination || {
    ordering: "-date_joined",
    page: 1,
    size: 10,
  };

  const goToUserDetail = (row) => {
    return navigate(absolutePath(`${ROUTES.MANAGER_USERS}/${row.id}`));
  };

  const addNewUser = (event) => {
    event.preventDefault();
    navigate(absolutePath(`${ROUTES.MANAGER_USERS}/create`));
  };

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_USERS_DATA });
  };

  useEffect(() => clear, []);
  // #endregion

  // #region Load common data
  const loadCommonData = () => {
    if (!loadingOrganizations) {
      dispatch(loadOrganizations());
    }
  };

  useEffect(() => {
    loadCommonData();
  }, []);
  // #endregion

  // #region Load users
  const fetchData = () => {
    if (usersFiltersList && !loading) {
      dispatch(
        loadUsersData({
          ...defaultPagination,
          ...usersFiltersList,
          groups__name__in:
            usersFiltersList?.users_role_name || WEB_USER_ROLES.join(","),
        })
      );
    }
  };

  useEffect(() => fetchData(), [usersFiltersList]);
  // #endregion

  const onClearFilters = (value) => {
    setClearFilters(value);
    dispatch(setFiltersList(INIT_STATE.usersFiltersList));
  };

  const onApplyFilters = (checkedList) => {
    dispatch(setFiltersList({ ...checkedList, page: 1 }));
  };

  const columnData = [
    {
      id: "username",
      align: "left",
      label: "common.emailLogin",
      sortable: true,
      handler: (row) => row.username.toLocaleLowerCase(),
    },
    {
      id: "first_name",
      align: "left",
      label: "common.firstName",
      sortable: true,
    },
    {
      id: "last_name",
      align: "left",
      label: "common.lastName",
      sortable: true,
    },
    {
      id: "organization",
      align: "left",
      label: "common.organization",
      sortable: true,
      sort_field: "organization__name",
      handler: (row) => {
        return (
          <div className="user-profile d-flex flex-row align-items-center">
            <div className="user-detail">
              <h5 className="user-name">
                {row.organization?.acronym?.toLocaleUpperCase()}
              </h5>
              <p className="user-description">
                {capitalizeFirst(row.organization?.name)}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      id: "groups",
      align: "left",
      label: "common.role",
      sortable: false,
      sort_field: "groups__name",
      handler: (row) =>
        row.groups ? (
          row.groups
            .map(
              (group) =>
                group.name &&
                intl.formatMessage({ id: `common.role.short.${group.name}` })
            )
            .join(", ")
        ) : (
          <NotAvailable />
        ),
    },
    {
      id: "date_joined",
      align: "left",
      label: "common.created",
      sortable: true,
      handler: (row) => {
        return row.date_joined ? (
          <div className="user-profile d-flex flex-row align-items-center">
            <div className="user-detail">
              <h5 className="user-name">
                {getFormattedDate(row.date_joined, "MMM D YYYY")}
              </h5>
              <h5>{getFormattedDate(row.date_joined, "h:mm:ss a")}</h5>
            </div>
          </div>
        ) : (
          <NotAvailable />
        );
      },
    },
  ];

  const filters = [
    {
      id: "users_role_name",
      title: "common.role",
      isAutocomplete: true,
      options: WEB_USER_ROLES.map((role) => {
        const roleDesc = intl.formatMessage({
          id: `common.role.${role}` || "-",
        });
        return {
          title: roleDesc,
          value: role,
          label: roleDesc,
        };
      }),
    },
    {
      id: "organization_id",
      title: "common.organization",
      isAutocomplete: true,
      options: organizations?.map((organization) => {
        return {
          title: getOrganizationFullTitle(organization),
          value: organization.id,
          label: organization.acronym,
        };
      }),
    },
  ];

  return (
    <Widget styleName={styles.usersList}>
      <Grid container>
        <Grid item xs="auto">
          <Button
            id="create_user"
            variant="contained"
            color="primary"
            startIcon={<PersonAddOutlined />}
            className={styles.addUserButton}
            onClick={addNewUser}
            disableElevation
            disableRipple
          >
            <IntlMessages id="pages.managers.addNew" />
          </Button>
        </Grid>
        <Grid item xs>
          <FiltersHeader
            onToggleDrawer={setToggleDrawer}
            filters={filters}
            selectedFilters={usersFiltersList}
            checkedList={usersFiltersList}
            setFiltersList={setFiltersList}
          />
        </Grid>
      </Grid>
      <FiltersSidebar
        toggleDrawer={setToggleDrawer}
        opened={toggleDrawer}
        filters={filters}
        initialList={usersFiltersList}
        activateFilters
        clearFilters={clearFilters}
        onDelete={onClearFilters}
        onSubmit={onApplyFilters}
      />
      <DataTable
        id="users"
        columnData={columnData}
        tableData={users}
        initialPagination={defaultPagination}
        setFiltersList={(filters) => dispatch(setFiltersList(filters))}
        rowsPerPageOptions={[5, 10, 20, 50]}
        noSearchResultsText={<IntlMessages id="pages.managers.noUsers" />}
        rowClickAction={goToUserDetail}
        selectedFilters={usersFiltersList}
        search={usersFiltersList?.search}
        count={count}
        loading={loading}
        searchable
      />
    </Widget>
  );
};

export { UserList };
