import { Tune } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleAssigned, toggleAttention } from "../../../actions/Enrolment";
import {
  ENROLMENT_WORKFLOW_STATUS,
  ENROLMENT_WORKFLOW_STATUS_DISPLAY,
} from "../../../constants/common";
import IntlMessages from "../../../util/IntlMessages";
import { ChipsPanel } from "../ChipsPanel";
import { SearchBox } from "../SearchBox";
import { SwitchButton } from "../SwitchButton";
import { useIntl } from "react-intl";

const useStyles = makeStyles(() => ({
  container: {
    margin: "-12px",
    marginBottom: "0.75rem",
  },
  searchContainer: {
    padding: "12px !important",
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "end",
    padding: "12px !important",
  },
  filtersButton: {
    textTransform: "none",
    minWidth: "unset",
  },
}));

const KEYS_TO_BE_IGNORED = ["size", "ordering", "page"];

const FiltersHeader = ({
  onToggleDrawer,
  toggleFirstSwitch,
  toggleSecondSwitch,
  filters,
  selectedFilters,
  checkedList,
  setFiltersList,
  firstSwitchChecked,
  secondSwitchChecked,
  onSearch,
  searchString,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const [filtersForChips, setFiltersForChips] = useState();

  useEffect(() => {
    const filtersForChips = [];

    selectedFilters &&
      Object.keys(selectedFilters)
        .filter((key) => !KEYS_TO_BE_IGNORED.includes(key))
        .forEach((key) => {
          const filterValue = selectedFilters[key];

          if (filterValue) {
            if (typeof filterValue === "object" && filterValue.length) {
              filterValue.forEach((value) =>
                filtersForChips.push({
                  key: `${key}_${value}`,
                  value,
                  label: getChipLabel(key, value),
                  isArrayValue: true,
                })
              );
            } else
              filtersForChips.push({
                key,
                value: filterValue,
                label: getChipLabel(key, filterValue),
              });
          }
        });
    setFiltersForChips(filtersForChips);
  }, [selectedFilters]);

  const getChipLabel = (key, value) => {
    const options = filters?.find((filter) => filter.id === key)?.options;
    const option = options?.find(
      (op) => JSON.stringify(op.value) === JSON.stringify(value)
    );

    switch (key) {
      case "allowed_to_enroll":
      case "assign_me":
      case "can_submit_DCE":
      case "communication_exhausted":
      case "has_appointment":
      case "has_model":
      case "invitation_redeemed":
      case "on_hold":
      case "opt_out":
      case "no_enrolments_requested":
        return intl.formatMessage({
          id: `common.filters.${key}.${value.toString()}`,
        });
      case "valid_coe_year":
        return option.titleTranslated;
      case "workflow_status":
        return intl.formatMessage({
          id: ENROLMENT_WORKFLOW_STATUS_DISPLAY[value],
        });
      default:
        return option ? option.label ?? option.value : value;
    }
  };

  const onRemoveTag = ({ key, value, isArrayValue }) => {
    const updatedList = _.cloneDeep(checkedList);

    if (isArrayValue) key = key.replace(`_${value}`, "");

    if (isArrayValue) {
      const filterValue = updatedList[key];
      const oldValueIdx = filterValue.indexOf(value);

      if (oldValueIdx !== -1) {
        filterValue.splice(oldValueIdx, 1);
      }

      if (!filterValue.length) updatedList[key] = undefined;
    } else {
      updatedList[key] = undefined;
    }

    dispatch(setFiltersList({ ...updatedList, page: 1 }));

    const { workflow_status, assign_me } = updatedList;
    dispatch(
      toggleAttention(
        workflow_status &&
          workflow_status.includes(
            ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString()
          )
          ? ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION
          : undefined
      )
    );
    dispatch(toggleAssigned(assign_me));
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      className={classes.container}
    >
      {/* Quick filters */}
      <Grid item className={classes.searchContainer}>
        {toggleFirstSwitch && (
          <SwitchButton
            label="enrolment.status.requiresAttention"
            checked={firstSwitchChecked}
            onChange={(event) => toggleFirstSwitch(event.target.checked)}
          />
        )}
        {toggleSecondSwitch && (
          <SwitchButton
            label="enrolment.assignedToMe"
            checked={secondSwitchChecked}
            onChange={(event) => toggleSecondSwitch(event.target.checked)}
          />
        )}
        {onSearch && (
          <SearchBox value={searchString} search={onSearch} delay={500} />
        )}
      </Grid>
      {/* Filters */}
      <Grid item xs={12} md={6} className={classes.filtersContainer}>
        {filtersForChips && (
          <ChipsPanel
            options={filtersForChips}
            idProperty="key"
            deleteOption={onRemoveTag}
          />
        )}
        <Button
          variant="outlined"
          color="primary"
          className={classes.filtersButton}
          endIcon={<Tune />}
          onClick={() => onToggleDrawer(true)}
        >
          <IntlMessages id="common.filters" />
        </Button>
      </Grid>
    </Grid>
  );
};

FiltersHeader.propTypes = {
  onToggleDrawer: PropTypes.func,
  toggleFirstSwitch: PropTypes.func,
  toggleSecondSwitch: PropTypes.func,
  selectedFilters: PropTypes.object,
  checkedList: PropTypes.object,
  onSearch: PropTypes.func,
};

export { FiltersHeader };
