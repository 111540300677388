import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getFormattedDate } from "../../../../../../util/dates";
import IntlMessages from "../../../../../../util/IntlMessages";
import { NoResults } from "../../../../../generic/NoResults";
import { EnrolmentSupportStatusDot } from "../../../../EnrolmentSupportStatus/EnrolmentSupportStatusDot";

const DATE_TIME_FORMAT = "DD/MM/YY HH:mm";

const useStyles = makeStyles(() => ({
  cell: {
    borderBottom: 0,
  },
  noResults: {
    marginTop: "40px",
    textAlign: "center",
  },
  statusLabel: {
    fontSize: "14px",
  },
}));

const HistoryLog = ({ historyData }) => {
  const classes = useStyles();

  const columns = [
    {
      field: "requestDate",
      label: "common.date",
      prop: "date_time",
      isDateTime: true,
    },
    {
      field: "event",
      label: "common.event",
      prop: "event",
      renderFunc: (event) => (
        <IntlMessages id={`pages.enrolmentDetail.log.${event}`} />
      ),
    },
    {
      field: "status",
      label: "common.status",
      prop: "extra.end_status",
      isStatus: true,
    },
    {
      field: "appointmentDate",
      label: "common.appointment",
      prop: "extra.slot.start_datetime",
      isDateTime: true,
    },
    { field: "agent", label: "common.agent", prop: "extra.operator.full_name" },
  ];

  const getColValue = (row, col) => {
    let originalValue = row[col.prop];
    if (col.prop.includes(".")) {
      originalValue = col.prop.split(".").reduce((cur, key) => {
        return cur ? cur[key] : null;
      }, row);
    }

    if (originalValue) {
      if (col.isDateTime) {
        return getFormattedDate(originalValue, DATE_TIME_FORMAT);
      } else if (col.isStatus) {
        return (
          <EnrolmentSupportStatusDot
            status={originalValue}
            showLabel
            labelClass={classes.statusLabel}
          />
        );
      } else if (col.renderFunc) {
        return col.renderFunc(originalValue);
      } else return originalValue;
    } else return "-";
  };

  return !!historyData && historyData.length ? (
    <Box className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => {
              return (
                <TableCell key={col.field} className={classes.cell}>
                  <IntlMessages id={col.label} />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {historyData.map((row, index) => (
            <TableRow key={`row_${index}`}>
              {columns.map((col) => {
                return (
                  <TableCell
                    key={`row_${index}_${col.field}`}
                    className={classes.cell}
                  >
                    {getColValue(row, col)}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  ) : (
    <NoResults />
  );
};

export { HistoryLog };
