import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { NotFound } from "../../components/generic/Error/NotFound";
import { Logo } from "../../components/generic/Logo";
import { PrivacyPolicy } from "../../components/generic/PrivacyPolicy/PrivacyPolicy";
import { languageSelector } from "../../reducers/selectors";
import { ORG_SHORT_NAME } from "../../util/appInfo";

const DocumentsLayout = () => {
  const language = useSelector(languageSelector);

  return (
    <Box className="container-fluid overflow-auto">
      <Box className="container d-flex flex-column align-items-center justify-content-start mt-5">
        <div
          className="logo-lg col-6 col-md-3 col-lg-2 mb-5"
          title={ORG_SHORT_NAME}
        >
          <Logo
            id="unjspf_logo"
            language={language}
            useSquare
            className="img-fluid"
          />
        </div>

        <div className="d-flex align-items-center">
          <Routes>
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Box>
    </Box>
  );
};

export { DocumentsLayout };
