import { Button, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import IntlMessages from "../../../../util/IntlMessages";

const SubmitButton = ({ loader, onSubmit, disabled }) => {
  return (
    <div className="mb-3 d-flex align-items-center justify-content-between">
      <Button
        onClick={() => {
          onSubmit();
        }}
        variant="contained"
        color="primary"
        disabled={disabled}
      >
        {loader && (
          <CircularProgress size={18} color="inherit" className="me-2" />
        )}
        <IntlMessages id="appModule.submit" />
      </Button>
    </div>
  );
};

SubmitButton.propTypes = {
  loader: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export { SubmitButton };
