import { Autocomplete, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import IntlMessages from "../../../util/IntlMessages";
import { ChipsPanel } from "../ChipsPanel";
import { SearchBox } from "../SearchBox";

const useStyles = makeStyles(() => ({
  chipsPanel: {
    marginTop: "10px",
  },
}));

const SearchSelect = ({
  label,
  labelTranslated,
  value,
  options,
  valueProp,
  labelProp,
  onChange,
  labelClass,
}) => {
  const classes = useStyles();
  const valueProperty = valueProp ?? "value";
  const labelProperty = labelProp ?? "title";
  const chipLabelProperty = labelProp ?? "label";

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (options && value) {
      setSelectedOptions(
        options.filter((op) => value.includes(op[valueProperty]))
      );
    }
  }, [value]);

  const translatedLabel = label ? <IntlMessages id={label} /> : labelTranslated;

  const handleChange = (_, value) => {
    setSelectedOptions(value);
    onChange(value.map((v) => v[valueProperty]));
  };

  const onDelete = (option) => {
    const newOptions = selectedOptions.filter(
      (op) => op[valueProperty] !== option[valueProperty]
    );

    setSelectedOptions(newOptions);
    onChange(
      newOptions.length > 0
        ? newOptions.map((v) => v[valueProperty])
        : undefined
    );
  };

  return (
    <>
      {!!translatedLabel && (
        <Typography className={labelClass}>{translatedLabel}</Typography>
      )}
      <Autocomplete
        autoHighlight
        multiple
        value={selectedOptions}
        options={options}
        getOptionLabel={(option) => option[labelProperty]}
        onChange={handleChange}
        renderInput={(props) => <SearchBox autoCompleteProps={props} />}
        renderTags={() => null}
      />
      <ChipsPanel
        options={selectedOptions}
        idProperty={valueProperty}
        labelProperty={chipLabelProperty}
        deleteOption={onDelete}
        containerClass={classes.chipsPanel}
      />
    </>
  );
};

export { SearchSelect };
