import { CloseOutlined } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUserAppInstalls,
  revokeAppInstall,
} from "../../../../../actions/Beneficiaries";
import {
  CLEAR_APP_INSTALLS,
  REVOKE_APP_INSTALL_SUCCESS,
} from "../../../../../constants/ActionTypes";
import { APP_INSTALL_STATUS } from "../../../../../constants/common";
import {
  appInstallsCountSelector,
  appInstallsSelector,
  loadingAppInstalls,
} from "../../../../../reducers/selectors";
import { getFormattedDate } from "../../../../../util/dates";
import IntlMessages from "../../../../../util/IntlMessages";
import { DataTable } from "../../../../generic/DataTable/DataTable";
import { StatusDot } from "../../../../generic/Status/StatusDot";
import { useIntl } from "react-intl";

const DATE_FORMAT = "DD/MM/YY";

const getColor = (status) => {
  if (status !== null) {
    return status ? "success" : "danger";
  } else return null;
};

const useStyles = makeStyles(() => ({
  cell: {
    borderBottom: 0,
  },
  statusLabel: {
    fontSize: "14px",
  },
  deleteIcon: {
    color: "#7E7E7E",
  },
}));

const UserDevicesList = ({ beneficiary, defaultPagination }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const intl = useIntl();
  const appInstalls = useSelector(appInstallsSelector);
  const loading = useSelector(loadingAppInstalls);
  const count = useSelector(appInstallsCountSelector);

  defaultPagination = defaultPagination || {
    ordering: "-created",
    size: 1000,
  };

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_APP_INSTALLS });
  };

  useEffect(() => clear, []);
  // #endregion

  // #region Load user app installs
  const fetchData = () => {
    if (!loading && beneficiary) {
      dispatch(
        loadUserAppInstalls({ ...defaultPagination, userId: beneficiary?.id })
      );
    }
  };

  useEffect(() => fetchData(), [beneficiary]);
  // #endregion

  // #region Actions
  const revoke = async (event, id) => {
    event.preventDefault();
    const result = await dispatch(revokeAppInstall({ id }));
    if (result.type === REVOKE_APP_INSTALL_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.profile.invitations.revokeSuccess" />
      );
      fetchData();
    } else {
      NotificationManager.error(
        <IntlMessages id="appModule.errorTryAgainLater" />
      );
    }
  };
  // #endregion

  const columnData = [
    {
      id: "device",
      align: "left",
      label: "common.device",
    },
    {
      id: "created",
      align: "left",
      label: "common.created",
      handler: (row) => getFormattedDate(row.created, DATE_FORMAT),
    },
    {
      id: "version",
      align: "left",
      label: "common.version",
      handler: (row) => row?.version ?? "-",
    },
    {
      id: "is_valid",
      align: "left",
      label: "common.valid",
      handler: (row) => (
        <StatusDot
          showLabel
          status={row.status === APP_INSTALL_STATUS.APPROVED}
          statusValues={{ true: "common.yes", false: "common.no" }}
          colorClass={getColor(row.status === APP_INSTALL_STATUS.APPROVED)}
          labelClass={classes.statusLabel}
        />
      ),
    },
  ];

  const actions = [
    {
      id: "delete",
      label: "",
      handler: (row) => (
        <IconButton
          title={intl.formatMessage({ id: "common.delete" })}
          onClick={(event) => revoke(event, row.id)}
        >
          <CloseOutlined fontSize="small" className={classes.deleteIcon} />
        </IconButton>
      ),
    },
  ];

  return (
    <Box>
      {!loading && beneficiary && (
        <DataTable
          id="devicesList"
          columnData={columnData}
          tableData={appInstalls}
          actions={actions}
          initialPagination={defaultPagination}
          noSearchResultsText={
            <IntlMessages id="pages.profile.appInstallations.notInstalled" />
          }
          count={count}
          cellClass={classes.cell}
          showOnlyTable
          hideFooter
        />
      )}
    </Box>
  );
};

export { UserDevicesList };
