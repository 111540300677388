import {
  DeleteOutlined,
  FeedbackOutlined,
  NavigateBefore,
} from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IntlMessages from "../../../../util/IntlMessages";
import { SideBar } from "../../../generic/SideBar";

const useStyles = makeStyles(() => ({
  goBack: {
    justifyContent: "start",
    fontSize: "16px",
    textTransform: "none",
  },
  gridContainer: {
    height: "calc(100vh - 130px)",
    padding: "0 50px",
    alignContent: "center",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
  deleteWarning: {
    fontWeight: "700",
    textAlign: "center",
  },
  deleteMessage: {
    textAlign: "center",
  },
  deleteButton: {
    padding: "12px 16px",
    textTransform: "none",
    margin: "60px 50px 0",
  },
  cancelButton: {
    color: "#C62222",
    textTransform: "none",
  },
}));

const DeleteAppointment = ({
  appointment,
  goBack,
  deleteAppointment,
  toggleDrawer,
}) => {
  // #region Hooks
  const classes = useStyles();
  // #endregion

  return (
    <>
      <SideBar
        title="pages.schedule.appointment.title"
        toggleDrawer={toggleDrawer}
        hideActions
      >
        {/* Go back */}
        <Button
          color="primary"
          className={classes.goBack}
          startIcon={<NavigateBefore />}
          onClick={() => goBack(appointment)}
          disableElevation
        >
          <IntlMessages id="pages.schedule.appointment.delete" />
        </Button>
        {/* Message and actions */}
        <Grid container spacing={2} className={classes.gridContainer}>
          <Grid item xs={12} className={classes.gridItem}>
            <FeedbackOutlined color="primary" fontSize="large" />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography variant="h5" className={classes.deleteWarning}>
              <IntlMessages id="pages.schedule.appointment.delete.warning" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography className={classes.deleteMessage}>
              <IntlMessages id="pages.schedule.appointment.delete.message" />
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Button
              variant="contained"
              color="primary"
              className={classes.deleteButton}
              startIcon={<DeleteOutlined />}
              onClick={() => deleteAppointment(appointment)}
              disableElevation
            >
              <IntlMessages id="pages.schedule.appointment.delete.deleteAppointment" />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <Button
              className={classes.cancelButton}
              onClick={() => goBack(appointment)}
              disableElevation
            >
              <IntlMessages id="common.cancel" />
            </Button>
          </Grid>
        </Grid>
      </SideBar>
    </>
  );
};

export { DeleteAppointment };
