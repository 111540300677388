import ColorHash from "color-hash";
import { StatusDot } from "../components/generic/Status/StatusDot";
import { ROLES } from "../constants/User";
import {
  CALL_EVENT_TYPE,
  CALL_STATUS,
  ENROL_REJECT_FACE,
  ENROL_REJECT_FACE_OPTIONS,
  ENROL_REJECT_FLOW_STEP,
  ENROL_REJECT_ID_DOC,
  ENROL_REJECT_ID_DOC_OPTIONS,
  ENROL_REJECT_REASONS,
} from "../constants/common";
import { getFormattedDate } from "./dates";
import { capitalizeFirst } from "./index";

export const isUserBeneficiary = (userData) =>
  !!userData?.groups.find((group) => group.name === ROLES.BENEFICIARY);

export const userBelongsToSupportGroup = (userData) => {
  return Boolean(
    userData?.groups?.find((group) =>
      [
        ROLES.OPERATOR.toLowerCase(),
        ROLES.HELPDESK_MANAGER.toLowerCase(),
        ROLES.TASK_FORCE.toLowerCase(),
      ].includes(group?.name.toLowerCase())
    )
  );
};

export const userIsHelpDeskManager = (userData) => {
  return Boolean(
    userData?.groups?.find((group) =>
      [ROLES.HELPDESK_MANAGER.toLowerCase()].includes(group?.name.toLowerCase())
    )
  );
};

export const userIsAgent = (userData) => {
  return Boolean(
    userData?.groups?.find((group) =>
      [ROLES.TASK_FORCE.toLowerCase()].includes(group?.name.toLowerCase())
    )
  );
};

export const userIsAdmin = (userData) => {
  return Boolean(
    userData?.groups?.find((group) =>
      [
        ROLES.UNJSPF_ADMIN.toLowerCase(),
        ROLES.HELPDESK_MANAGER.toLowerCase(),
      ].includes(group?.name.toLowerCase())
    )
  );
};

export const userIsReadOnly = (userData) => {
  return Boolean(
    userData?.groups?.find((group) =>
      [ROLES.READ_ONLY.toLowerCase()].includes(group?.name.toLowerCase())
    )
  );
};

export const getOrganizationFullTitle = (organization) =>
  organization
    ? `${organization?.acronym} - ${organization?.name}`
    : "Not Available";

export const formatRetirementDate = (retirementDate) =>
  retirementDate ? getFormattedDate(retirementDate, "MMM D YYYY") : null;

export const formatDateForm = (date) =>
  date ? getFormattedDate(date, "Y-MM-DD") : null;

export const getFullName = (user, upperCase = false) => {
  if (!user) {
    return "";
  } else {
    if (user && !user.first_name && !user.last_name && !user.middle_name) {
      return "";
    }

    const firstName = user?.first_name ? capitalizeFirst(user.first_name) : "";
    const middleName = user?.middle_name
      ? capitalizeFirst(user.middle_name)
      : "";
    const lastName = user?.last_name ? capitalizeFirst(user.last_name) : "";

    let fullName =
      middleName !== ""
        ? `${firstName} ${middleName} ${lastName}`
        : `${firstName} ${lastName}`;
    if (upperCase) {
      fullName = fullName.toUpperCase();
    }
    return fullName;
  }
};

export const getInitialsName = (operator) => {
  let firstLetter = "";
  if (operator && operator.first_name) {
    firstLetter = capitalizeFirst(operator.first_name).slice(0, 1);
  }

  let lastLetter = "";
  if (operator && operator.last_name) {
    lastLetter = capitalizeFirst(operator.last_name).slice(0, 1);
  }
  return `${firstLetter}${lastLetter}`;
};

export const getReducedName = (beneficiary, upperCase = false) => {
  let firstName = "";
  if (beneficiary && beneficiary.first_name) {
    firstName = capitalizeFirst(beneficiary.first_name).slice(0, 1);
  }

  let middleName = "";
  if (beneficiary && beneficiary.middle_name) {
    middleName = capitalizeFirst(beneficiary.last_name).slice(0, 1);
  }

  let lastName = "";
  if (beneficiary && beneficiary.last_name) {
    lastName = capitalizeFirst(beneficiary.last_name);
  }

  let fullName = middleName
    ? `${firstName}. ${middleName}. ${lastName}`
    : `${firstName}. ${lastName}`;
  if (upperCase) {
    fullName = fullName.toUpperCase();
  }
  return fullName;
};

export const getDarkerColorFromName = (fullName) => {
  const color = new ColorHash({ lightness: 0.4, saturation: 0.2 }).hex(
    fullName
  );
  return color;
};

export const getLightColorFromName = (fullName) => {
  const color = new ColorHash({ lightness: 0.9, saturation: 0.2 }).hex(
    fullName
  );
  return color;
};

export const getColorFromName = (fullName) => {
  const color = new ColorHash({ lightness: 0.55, saturation: 0.3 }).hex(
    fullName
  );
  return color;
};

const getReason = (value, options, greenOption) => {
  if (options && Array.isArray(options)) {
    const statusValues = {};
    options.map((option) => {
      statusValues[option.value] = option.title;
    });

    return (
      <StatusDot
        showLabel
        status={value}
        statusValues={statusValues}
        colorStyle={value === greenOption ? "#4DA551" : "#C62222"}
        alignItems="baseline"
      />
    );
  } else return value;
};

export const getRejectedReasons = (rejectedReasons) => {
  if (rejectedReasons && Object.keys(rejectedReasons).length) {
    const { face, issue_id, other } = rejectedReasons;

    const faceDot = getReason(
      face,
      ENROL_REJECT_FACE_OPTIONS,
      ENROL_REJECT_FACE.VALID
    );
    const issueDot = getReason(
      issue_id,
      ENROL_REJECT_ID_DOC_OPTIONS,
      ENROL_REJECT_ID_DOC.VALID
    );

    return {
      [ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS]]: faceDot,
      [ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS]]: issueDot,
      [ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.OTHER_REASONS]]: other,
    };
  }
};

export const getCallsHistory = (callEvents) => {
  const userCalls = [];
  [...callEvents]
    .sort((a, b) => a.created.localeCompare(b.created))
    .forEach((event, index, allEvents) => {
      const {
        event_type: currType,
        created: currDate,
        operator: currAgent,
      } = event;

      if (index > 0) {
        const {
          event_type: prevType,
          created: prevDate,
          operator: prevAgent,
        } = allEvents[index - 1];

        if (prevType === CALL_EVENT_TYPE.REQUEST) {
          if (currType === CALL_EVENT_TYPE.ENDED) {
            // Call performed - call requested and call ended in sequence (something happened with call answered)
            userCalls.push({
              startDate: prevDate,
              status: CALL_STATUS.PERFORMED,
              answerDate: null,
              endDate: currDate,
              agent: getFullName(prevAgent),
            });
          } else if (currType !== CALL_EVENT_TYPE.ANSWERED) {
            // Call attempted - call requested but no call answered in sequence
            userCalls.push({
              startDate: prevDate,
              status: CALL_STATUS.ATTEMPTED,
              answerDate: null,
              endDate: null,
              agent: getFullName(prevAgent),
            });
          }
        }

        if (index > 1) {
          const {
            event_type: firstType,
            created: firstDate,
            operator: firstAgent,
          } = allEvents[index - 2];

          if (
            firstType === CALL_EVENT_TYPE.REQUEST &&
            prevType === CALL_EVENT_TYPE.ANSWERED
          ) {
            if (currType === CALL_EVENT_TYPE.ENDED) {
              // Call performed - call requested, call answered and call ended in sequence
              userCalls.push({
                startDate: firstDate,
                status: CALL_STATUS.PERFORMED,
                answerDate: prevDate,
                endDate: currDate,
                agent: getFullName(firstAgent),
              });
            } else {
              // Call interrupted - call requested, call answered in sequence but no call ended
              userCalls.push({
                startDate: firstDate,
                status: CALL_STATUS.INTERRUPTED,
                answerDate: prevDate,
                endDate: null,
                agent: getFullName(firstAgent),
              });
            }
          }
        }
      }

      if (
        (allEvents.length === 1 || index === allEvents.length - 1) &&
        currType === CALL_EVENT_TYPE.REQUEST
      ) {
        // Call attempted - call requested but no call answered in sequence (in case only one call event)
        userCalls.push({
          startDate: currDate,
          status: CALL_STATUS.ATTEMPTED,
          answerDate: null,
          endDate: null,
          agent: getFullName(currAgent),
        });
      }
    });
  return userCalls;
};
