import { PeopleOutline } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  Hidden,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { createInstantCall } from "../../actions/Beneficiaries";
import {
  bookAppointment,
  createOperatorsAvailability,
  deleteVerificationAppointment,
  getVideoCallUrl,
  listOperatorsAppointments,
} from "../../actions/SupportSchedule";
import { loadUsersData } from "../../actions/User";
import {
  BOOK_APPOINTMENT_FAIL,
  BOOK_APPOINTMENT_SUCCESS,
  CLEAR_AVAILABILITY_SLOTS,
  CLEAR_USERS_DATA,
  CLEAR_VERIFICATION_APPOINTMENTS,
  CLEAR_VIDEO_CALL_URL,
  CREATE_INSTANT_CALL_FAIL,
  CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL,
  CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS,
  DELETE_OPERATOR_APPOINTMENT_FAIL,
  DELETE_OPERATOR_APPOINTMENT_SUCCESS,
  LOAD_VERIFICATION_APPOINTMENTS_SUCCESS,
} from "../../constants/ActionTypes";
import { ROLES } from "../../constants/User";
import {
  VideoCallUrlSelector,
  appointmentsRequestInProgress,
  calendarEventsSelector,
  currentUserIsAgentSelector,
  currentUserIsHelpDeskManagerSelector,
  operatorsListForSelectSelector,
  userIdSelector,
  usersLoadingSelector,
  videoCallTokenRequestInProgress,
} from "../../reducers/selectors";
import IntlMessages from "../../util/IntlMessages";
import { ChipsPanel } from "../generic/ChipsPanel";
import { SwitchButton } from "../generic/SwitchButton";
import { Widget } from "../generic/Widget/Widget";
import { AddAvailability } from "./AddAvailability";
import { AgentsFilters } from "./AgentsFilters";
import { AppointmentDetails } from "./AppointmentDetails";
import { DeleteAppointment } from "./AppointmentDetails/DeleteAppointment";
import { EditAppointment } from "./EditAppointment";
import { ScheduleView } from "./ScheduleView";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  agentsContainer: {
    display: "flex",
    justifyContent: "end",
  },
  agentsFilters: {
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    overflow: "auto",
    marginRight: "5px",
  },
  agentsScrollablePanel: {
    minHeight: "unset",
  },
  agentsScrollableDiv: {
    marginBottom: "0",
  },
  agentsScrollButtons: {
    color: theme.palette.primary.main,
  },
  agentFilterItem: {
    padding: theme.spacing(0.5),
  },
  agentFilterItemDelete: {
    color: "#fff",
    width: "18px",
  },
  agentsFilterButton: {
    textTransform: "none",
    minWidth: "unset",
  },
}));

const Sidebar = {
  FILTERS: 1,
  APPOINTMENT_DETAILS: 2,
  APPOINTMENT_DELETE: 3,
  APPOINTMENT_EDIT: 4,
  AVAILABILITY_ADD: 5,
};

const Schedule = () => {
  // #region Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const operators = useSelector(operatorsListForSelectSelector);
  const appointmentsForCalendar = useSelector(calendarEventsSelector);
  const loadingOperators = useSelector(usersLoadingSelector);
  const loadingAppointments = useSelector(appointmentsRequestInProgress);
  const loadingVideoCallToken = useSelector(videoCallTokenRequestInProgress);
  const videoCallUrl = useSelector(VideoCallUrlSelector);
  const userId = useSelector(userIdSelector);
  const userIsAgent = useSelector(currentUserIsAgentSelector);
  const userIsHelpDeskManager = useSelector(
    currentUserIsHelpDeskManagerSelector
  );
  const [viewDateRange, setViewDateRange] = useState({});
  const [selectedOperatorsIds, setSelectedOperatorsIds] = useState([]);
  const [filters, setFilters] = useState({
    appointments: true,
    availability: true,
  });
  const [drawer, setDrawer] = useState({
    open: false,
    component: null,
    payload: null,
  });
  // #endregion

  // #region Clear data on leave
  const clear = () => {
    dispatch({ type: CLEAR_USERS_DATA });
    dispatch({ type: CLEAR_VERIFICATION_APPOINTMENTS });
    dispatch({ type: CLEAR_VIDEO_CALL_URL });
    dispatch({ type: CLEAR_AVAILABILITY_SLOTS });
  };

  useEffect(() => clear, []);
  // #endregion

  // #region Load operators
  const fetchOperatorUsersData = () => {
    const fetchUsersParams = {
      groups__name__in: [ROLES.OPERATOR, ROLES.TASK_FORCE].join(","),
      size: 1000,
    };
    dispatch(loadUsersData({ ...fetchUsersParams }));
  };

  useEffect(() => {
    if (!loadingOperators) {
      fetchOperatorUsersData();
    }
  }, []);
  // #endregion

  // #region Load appointments
  const fetchAppointments = () => {
    const operatorsIds = selectedOperatorsIds.length
      ? selectedOperatorsIds
      : operators.map((operator) => operator.id);
    return dispatch(
      listOperatorsAppointments({
        operatorsIds,
        startTime: dayjs(viewDateRange.start).valueOf(),
        endTime: dayjs(viewDateRange.end).valueOf(),
        onboarding__isnull: onboardingIsNull(),
      })
    );
  };

  useEffect(() => {
    if (userId && userIsAgent && !userIsHelpDeskManager) {
      setSelectedOperatorsIds([userId]);
    }
  }, [userId]);

  useEffect(() => {
    if (
      operators &&
      !loadingAppointments &&
      viewDateRange.start &&
      viewDateRange.end
    ) {
      fetchAppointments();
    }
  }, [operators, selectedOperatorsIds, viewDateRange, filters]);
  // #endregion

  // #region Handle filters
  const toggleShowAppointments = (event) => {
    const showAppointments = event.target.checked;
    const showAvailability = showAppointments ? filters.availability : true;
    setFilters({
      appointments: showAppointments,
      availability: showAvailability,
    });
  };

  const toggleShowAvailability = (event) => {
    const showAvailability = event.target.checked;
    const showAppointments = showAvailability ? filters.appointments : true;
    setFilters({
      appointments: showAppointments,
      availability: showAvailability,
    });
  };

  const deleteAgentFromSelected = (agent) => {
    const operatorToDeleteIdx = selectedOperatorsIds.findIndex(
      (op) => op === agent.id
    );
    selectedOperatorsIds.splice(operatorToDeleteIdx, 1);
    setSelectedOperatorsIds([...selectedOperatorsIds]);
  };

  const onboardingIsNull = () => {
    const { appointments, availability } = filters;
    if (!appointments) return true;
    else if (!availability) return false;
    else return null;
  };
  // #endregion

  // #region Appointment
  const openAppointment = (appointment) => {
    if (appointment?.onboarding) {
      dispatch(getVideoCallUrl(appointment?.onboarding?.user?.id));
    }
    toggleDrawer(true, Sidebar.APPOINTMENT_DETAILS, appointment);
  };

  const openDeleteAppointment = (appointment) => {
    toggleDrawer(true, Sidebar.APPOINTMENT_DELETE, appointment);
  };

  const openEditAppointment = (appointment) => {
    toggleDrawer(true, Sidebar.APPOINTMENT_EDIT, appointment);
  };

  const deleteAppointment = async (appointment) => {
    toggleDrawer(false);

    const result = await dispatch(
      deleteVerificationAppointment(appointment?.id)
    );
    if (result.type === DELETE_OPERATOR_APPOINTMENT_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.supportSchedule.appointments.delete.success" />
      );
      fetchAppointments();
    } else if (result.type === DELETE_OPERATOR_APPOINTMENT_FAIL) {
      NotificationManager.error(
        <IntlMessages id="pages.supportSchedule.appointments.delete.error" />
      );
    }
  };

  const assignEnrolment = async (appointment, enrolment) => {
    toggleDrawer(false);

    const result = await dispatch(bookAppointment(appointment, enrolment));
    if (result.type === BOOK_APPOINTMENT_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.schedule.slotAvailable.addAppointment.success" />
      );
      fetchAppointments();
    } else if (result.type === BOOK_APPOINTMENT_FAIL) {
      NotificationManager.error(
        <IntlMessages id="pages.schedule.slotAvailable.addAppointment.error" />
      );
    }
  };

  const saveAppointment = async (newAppointmentValues) => {
    const { selectedAgent, selectedSlot, enrolment } = newAppointmentValues;
    const appointment = { slot: selectedSlot, operator: selectedAgent };

    const result = await dispatch(bookAppointment(appointment, enrolment));
    if (result.type === BOOK_APPOINTMENT_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.supportSchedule.appointments.reassign.success" />
      );
      const fetchResult = await fetchAppointments();
      if (fetchResult.type === LOAD_VERIFICATION_APPOINTMENTS_SUCCESS) {
        const appointmentsByEnrolment = fetchResult.payload?.data
          .filter((appt) => appt?.onboarding?.id === enrolment?.id)
          .sort((a, b) => b.slot?.start_datetime - a.slot?.start_datetime);

        if (!!appointmentsByEnrolment && !!appointmentsByEnrolment.length) {
          const newAppointment = appointmentsByEnrolment[0];
          toggleDrawer(true, Sidebar.APPOINTMENT_DETAILS, {
            ...newAppointment,
          });
        } else toggleDrawer(false);
      } else toggleDrawer(false);
    }
    if (result.type === BOOK_APPOINTMENT_FAIL) {
      NotificationManager.error(
        <IntlMessages id="pages.supportSchedule.appointments.reassign.error" />
      );
    }
  };

  const joinVideoCall = async (appointment) => {
    const userId = appointment?.onboarding?.user?.id;
    if (videoCallUrl) {
      toggleDrawer(false);
      window.open(videoCallUrl, "_blank");

      const callAction = await dispatch(createInstantCall({ userId }));
      if (callAction.type === CREATE_INSTANT_CALL_FAIL) {
        NotificationManager.error(
          <IntlMessages id="pages.supportSchedule.appointments.callError" />
        );
      }
    } else {
      NotificationManager.error(
        <IntlMessages id="pages.supportSchedule.appointments.joinError" />
      );
    }
  };
  // #endregion

  // #region Availability
  const openAddAvailability = (selectionInfo) => {
    toggleDrawer(true, Sidebar.AVAILABILITY_ADD, selectionInfo);
  };

  const addAvailability = async (info) => {
    toggleDrawer(false);

    const result = await dispatch(createOperatorsAvailability(info));
    if (result.type === CREATE_OPERATOR_AVAILABILITY_SLOTS_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.supportSchedule.availabilityRegisteredSuccess" />
      );
      fetchAppointments();
    } else if (result.type === CREATE_OPERATOR_AVAILABILITY_SLOTS_FAIL) {
      NotificationManager.error(
        <IntlMessages id="pages.supportSchedule.availabilityRegisteredError" />
      );
    }
  };
  // #endregion

  // #region Drawer config
  const toggleDrawer = (open, component, payload) => {
    setDrawer({ open, component, payload });
  };

  function renderDrawerComponents() {
    switch (drawer.component) {
      case Sidebar.FILTERS:
        return (
          <AgentsFilters
            agents={operators}
            agentsSelectedIds={selectedOperatorsIds}
            setAgentsSelectedIds={setSelectedOperatorsIds}
            toggleDrawer={toggleDrawer}
          />
        );
      case Sidebar.APPOINTMENT_DETAILS:
        return (
          <AppointmentDetails
            appointment={drawer.payload}
            openDelete={openDeleteAppointment}
            deleteAppointment={deleteAppointment}
            openEditAppointment={openEditAppointment}
            joinVideoCall={joinVideoCall}
            bookAppointment={assignEnrolment}
            loadingJoin={loadingVideoCallToken}
            canJoinVideoCall={Boolean(videoCallUrl)}
            toggleDrawer={toggleDrawer}
          />
        );
      case Sidebar.APPOINTMENT_DELETE:
        return (
          <DeleteAppointment
            appointment={drawer.payload}
            goBack={openAppointment}
            deleteAppointment={deleteAppointment}
            toggleDrawer={toggleDrawer}
          />
        );
      case Sidebar.APPOINTMENT_EDIT:
        return (
          <EditAppointment
            appointment={drawer.payload}
            goBack={openAppointment}
            saveAppointment={saveAppointment}
            toggleDrawer={toggleDrawer}
          />
        );
      case Sidebar.AVAILABILITY_ADD:
        return (
          <AddAvailability
            selection={drawer.payload}
            agents={operators}
            addAvailability={addAvailability}
            toggleDrawer={toggleDrawer}
          />
        );
    }
  }
  // #endregion

  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={loadingOperators || loadingAppointments}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Widget styleName="jr-card-profile">
        <Grid container spacing={3} justifyContent="space-between">
          {/* Filters */}
          <Grid item>
            <SwitchButton
              label="pages.schedule.filters.appointments"
              checked={filters.appointments}
              onChange={toggleShowAppointments}
            />
            <SwitchButton
              label="pages.schedule.filters.availability"
              checked={filters.availability}
              onChange={toggleShowAvailability}
            />
          </Grid>
          {/* Operators selector */}
          <Grid item xs={12} sm={6} md={7} className={classes.agentsContainer}>
            {selectedOperatorsIds && operators && (
              <ChipsPanel
                options={operators.filter((op) =>
                  selectedOperatorsIds?.includes(op.id)
                )}
                labelProperty="fullName"
                deleteOption={deleteAgentFromSelected}
              />
            )}
            <Button
              variant="outlined"
              color="primary"
              className={classes.agentsFilterButton}
              startIcon={<PeopleOutline />}
              onClick={() => toggleDrawer(true, Sidebar.FILTERS)}
            >
              <IntlMessages id="pages.schedule.agents" />
            </Button>
          </Grid>
          {/* Schedule */}
          <Grid item xs={12}>
            {/* Appointments Schedule view */}
            <ScheduleView
              events={appointmentsForCalendar}
              selectDatesRange={setViewDateRange}
              openApointment={openAppointment}
              openAddAvailability={openAddAvailability}
            />
          </Grid>
        </Grid>
      </Widget>
      {/* Sidebar large screens */}
      <Hidden xsDown>
        <Drawer
          anchor="right"
          open={drawer.open}
          onClose={() => toggleDrawer(false)}
        >
          <Box sx={{ width: 500 }}>{renderDrawerComponents()}</Box>
        </Drawer>
      </Hidden>
      {/* Sidebar mobile screens */}
      <Hidden smUp>
        <Drawer
          anchor="right"
          open={drawer.open}
          onClose={() => toggleDrawer(false)}
        >
          <Box sx={{ width: "100vw" }}>{renderDrawerComponents()}</Box>
        </Drawer>
      </Hidden>
    </div>
  );
};

export { Schedule };
