import { InfoOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { loadOrganizations } from "../../actions/Common";
import { CLEAR_ERRORS, CLEAR_ORGANIZATIONS } from "../../constants/ActionTypes";
import { ROUTES } from "../../constants/Routes";
import { NOTIFICATION_PREFERENCES, ROLES } from "../../constants/User";
import { DATE_TIME_SHORT_FORMAT } from "../../constants/common";
import { SUPPORTED_LANGUAGES } from "../../lngProvider/constants";
import {
  loadingOrganizationsSelector,
  organizationsSelector,
  userErrors,
} from "../../reducers/selectors";
import { capitalizeFirst } from "../../util";
import IntlMessages from "../../util/IntlMessages";
import { getFormattedDate } from "../../util/dates";
import { getOrganizationFullTitle } from "../../util/user";
import styles from "./User.module.scss";
import { UserField } from "./UserField";

const LANGUAGES = SUPPORTED_LANGUAGES.map((lang) => {
  return {
    id: lang,
    label: `common.language.${lang}`,
  };
});

const UserForm = ({ user, isBeneficiary, saveAction, cancelAction }) => {
  const isCreateMode = !user;
  const rolesList = Object.values(ROLES)
    .filter((role) => role !== ROLES.BENEFICIARY)
    .map((role) => {
      return { value: role, label: `common.role.${role}` };
    });
  const isManager = !isBeneficiary;

  const intl = useIntl();
  const errorMessages = {
    cannotBeEmpty: intl.formatMessage({ id: "validation.fieldCannotBeEmpty" }),
    mustBeNumber: intl.formatMessage({ id: "validation.mustBeNumber" }),
    mustHaveLength: (length) =>
      intl.formatMessage({ id: "validation.mustHaveLength" }, { length }),
  };
  const dispatch = useDispatch();
  const organizations = useSelector(organizationsSelector);
  const loadingOrganizations = useSelector(loadingOrganizationsSelector);
  const apiErrors = useSelector(userErrors);
  const isMyProfile = location.pathname.includes(ROUTES.MY_PROFILE);

  const [username, setUsername] = useState(user?.username || "");
  const [first_name, setFirstName] = useState(user?.first_name || null);
  const [middle_name, setMiddleName] = useState(user?.middle_name || null);
  const [last_name, setLastName] = useState(user?.last_name || null);
  const [email, setEmail] = useState(user?.email || null);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || null);
  const [organization, setOrganization] = useState(user?.organization || null);
  const [notificationPreferences, setNotificationPreferences] = useState(
    NOTIFICATION_PREFERENCES.find(
      (pref) => pref.id === user?.notification_preferences
    )
  );
  const [language, setLanguage] = useState(
    LANGUAGES.find((lang) => user?.profile?.language === lang.id)
  );
  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState(
    user?.groups?.map((group) => group.name) || []
  );

  const clear = () => {
    dispatch({ type: CLEAR_ERRORS });
    dispatch({ type: CLEAR_ORGANIZATIONS });
  };

  // Clear data on leave
  useEffect(() => clear, []);

  const displayErrors = (field) => {
    if (!errors[field]) {
      return "";
    }
    return errors[field].join(", ");
  };

  const isFormValid = () => {
    const formErrors = {};
    if (!first_name) {
      formErrors.first_name = [errorMessages.cannotBeEmpty];
    }
    if (!last_name) {
      formErrors.last_name = [errorMessages.cannotBeEmpty];
    }
    if (!email) {
      formErrors.email = [errorMessages.cannotBeEmpty];
    }
    if (!organization) {
      formErrors.organization = [errorMessages.cannotBeEmpty];
    }
    if (!notificationPreferences) {
      formErrors.notification_preferences = [errorMessages.cannotBeEmpty];
    }
    if (isBeneficiary && !phoneNumber) {
      formErrors.phone_number = [errorMessages.cannotBeEmpty];
    }
    if (isCreateMode) {
      if (!username) {
        formErrors.username = [errorMessages.cannotBeEmpty];
      }
      if (!roles || !roles?.length) {
        formErrors.groups = [errorMessages.cannotBeEmpty];
      }
    }
    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    const data = {
      first_name,
      middle_name,
      last_name,
      email,
      organization_id: organization?.id,
      notification_preferences: notificationPreferences?.id,
      group_names: roles,
    };

    if (isMyProfile) {
      data.profile = {
        ...user?.profile,
        language: language?.id,
      };
    }

    if (isBeneficiary && phoneNumber) {
      data.phone_number = phoneNumber.toLowerCase().trim();
    }

    if (isCreateMode) {
      data.username = username;
    }

    await saveAction(data);
  };

  const isRoleChecked = (value) => {
    return roles.includes(value);
  };

  const handleRoleChange = (role, selected) => {
    const newRoles = [...roles];

    if (selected) {
      newRoles.push(role);
    } else {
      const oldValueIdx = newRoles.indexOf(role);
      if (oldValueIdx !== -1) {
        newRoles.splice(oldValueIdx, 1);
      }
    }

    setRoles(newRoles);
  };

  useEffect(() => {
    if (!organizations && !loadingOrganizations) {
      dispatch(loadOrganizations());
    }
  }, []);

  useEffect(() => {
    if (apiErrors && Object.keys(apiErrors).length > 0) {
      setErrors(apiErrors);
    }
  }, [apiErrors]);

  return (
    <Box className={styles.userForm}>
      <form onSubmit={handleSubmit}>
        <Grid container>
          {/* First name */}
          <Grid item xs={12} sm={4} className={styles.field}>
            <UserField
              id="first_name"
              label="common.firstName"
              defaultValue={capitalizeFirst(first_name)}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              inputProps={{ maxLength: 30 }}
              error={!!errors.first_name}
              helperText={displayErrors("first_name")}
              disabled={Boolean(isBeneficiary)}
              required
            />
          </Grid>

          {/* Middle name */}
          <Grid item xs={12} sm={4} className={styles.field}>
            <UserField
              id="middle_name"
              label="common.middleName"
              defaultValue={capitalizeFirst(middle_name)}
              onChange={(e) => setMiddleName(e.target.value)}
              inputProps={{ maxLength: 150 }}
              error={!!errors.middle_name}
              helperText={displayErrors("middle_name")}
              disabled={Boolean(isBeneficiary)}
            />
          </Grid>

          {/* Last name */}
          <Grid item xs={12} sm={4} className={styles.field}>
            <UserField
              id="last_name"
              label="common.lastName"
              defaultValue={capitalizeFirst(last_name)}
              onChange={(e) => setLastName(e.target.value)}
              inputProps={{ maxLength: 150 }}
              error={!!errors.last_name}
              helperText={displayErrors("last_name")}
              disabled={Boolean(isBeneficiary)}
              required
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12} sm={4} className={styles.field}>
            <UserField
              id="email"
              type="email"
              label="common.email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ maxLength: 250 }}
              error={!!errors.email}
              helperText={displayErrors("email")}
              disabled={Boolean(isBeneficiary)}
              required
            />
          </Grid>

          {/* Username */}
          <Grid item xs={12} sm={4} className={styles.field}>
            <UserField
              id="username"
              label="common.login"
              defaultValue={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              inputProps={{ maxLength: 150 }}
              error={!!errors.username}
              helperText={displayErrors("username")}
              disabled={!isCreateMode}
            />
          </Grid>

          {/* Phone number */}
          {isBeneficiary ? (
            <Grid item xs={12} sm={4} className={styles.field}>
              <UserField
                id="phone_number"
                label="common.phoneNumber"
                defaultValue={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                inputProps={{ maxLength: 20 }}
                error={!!errors.phone_number}
                helperText={displayErrors("phone_number")}
              />
            </Grid>
          ) : null}

          {/* Organization */}
          {organizations && (
            <Grid item xs={12} sm={4} className={styles.field}>
              <FormControl
                id="organizations_fc"
                error={!!errors.organization}
                disabled={Boolean(isBeneficiary)}
                fullWidth
                required
              >
                <Autocomplete
                  id="organization"
                  name="organization_id"
                  value={organization}
                  onChange={(e, newOrg) => setOrganization(newOrg)}
                  options={organizations}
                  getOptionSelected={(option, { value }) =>
                    option.item?.id === value?.id
                  }
                  getOptionLabel={(org) => getOrganizationFullTitle(org)}
                  renderInput={(params) => (
                    <UserField
                      {...params}
                      label="common.organization"
                      required
                    />
                  )}
                  disabled={Boolean(isBeneficiary)}
                  required
                />
                <FormHelperText id="organization-error-text">
                  {displayErrors("organization")}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

          {/* Creation date */}
          {!isCreateMode && (
            <Grid item xs={12} sm={4} className={styles.field}>
              <UserField
                id="date_joined"
                label="common.userCreation"
                defaultValue={getFormattedDate(
                  user?.date_joined,
                  DATE_TIME_SHORT_FORMAT
                )}
                disabled
              />
            </Grid>
          )}

          {/* Notification preferences */}
          <Grid item xs={12} sm={4} className={styles.field}>
            <FormControl
              id="notification"
              error={!!errors.notification_preferences}
              fullWidth
              required
            >
              <Autocomplete
                id="notification_preferences"
                name="notification_preferences"
                value={notificationPreferences}
                onChange={(e, newPref) => setNotificationPreferences(newPref)}
                options={NOTIFICATION_PREFERENCES}
                getOptionSelected={(option, { value }) =>
                  option.item?.id === value?.id
                }
                getOptionLabel={(pref) =>
                  intl.formatMessage({ id: pref.label })
                }
                renderInput={(params) => (
                  <UserField
                    {...params}
                    label="common.notificationPreferences"
                    required
                  />
                )}
                required
              />
              <FormHelperText id="notification-preferences-error-text">
                {displayErrors("notification_preferences")}
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* Language */}
          {isMyProfile && (
            <Grid item xs={12} sm={4} className={styles.field}>
              <FormControl
                id="language"
                error={!!errors.language}
                fullWidth
                required
              >
                <Autocomplete
                  id="language"
                  name="language"
                  value={language}
                  onChange={(e, newLang) => setLanguage(newLang)}
                  options={LANGUAGES}
                  getOptionSelected={(option, { value }) =>
                    option.item?.id === value?.id
                  }
                  getOptionLabel={(pref) =>
                    intl.formatMessage({ id: pref.label })
                  }
                  renderInput={(params) => (
                    <UserField {...params} label="common.language" />
                  )}
                />
              </FormControl>
            </Grid>
          )}

          {/* User role/group */}
          {isManager && (
            <Grid item xs={12} sm={4} className={styles.field}>
              <FormControl
                id="groups_fc"
                error={!!errors.groups}
                disabled={Boolean(isBeneficiary)}
                fullWidth
                required
              >
                <Typography className={styles.fieldLabel}>
                  <IntlMessages id="common.roles" />
                  {" *"}
                </Typography>
                {rolesList.map((role, index) => {
                  const value = role.value;
                  return (
                    <FormGroup row key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={value}
                            color="primary"
                            value={value}
                            checked={isRoleChecked(value)}
                            onChange={(event) =>
                              handleRoleChange(
                                event.target.value,
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={<IntlMessages id={role.label} />}
                      />
                    </FormGroup>
                  );
                })}
                <FormHelperText id="role-error-text">
                  {displayErrors("groups")}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </form>

      {/* Note */}
      {isManager && isCreateMode ? (
        <Box className={styles.note}>
          <InfoOutlined className={styles.icon} />
          <IntlMessages id="pages.user.createManagerInfo" />
        </Box>
      ) : null}

      {/* Actions */}
      <Box className={styles.actions}>
        <Button
          id="button_cancel"
          color="primary"
          variant="outlined"
          onClick={cancelAction}
          className={styles.action}
          disableElevation
          disableRipple
        >
          <IntlMessages id="common.cancel" />
        </Button>
        <Button
          id="button_save"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          className={styles.action}
          disableElevation
          disableRipple
        >
          <IntlMessages id="common.saveChanges" />
        </Button>
      </Box>
    </Box>
  );
};

export { UserForm };
