import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EVENT_REPETITION } from "../../../constants/common";
import {
  currentUserIsHelpDeskManagerSelector,
  userIdSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Dropdown } from "../../generic/Dropdown";
import { DatePicker, TimePicker } from "../../generic/Pickers";
import { SideBar } from "../../generic/SideBar";
import { AgentsList } from "../AgentsList";

const MIN_EVENT_DURATION = 45; // minutes

const useStyles = makeStyles(() => ({
  detailsBox: {
    marginBottom: "40px",
  },
  detailsTitle: {
    fontWeight: "700",
    marginBottom: "10px",
  },
  detailsSubtitle: {
    fontSize: "1rem",
    marginBottom: "20px",
  },
  picker: {
    "&:not(:last-of-type)": {
      marginBottom: "16px",
    },
  },
}));

const AddAvailability = ({
  selection,
  agents,
  addAvailability,
  toggleDrawer,
}) => {
  // #region Hooks
  const classes = useStyles();
  const { start } = selection;
  const userId = useSelector(userIdSelector);
  const userIsHelpdeskManager = useSelector(
    currentUserIsHelpDeskManagerSelector
  );
  const [fromDateTime, setFromDateTime] = useState(dayjs(start));
  const [toDateTime, setToDateTime] = useState(null);
  const [repetitionFrequency, setRepetitionFrequency] = useState(
    EVENT_REPETITION.NONE
  );
  const [repetitionEndDate, setRepetitionEndDate] = useState(null);
  const [agentsList, setAgentsList] = useState([]);
  const [isAnyAgentSelected, setIsAnyAgentSelected] = useState(false);

  useEffect(() => {
    agents.map((agent) => {
      agent.selected = false;
      return agent;
    });

    if (agents.length === 1 && !userIsHelpdeskManager) {
      agents[0].selected = true;
    }

    setAgentsList(agents);
  }, [agents]);

  useEffect(() => {
    const { start, end } = selection;
    const eventDuration = dayjs(end).diff(dayjs(start), "minutes");

    // Change end date so duration in 45 minutes min
    const endDate =
      eventDuration < MIN_EVENT_DURATION
        ? dayjs(start).add(45, "minutes")
        : dayjs(end);

    setToDateTime(endDate);
  }, [selection]);
  // #endregion

  // #region Pickers methods
  const handleFromDateTimeChange = (fromTime) => {
    setFromDateTime(fromTime);
    adjustToDateTime(fromTime);
  };

  const handleToDateTimeChange = (toTime) => {
    setToDateTime(toTime);
    adjustFromDateTime(toTime);
  };

  const handleRepetitionDateChange = (date) => {
    const dateValue = date.startOf("date");
    if (dateValue.isAfter(toDateTime)) {
      setRepetitionEndDate(dateValue);
    }
  };
  // #endregion

  // #region List methods
  const agentsChanged = (agents) => {
    setAgentsList(agents);
    setIsAnyAgentSelected(!!agents?.filter((a) => a.selected).length);
  };
  // #endregion

  // #region Actions methods
  const saveChanges = () => {
    addAvailability({
      start: fromDateTime,
      end: toDateTime,
      operatorsIds: userIsHelpdeskManager
        ? agentsList.filter((a) => a.selected).map((a) => a.id)
        : [userId],
      repetition: repetitionFrequency,
      endRepetitionDate: repetitionEndDate,
    });
  };
  // #endregion

  // #region Aux methods
  const adjustToDateTime = (fromTime) => {
    const eventDuration = dayjs(toDateTime).diff(dayjs(fromTime), "minutes");
    if (eventDuration < MIN_EVENT_DURATION) {
      const newToDateTime = dayjs(fromTime).add(MIN_EVENT_DURATION, "minutes");
      setToDateTime(newToDateTime);
    }
  };

  const adjustFromDateTime = (toDate) => {
    const eventDuration = dayjs(toDate).diff(dayjs(fromDateTime), "minutes");
    if (eventDuration < MIN_EVENT_DURATION) {
      const newFromDateTime = dayjs(toDate).subtract(
        MIN_EVENT_DURATION,
        "minutes"
      );
      setFromDateTime(newFromDateTime);
    }
  };
  // #endregion

  return (
    <>
      <SideBar
        title="pages.schedule.addAvailability.title"
        toggleDrawer={toggleDrawer}
        cancelLabel="common.cancel"
        cancelFn={() => toggleDrawer(false)}
        saveLabel="common.saveChanges"
        saveFn={saveChanges}
        saveDisabled={
          (!isAnyAgentSelected && userIsHelpdeskManager) ||
          (repetitionFrequency !== EVENT_REPETITION.NONE && !repetitionEndDate)
        }
      >
        {/* Date and time */}
        <Box className={classes.detailsBox}>
          <Typography variant="h6" className={classes.detailsTitle}>
            <IntlMessages id="pages.schedule.addAvailability.dateTime.title" />
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.detailsSubtitle}
          >
            <IntlMessages id="pages.schedule.addAvailability.dateTime.message" />
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DatePicker
                label="pages.schedule.addAvailability.dateTime.from"
                value={fromDateTime}
                onChange={handleFromDateTimeChange}
                containerClass={classes.picker}
                dense
              />
              <TimePicker
                value={fromDateTime}
                onAccept={handleFromDateTimeChange}
                onChange={setFromDateTime}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="pages.schedule.addAvailability.dateTime.to"
                value={toDateTime}
                onChange={handleToDateTimeChange}
                containerClass={classes.picker}
                dense
              />
              <TimePicker
                value={toDateTime}
                onAccept={handleToDateTimeChange}
                onChange={setToDateTime}
              />
            </Grid>
          </Grid>
        </Box>
        {/* Repetition */}
        <Box className={classes.detailsBox}>
          <Typography variant="h6" className={classes.detailsTitle}>
            <IntlMessages id="pages.schedule.addAvailability.repetition.title" />
          </Typography>
          {repetitionFrequency !== EVENT_REPETITION.NONE && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.detailsSubtitle}
            >
              <IntlMessages id="pages.schedule.addAvailability.repetition.message" />
            </Typography>
          )}
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Dropdown
                label="pages.schedule.addAvailability.repetition.frequency"
                value={repetitionFrequency}
                options={Object.values(EVENT_REPETITION).map((option) => {
                  return {
                    value: option,
                    label: (
                      <IntlMessages
                        id={`pages.schedule.repetition.${option}`}
                      />
                    ),
                  };
                })}
                onChange={setRepetitionFrequency}
                dense
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="pages.schedule.addAvailability.repetition.end"
                value={repetitionEndDate}
                onChange={handleRepetitionDateChange}
                disabled={repetitionFrequency === EVENT_REPETITION.NONE}
                dense
              />
            </Grid>
          </Grid>
        </Box>
        {/* Agents */}
        {userIsHelpdeskManager ? (
          <Box>
            <Typography variant="h6" className={classes.detailsTitle}>
              <IntlMessages id="pages.schedule.addAvailability.agents.title" />
            </Typography>
            <AgentsList
              agents={agentsList}
              agentsChanged={(agents) => agentsChanged(agents)}
              showSelectedAsChips
              selectionRequired
            />
          </Box>
        ) : (
          <></>
        )}
      </SideBar>
    </>
  );
};

export { AddAvailability };
