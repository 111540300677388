import { BorderColorOutlined } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { NOTIFICATION_PREFERENCES } from "../../constants/User";
import { DATE_TIME_SHORT_FORMAT } from "../../constants/common";
import { capitalizeFirst } from "../../util";
import IntlMessages from "../../util/IntlMessages";
import { getFormattedDate } from "../../util/dates";
import { getOrganizationFullTitle } from "../../util/user";
import styles from "./User.module.scss";
import { UserField } from "./UserField";

const UserInfo = ({ user, actions }) => {
  const intl = useIntl();
  const {
    first_name,
    middle_name,
    last_name,
    email,
    organization,
    username,
    joined,
    notification_preferences,
    profile,
  } = user;
  const userGroupsNames =
    user?.groups
      ?.map((group) => `${group.name.toUpperCase()}, `)
      ?.join("")
      .slice(0, -2) || "";
  const notificationPreference = NOTIFICATION_PREFERENCES.find(
    (pref) => pref.id === notification_preferences
  )?.label;
  const language = profile?.language
    ? `common.language.${profile.language}`
    : null;

  return (
    <Box className={styles.userInfo}>
      <Grid container>
        {/* First name */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="first_name"
            label="common.firstName"
            defaultValue={capitalizeFirst(first_name)}
            readOnly
          />
        </Grid>

        {/* Middle name */}
        {middle_name ? (
          <Grid item xs={12} sm={4} className={styles.field}>
            <UserField
              id="middle_name"
              label="common.middleName"
              defaultValue={capitalizeFirst(middle_name)}
              readOnly
            />
          </Grid>
        ) : (
          <></>
        )}

        {/* Last name */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="last_name"
            label="common.lastName"
            defaultValue={capitalizeFirst(last_name)}
            readOnly
          />
        </Grid>

        {/* Email */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="email"
            label="common.email"
            defaultValue={email}
            readOnly
          />
        </Grid>

        {/* Username */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="login"
            label="common.login"
            defaultValue={username}
            readOnly
          />
        </Grid>

        {/* Organization */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="organization"
            label="common.organization"
            defaultValue={getOrganizationFullTitle(organization)}
            readOnly
          />
        </Grid>

        {/* Creation date */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="date_joined"
            label="common.userCreation"
            defaultValue={getFormattedDate(joined, DATE_TIME_SHORT_FORMAT)}
            readOnly
          />
        </Grid>

        {/* Roles */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="roles"
            label="common.roles"
            defaultValue={userGroupsNames}
            readOnly
          />
        </Grid>

        {/* Notification preferences */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="notification_preferences"
            label="common.notificationPreferences"
            defaultValue={intl.formatMessage({
              id: notificationPreference ?? "common.notDefined",
            })}
            readOnly
          />
        </Grid>

        {/* Language */}
        <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id="language"
            label="common.language"
            defaultValue={intl.formatMessage({
              id: language ?? "common.notDefined",
            })}
            readOnly
          />
        </Grid>

        {/* Timezone */}
        {/* <Grid item xs={12} sm={4} className={styles.field}>
          <UserField
            id='timezone'
            label='Timezone'
            defaultValue={profile?.timezone}
            readOnly
          />
        </Grid> */}
      </Grid>

      {actions && actions.length > 0 ? (
        <Box className={styles.actions}>
          {actions.map((action, index) => {
            return (
              <Button
                key={index}
                id="button_edit"
                color="primary"
                variant="outlined"
                onClick={() => action.handler()}
                className={styles.action}
                endIcon={<BorderColorOutlined />}
                disableElevation
                disableRipple
              >
                <IntlMessages id={action.title} />
              </Button>
            );
          })}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export { UserInfo };
