import { AppBar, IconButton, Toolbar } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedNav } from "../../../../actions/Settings";
import { UserProfileWidget } from "../../../../components/User/UserProfileWidget/UserProfileWidget";
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
} from "../../../../constants/ActionTypes";

const Header = (props) => {
  const dispatch = useDispatch();
  const { drawerType } = useSelector(({ settings }) => settings) || {};

  const onToggleCollapsedNav = () => {
    const value = !props.navCollapsed;
    dispatch(toggleCollapsedNav(value));
  };

  const drawerStyle = drawerType?.includes(FIXED_DRAWER)
    ? "d-block d-xl-none"
    : drawerType?.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";

  return (
    <AppBar className="app-main-header" position="sticky" elevation={0}>
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon me-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>
        <UserProfileWidget />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  navCollapsed: PropTypes.bool,
};

export { Header };
