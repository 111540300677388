import { Avatar, Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  bulkAssignEnrolments,
  loadEnrolments,
} from "../../../actions/Beneficiaries";
import {
  loadOrganizations,
  loadReportedCountries,
} from "../../../actions/Common";
import {
  setFiltersList,
  toggleAssigned,
  toggleAttention,
} from "../../../actions/Enrolment";
import { loadUsersData } from "../../../actions/User";
import {
  BULK_ASSIGN_ENROLMENTS_FAIL,
  BULK_ASSIGN_ENROLMENTS_SUCCESS,
  CLEAR_ENROLMENTS,
  CLEAR_USERS_DATA,
} from "../../../constants/ActionTypes";
import { ROUTES, absolutePath } from "../../../constants/Routes";
import { ROLES } from "../../../constants/User";
import {
  ENROLMENT_WORKFLOW_STATUS,
  ENROLMENT_WORKFLOW_STATUS_DISPLAY,
} from "../../../constants/common";
import { INIT_STATE } from "../../../reducers/Enrolment";
import {
  enrolmentsCountSelector,
  enrolmentsLoadingSelector,
  enrolmentsSelector,
  filtersListSelector,
  loadingOrganizationsSelector,
  loadingReportedCountriesSelector,
  operatorsListForSelectSelector,
  organizationsSelector,
  reportedCountriesSelector,
  toggleAssignedSelector,
  toggleAttentionSelector,
  usersLoadingSelector,
} from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { getFormattedDate } from "../../../util/dates";
import { getAgent, getAppointmentInfo } from "../../../util/enrolment";
import { solutionYears } from "../../../util/solutionYears";
import { getFullName } from "../../../util/user";
import { DataTable } from "../../generic/DataTable/DataTable";
import { Dropdown } from "../../generic/Dropdown";
import { FiltersSidebar } from "../../generic/FilterSidebar/FilterSidebar";
import { FiltersHeader } from "../../generic/FiltersHeader/FiltersHeader";
import { LinkButton } from "../../generic/LinkButton";
import { NotAvailable } from "../../generic/NotAvailable/NotAvailable";
import { Widget } from "../../generic/Widget/Widget";
import { EnrolmentSupportStatus } from "../EnrolmentSupportStatus";
import { EnrolmentSupportStatusDot } from "../EnrolmentSupportStatus/EnrolmentSupportStatusDot";

const useStyles = makeStyles({
  bulkAssignContainer: {
    display: "flex",
    alignItems: "center",
  },
  bulkAssignItem: {
    marginRight: "20px",
  },
  startBulkAssign: {
    fontWeight: "590",
    textDecoration: "underline",
  },
  cancelBulkAssign: {
    color: "#C62222 !important",
    fontWeight: "590",
    textDecoration: "underline",
  },
  dropdown: {
    width: "230px",
  },
  body2: {
    marginTop: "2px",
    color: "#565656",
  },
  applyButton: {
    textTransform: "none",
    marginLeft: "10px",
    marginRight: "20px",
  },
  checkbox: {
    marginRight: "35px",
  },
});

const BULK_ASSIGN_STATUS = {
  BEFORE_START: 0,
  WAITING_ENROLMENTS: 1,
  SELECT_AGENT: 2,
  AGENT_SELECTED: 3,
  APPLYING_BULK: 4,
};

const EnrolmentsList = ({
  beneficiary,
  defaultPagination,
  tableProps,
  showOnlyTable = false,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const intl = useIntl();
  const enrolments = useSelector(enrolmentsSelector);
  const enrolmentsFiltersList = useSelector(filtersListSelector);
  const enrolmentToggleAttention = useSelector(toggleAttentionSelector);
  const enrolmentToggleAssigned = useSelector(toggleAssignedSelector);
  const count = useSelector(enrolmentsCountSelector);
  const loading = useSelector(enrolmentsLoadingSelector);
  const operators = useSelector(operatorsListForSelectSelector);
  const loadingOperators = useSelector(usersLoadingSelector);
  const organizations = useSelector(organizationsSelector);
  const loadingOrganizations = useSelector(loadingOrganizationsSelector);
  const reportedCountries = useSelector(reportedCountriesSelector);
  const loadingReportedCountries = useSelector(
    loadingReportedCountriesSelector
  );
  const [enrolmentsList, setEnrolmentsList] = useState([]);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);
  const [bulkAssignStatus, setBulkAssignStatus] = useState(
    BULK_ASSIGN_STATUS.BEFORE_START
  );
  const [bulkAssignAgent, setBulkAssignAgent] = useState(null);

  defaultPagination = defaultPagination || {
    ordering: "-created",
    page: 1,
    size: 10,
  };

  const openEnrolment = (row) => {
    if (bulkAssignStatus === BULK_ASSIGN_STATUS.BEFORE_START) {
      navigate(absolutePath(`${ROUTES.ENROLMENT}/${row.id}`));
    }
  };

  // #region Load enrolments
  const fetchData = () => {
    if (enrolmentsFiltersList && !loading) {
      dispatch(
        loadEnrolments({
          ...defaultPagination,
          ...enrolmentsFiltersList,
          userId: beneficiary?.id,
        })
      );
    }
  };

  useEffect(() => fetchData(), [enrolmentsFiltersList]);
  // #endregion

  const onToggleRequiresAttention = (checked) => {
    let { workflow_status } = enrolmentsFiltersList;
    if (checked) {
      if (!workflow_status) {
        workflow_status = [
          ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString(),
        ];
      } else if (
        !workflow_status.includes(
          ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString()
        )
      ) {
        workflow_status.push(
          ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString()
        );
      }
    } else {
      const index = workflow_status.indexOf(
        ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString()
      );
      if (index !== -1) {
        workflow_status.length > 1
          ? workflow_status.splice(index, 1)
          : (workflow_status = undefined);
      }
    }

    dispatch(
      toggleAttention(
        checked ? ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION : undefined
      )
    );
    dispatch(
      setFiltersList({ ...enrolmentsFiltersList, page: 1, workflow_status })
    );
  };

  const onToggleAssignedMe = (checked) => {
    dispatch(toggleAssigned(checked));
  };

  // #region Load enrolments list
  useEffect(() => setEnrolmentsList(enrolments), [enrolments]);
  // #endregion

  // #region Load common data
  const fetchOperatorUsersData = () => {
    if (!operators && !loadingOperators) {
      const fetchUsersParams = {
        groups__name__in: [ROLES.OPERATOR, ROLES.TASK_FORCE].join(","),
        size: 1000,
      };
      dispatch(loadUsersData({ ...fetchUsersParams }));
    }
  };

  const fetchCommonData = () => {
    if (!organizations && !loadingOrganizations) {
      dispatch(loadOrganizations());
    }
    if (!reportedCountries && !loadingReportedCountries) {
      dispatch(loadReportedCountries());
    }
  };

  useEffect(() => {
    fetchOperatorUsersData();
    fetchCommonData();
  }, []);
  // #endregion

  // #region Clear data on leave
  useEffect(() => clear, []);

  const clear = () => {
    dispatch({ type: CLEAR_ENROLMENTS });
    dispatch({ type: CLEAR_USERS_DATA });
  };
  // #endregion

  const columnData = [
    {
      id: "name",
      align: "left",
      label: "common.beneficiary",
      sortable: true,
      sort_field: "user__first_name",
      handler: (row) => {
        const fullName = getFullName(row?.user);
        return (
          <div className="user-profile d-flex flex-row align-items-center">
            {bulkAssignStatus !== BULK_ASSIGN_STATUS.BEFORE_START && (
              <Checkbox
                color="primary"
                disabled={
                  !(
                    row.workflow_status_unjspf ===
                      ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION ||
                    (row.workflow_status_unjspf ===
                      ENROLMENT_WORKFLOW_STATUS.AGENT_ASSIGNED &&
                      !row.appointment?.id)
                  )
                }
                className={classes.checkbox}
                checked={!!row.selected}
                onChange={(event) => enrolmentSelected(event, row)}
              />
            )}
            <Avatar className="user-avatar" />
            <div className="user-detail">
              <h5 className="user-name">{fullName}</h5>
              <p className="user-description">
                {row.user?.organization?.acronym?.toLocaleUpperCase()}{" "}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      id: "created",
      align: "left",
      label: "common.requested",
      sortable: true,
      handler: (row) => getFormattedDate(row.created, "MMM D YYYY, h:mm:ss a"),
    },
    {
      id: "unjspf_uid",
      label: "common.id",
      align: "left",
      sortable: false,
      handler: (row) => row?.user?.unjspf_uuid,
    },
    {
      id: "timezone",
      label: "common.timezone",
      align: "left",
      sortable: false,
      handler: (row) => row?.user?.profile?.timezone,
    },
    {
      id: "on_hold_until",
      align: "left",
      label: "common.onHoldUntil",
      sortable: true,
      sort_field: "user__on_hold_until",
      hide: enrolmentsFiltersList.on_hold !== "true",
      handler: (row) =>
        row.user.on_hold
          ? getFormattedDate(row.user.on_hold_until, "MMM D YYYY")
          : "-",
    },
    {
      id: "status",
      align: "left",
      label: "common.status",
      sortable: true,
      handler: (row) => {
        return (
          <div className="enrolment-workflow-status">
            <EnrolmentSupportStatus status={row?.workflow_status_unjspf} />
          </div>
        );
      },
    },
    {
      id: "appointment_datetime",
      align: "left",
      label: "common.appointment",
      sortable: true,
      handler: (row) => {
        const appointmentInfo = getAppointmentInfo(row?.appointment);
        return appointmentInfo || <NotAvailable />;
      },
    },
    {
      id: "assigned_agent",
      align: "left",
      label: "common.agent",
      sortable: true,
      handler: (row) => {
        return getAgent(row) || <NotAvailable />;
      },
    },
  ];

  const yearFilteringOptions = solutionYears().map((year) => ({
    titleTranslated: year.toString(),
    value: year,
  }));

  const yearDCEFilteringOptions = solutionYears()
    .map((year) => [
      {
        label: "common.issuedOn",
        valid: true,
        year,
      },
      {
        label: "common.notIssuedOn",
        valid: false,
        year,
      },
    ])
    .flat()
    .map((item) => ({
      titleTranslated: intl.formatMessage(
        { id: item.label },
        { year: item.year }
      ),
      value: {
        valid: item.valid,
        year: item.year,
      },
      groupBy: item.year,
    }));

  const statusFiltersOptions = Object.values(ENROLMENT_WORKFLOW_STATUS).map(
    (status) => ({
      title: ENROLMENT_WORKFLOW_STATUS_DISPLAY[status],
      rendered: <EnrolmentSupportStatusDot status={status} />,
      value: status,
    })
  );

  const filters = [
    {
      id: "organization_id",
      title: "common.organization",
      isAutocomplete: true,
      options: organizations?.map((organization) => {
        return {
          title: `${organization.acronym} - ${organization.name}`,
          value: organization.id,
          label: organization.acronym,
        };
      }),
    },
    {
      id: "reported_country",
      title: "common.reportedCountry",
      isAutocomplete: true,
      options: reportedCountries?.map((country) => {
        const countryName = country
          .toLowerCase()
          .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
        return {
          title: countryName,
          value: country,
          label: countryName,
        };
      }),
    },
    {
      id: "year",
      title: "common.year",
      isRadioButton: true,
      options: [...yearFilteringOptions],
    },
    {
      id: "workflow_status",
      title: "common.status",
      isCheckboxArray: true,
      options: [...statusFiltersOptions],
    },
    {
      id: "on_hold",
      title: "common.onHold",
      isRadioButton: true,
    },
    {
      id: "has_model",
      title: "common.biometricModel",
      isRadioButton: true,
    },
    {
      id: "has_appointment",
      title: "common.hasAppointment",
      isRadioButton: true,
    },
    {
      id: "allowed_to_enroll",
      title: "common.allowedToEnrol",
      isRadioButton: true,
    },
    {
      id: "can_submit_DCE",
      title: "common.enabledForDce",
      isRadioButton: true,
    },
    {
      id: "opt_out",
      title: "common.optedOut",
      isRadioButton: true,
    },
    {
      id: "communication_exhausted",
      title: "common.commExhausted",
      isRadioButton: true,
    },
    {
      id: "invitation_redeemed",
      title: "common.invitationRedeemed",
      isRadioButton: true,
    },
    {
      id: "valid_coe_year",
      title: "common.validDceOnExercise",
      valueIsObject: true,
      isRadioButton: true,
      options: [...yearDCEFilteringOptions],
    },
  ];

  // #region Bulk assign
  const enrolmentSelected = (event, enrolment) => {
    event.stopPropagation();
    const enrolmentSelect = enrolments.find((e) => e.id === enrolment.id);

    if (enrolmentSelect) {
      enrolmentSelect.selected = event.target.checked;
      setEnrolmentsList([...enrolments]);
    }

    if (!enrolments.filter((e) => e.selected).length) {
      setBulkAssignStatus(BULK_ASSIGN_STATUS.WAITING_ENROLMENTS);
    } else setAgentSelected();
  };

  const changeBulkAssignAgent = (agentId) => {
    setBulkAssignAgent(agentId);
    setAgentSelected(agentId);
  };

  const setAgentSelected = (agentId = bulkAssignAgent) => {
    setBulkAssignStatus(
      agentId
        ? BULK_ASSIGN_STATUS.AGENT_SELECTED
        : BULK_ASSIGN_STATUS.SELECT_AGENT
    );
  };

  const startBulkAssign = (event) => {
    event.preventDefault();
    setBulkAssignStatus(BULK_ASSIGN_STATUS.WAITING_ENROLMENTS);
  };

  const bulkAssign = async () => {
    const data = {
      on_boarding_ids: enrolments.filter((e) => e.selected).map((e) => e.id),
      assigned_agent: bulkAssignAgent,
    };

    const result = await dispatch(bulkAssignEnrolments(data));
    if (result.type === BULK_ASSIGN_ENROLMENTS_SUCCESS) {
      NotificationManager.success(
        <IntlMessages id="pages.enrolments.bulkAssign.success" />
      );
      cancelBulkAssign();
      fetchData();
    } else if (result.type === BULK_ASSIGN_ENROLMENTS_FAIL) {
      NotificationManager.error(
        <IntlMessages id="pages.enrolments.bulkAssign.error" />
      );
    }
  };

  const cancelBulkAssign = (event) => {
    event && event.preventDefault();
    enrolments.map((e) => {
      e.selected = false;
      return e;
    });
    setBulkAssignStatus(BULK_ASSIGN_STATUS.BEFORE_START);
    setBulkAssignAgent(null);
  };

  const inlineActions = () => {
    return (
      <Grid container item xs={12} md className={classes.bulkAssignContainer}>
        {renderBulkAssignComponents()}
        {bulkAssignStatus !== BULK_ASSIGN_STATUS.BEFORE_START && (
          <LinkButton
            label="pages.enrolments.bulkAssign.cancel"
            click={cancelBulkAssign}
            className={classes.cancelBulkAssign}
          />
        )}
      </Grid>
    );
  };

  const renderBulkAssignComponents = () => {
    switch (bulkAssignStatus) {
      case BULK_ASSIGN_STATUS.BEFORE_START:
        return (
          <LinkButton
            label="pages.enrolments.bulkAssign.start"
            click={startBulkAssign}
            className={classes.startBulkAssign}
          />
        );
      case BULK_ASSIGN_STATUS.WAITING_ENROLMENTS:
        return (
          <Typography variant="body2" className={classes.bulkAssignItem}>
            <IntlMessages id="pages.enrolments.bulkAssign.checkEnrolmentsElegible" />
          </Typography>
        );
      case BULK_ASSIGN_STATUS.SELECT_AGENT:
      case BULK_ASSIGN_STATUS.AGENT_SELECTED:
        return (
          <>
            <Dropdown
              label="pages.enrolments.bulkAssign.assignTo"
              value={bulkAssignAgent}
              options={operators}
              placeholder={<IntlMessages id="common.selectAgent" />}
              valueProp="id"
              labelProp="fullName"
              customOption={renderAgentSelectOption}
              onChange={changeBulkAssignAgent}
              className={classes.dropdown}
              hbClassName={`${
                bulkAssignStatus !== BULK_ASSIGN_STATUS.AGENT_SELECTED &&
                classes.bulkAssignItem
              }`}
              maxListHeight="300px"
              labelBefore
              small
            />
            {bulkAssignStatus === BULK_ASSIGN_STATUS.AGENT_SELECTED && (
              <Button
                variant="contained"
                color="primary"
                onClick={bulkAssign}
                className={classes.applyButton}
                disableElevation
              >
                <IntlMessages id="pages.enrolments.bulkAssign.apply" />
              </Button>
            )}
          </>
        );
    }
  };

  const renderAgentSelectOption = (option) => {
    return (
      <Box>
        <Typography>{option.fullName}</Typography>
        <Typography variant="body2" className={classes.body2}>
          {option.timezone}
        </Typography>
      </Box>
    );
  };
  // #endregion

  const onClearFilters = (value) => {
    setClearFilters(value);
    dispatch(setFiltersList(INIT_STATE.enrolmentFiltersList));
    dispatch(toggleAttention(undefined));
    dispatch(toggleAssigned(undefined));
  };

  const onApplyFilters = (checkedList) => {
    const { workflow_status } = checkedList;

    dispatch(setFiltersList({ ...checkedList, page: 1 }));
    dispatch(
      toggleAttention(
        workflow_status &&
          workflow_status.includes(
            ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION.toString()
          )
          ? ENROLMENT_WORKFLOW_STATUS.REQUIRES_ATTENTION
          : undefined
      )
    );
  };

  const renderFilters = (
    <>
      <FiltersHeader
        onToggleDrawer={setToggleDrawer}
        toggleFirstSwitch={onToggleRequiresAttention}
        toggleSecondSwitch={onToggleAssignedMe}
        filters={filters}
        selectedFilters={enrolmentsFiltersList}
        checkedList={enrolmentsFiltersList}
        setFiltersList={setFiltersList}
        firstSwitchChecked={!!enrolmentToggleAttention?.workflow_status}
        secondSwitchChecked={!!enrolmentToggleAssigned?.assign_me}
      />
      <FiltersSidebar
        toggleDrawer={setToggleDrawer}
        opened={toggleDrawer}
        filters={filters}
        initialList={enrolmentsFiltersList}
        activateFilters
        clearFilters={clearFilters}
        onDelete={onClearFilters}
        onSubmit={onApplyFilters}
      />
    </>
  );

  const renderTable = (
    <DataTable
      id="enrolments"
      columnData={columnData}
      tableData={enrolmentsList}
      initialPagination={defaultPagination}
      setFiltersList={(filters) => dispatch(setFiltersList(filters))}
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      searchable
      noSearchResultsText={<IntlMessages id="enrolment.noEnrolments" />}
      rowClickAction={openEnrolment}
      selectedFilters={enrolmentsFiltersList}
      slideOne={enrolmentToggleAttention}
      slideOneIncludedInFilters
      slideTwo={enrolmentToggleAssigned}
      count={count}
      inlineActions={inlineActions}
      showOnlyTable={showOnlyTable}
      loading={loading}
      {...tableProps}
    />
  );

  return showOnlyTable ? (
    <Box>{renderTable}</Box>
  ) : (
    <Widget>
      {renderFilters}
      {renderTable}
    </Widget>
  );
};

export { EnrolmentsList };
