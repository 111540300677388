import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import IntlMessages from "../../../../../util/IntlMessages";
import styles from "./EnrolmentInformation.module.scss";

const EnrolmentInformation = ({
  phoneValue,
  notesValue,
  setPhoneValue,
  setNoteValue,
  label,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className={styles.title}>
        <IntlMessages id={label} />
      </div>
      {phoneValue ? (
        <TextField
          defaultValue={phoneValue}
          label=""
          variant="outlined"
          color="secondary"
          className={styles.input}
          onChange={(event) => setPhoneValue(event.target.value)}
        />
      ) : (
        <TextField
          multiline
          minRows={10}
          placeholder={formatMessage({ id: "common.notAvailable" })}
          defaultValue={notesValue}
          label=""
          variant="outlined"
          color="secondary"
          className={styles.textarea}
          onChange={(event) => setNoteValue(event.target.value)}
        />
      )}
    </>
  );
};

EnrolmentInformation.propTypes = {
  phoneValue: PropTypes.string,
  notesValue: PropTypes.string,
  setPhoneValue: PropTypes.func,
  setNoteValue: PropTypes.func,
  label: PropTypes.string,
};

export { EnrolmentInformation };
