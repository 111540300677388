import { Link } from "@mui/material";
import { cloneElement } from "react";
import { useNavigate } from "react-router-dom";
import { absolutePath } from "../../../constants/Routes";
import IntlMessages from "../../../util/IntlMessages";

const MenuItem = ({ menuItem }) => {
  const navigate = useNavigate();
  const { route, icon, title, hide, openBlank, absoluteRoute } = menuItem;
  const absRoute = absoluteRoute ?? absolutePath(route);

  const goToRoute = (event) => {
    event.preventDefault();
    navigate(absRoute);
  };

  const renderChild = () => {
    return (
      <>
        {cloneElement(icon, {
          color: "inherit",
          className: "me-2",
          fontSize: "small",
        })}
        <span className="nav-text">
          <IntlMessages id={title} />
        </span>
      </>
    );
  };

  return hide ? (
    <></>
  ) : (
    <li className={`menu no-arrow ${openBlank ? "open-blank" : ""}`}>
      {openBlank ? (
        <a target="_blank" href={route} rel="noreferrer">
          {renderChild()}
        </a>
      ) : (
        <Link href={absRoute} onClick={goToRoute}>
          {renderChild()}
        </Link>
      )}
    </li>
  );
};

export { MenuItem };
