import {
  HIDE_MESSAGE,
  ON_SHOW_LOADER,
  RECOVER_BENEFICIARY_SECURITY_CODE,
  RECOVER_PASSWORD,
  SET_PASSWORD,
  SIGNIN_USER,
  SIGNOUT_USER,
  TOKEN_REFRESH,
} from "../constants/ActionTypes";
import { CSRF_TOKEN_COOKIE_NAME } from "../constants/common";
import csrfHeader from "../util/csrfHeader";
import getCookie from "../util/getCookie";

export const userSignIn = ({ email, password }) => ({
  type: SIGNIN_USER,
  payload: {
    request: {
      url: "auth/token",
      method: "POST",
      data: {
        username: email,
        password,
      },
      headers: {
        ...csrfHeader(getCookie(CSRF_TOKEN_COOKIE_NAME)),
      },
    },
  },
});

export const userTokenRefresh = ({ refreshToken }) => ({
  type: TOKEN_REFRESH,
  payload: {
    request: {
      url: "auth/token/refresh",
      method: "POST",
      data: {
        refresh: refreshToken,
      },
      headers: {
        ...csrfHeader(getCookie(CSRF_TOKEN_COOKIE_NAME)),
      },
    },
  },
});

export const userRecoverPassword = ({ username }) => ({
  type: RECOVER_PASSWORD,
  payload: {
    request: {
      url: "auth/recover_password",
      method: "POST",
      data: {
        username,
      },
      headers: {
        ...csrfHeader(getCookie(CSRF_TOKEN_COOKIE_NAME)),
      },
    },
  },
});

export const recoverBeneficiarySecurityCode = ({ unjspfUuid, email }) => ({
  type: RECOVER_BENEFICIARY_SECURITY_CODE,
  payload: {
    request: {
      url: "/unjspfadmin/user/recover_password",
      method: "POST",
      data: {
        email,
        unjspf_uuid: unjspfUuid,
      },
      headers: {
        ...csrfHeader(getCookie(CSRF_TOKEN_COOKIE_NAME)),
      },
    },
  },
});

export const userSetPassword = ({ code, password }) => ({
  type: SET_PASSWORD,
  payload: {
    request: {
      url: "auth/set_password",
      method: "POST",
      data: {
        code,
        new_password: password,
      },
      headers: {
        ...csrfHeader(getCookie(CSRF_TOKEN_COOKIE_NAME)),
      },
    },
  },
});

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
