import React from "react";
import { StatusDot } from "../../generic/Status/StatusDot";

const EnrolmentSupportStatusDot = ({
  status,
  showLabel = false,
  labelClass,
}) => (
  <StatusDot status={status} showLabel={showLabel} labelClass={labelClass} />
);

export { EnrolmentSupportStatusDot };
