export const ROLES = {
  BENEFICIARY: "retiree",
  UNJSPF_ADMIN: "unjspf_admin",
  OPERATOR: "operator",
  TASK_FORCE: "task_force",
  HELPDESK_MANAGER: "helpdesk_manager",
  SPC: "spc",
  READ_ONLY: "read_only",
};

export const WEB_USER_ROLES = [
  ROLES.UNJSPF_ADMIN,
  ROLES.SPC,
  ROLES.HELPDESK_MANAGER,
  ROLES.OPERATOR,
  ROLES.TASK_FORCE,
  ROLES.READ_ONLY,
];

export const USER_FIELDS = {
  ORGANIZATION: "organization",
  UNJSPF_ID: "unjspf_id",
  DATE_OF_BIRTH: "date_of_birth",
  DATE_JOINED: "date_joined",
  INVITATION_REDEEMED: "invitation_redemed",
  GENDER: "gender",
  ENABLED_ENROLL: "allowed_to_enroll",
  ENABLED_DCE: "enabled_dce",
  MSS: "mss",
  DECEASED: "deceased",
  DISABILITY: "disability",
  PARTICIPATION_STATUS: "participation_status",
  REASON: "reason",
  OPT_OUT: "opt_out",
  OPT_OUT_DATE: "opt_out_date",
  OPT_OUT_NOTE: "opt_out_note",
  RECORD_ID: "record_id",
  HAS_MODEL: "has_model",
  COMMUNICATION_EXHAUSTED: "communication_exhausted",
  COMMUNICATION_EXHAUSTED_DATE: "communication_exhausted_date",
  COMMUNICATION_EXHAUSTED_NOTE: "communication_exhausted_note",
  EMAIL: "email",
  LEGACY_EMAIL: "legacy_email",
  PHONE_NUMBER: "phone_number",
  RETIREMENT_DATE: "retirement_date",
  ID: "id",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  MIDDLE_NAME: "middle_name",
  VALID_COES: "valid_coes",
  ON_BOARDING: "on_boarding",
  USERNAME: "username",
  GROUPS: "groups",
  GUARDIAN_UID: "guardian_uid",
  GUARDIAN_SORT_NAME: "guardian_sort_name",
  LANGUAGE_CODE: "language_code",
  TIMEZONE: "timezone",
  REPORTED_COUNTRY: "reported_country",
};

export const USER_PROFILE_FIELDS = {
  INFO: [
    USER_FIELDS.UNJSPF_ID,
    USER_FIELDS.ORGANIZATION,
    USER_FIELDS.DATE_OF_BIRTH,
    USER_FIELDS.DATE_JOINED,
    USER_FIELDS.GENDER,
    USER_FIELDS.INVITATION_REDEEMED,
  ],
  DCE: [
    USER_FIELDS.ENABLED_ENROLL,
    USER_FIELDS.ENABLED_DCE,
    USER_FIELDS.MSS,
    USER_FIELDS.DECEASED,
    USER_FIELDS.DISABILITY,
    USER_FIELDS.PARTICIPATION_STATUS,
    USER_FIELDS.REASON,
    USER_FIELDS.OPT_OUT,
    USER_FIELDS.OPT_OUT_DATE,
    USER_FIELDS.OPT_OUT_NOTE,
    USER_FIELDS.RECORD_ID,
    USER_FIELDS.HAS_MODEL,
  ],
  CONTACT: [
    USER_FIELDS.EMAIL,
    USER_FIELDS.LEGACY_EMAIL,
    USER_FIELDS.PHONE_NUMBER,
    USER_FIELDS.LANGUAGE_CODE,
    USER_FIELDS.TIMEZONE,
    USER_FIELDS.REPORTED_COUNTRY,
    USER_FIELDS.COMMUNICATION_EXHAUSTED,
    USER_FIELDS.COMMUNICATION_EXHAUSTED_DATE,
    USER_FIELDS.COMMUNICATION_EXHAUSTED_NOTE,
  ],
};

export const NOTIFICATION_PREFERENCES = [
  { id: 0, label: "common.notificationPreferences.any" },
  { id: 1, label: "common.notificationPreferences.instant" },
  { id: 2, label: "common.notificationPreferences.hourly" },
  { id: 3, label: "common.notificationPreferences.daily" },
];
