import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import IntlMessages from "../../../../../util/IntlMessages";
import styles from "./FiltersContainer.module.scss";

const FiltersContainer = ({ title, toggleDrawer, children }) => {
  return (
    <>
      <Box className={styles.filtersContainer}>
        <Typography variant="h5" className={styles.filterTitle}>
          <IntlMessages id={title} />
          <Close
            className={styles.closeContainer}
            onClick={() => toggleDrawer(false)}
          />
        </Typography>
        <div className={styles.container}>{children}</div>
      </Box>
    </>
  );
};

FiltersContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
};

export { FiltersContainer };
