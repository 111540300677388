import { VideocamOutlined } from "@mui/icons-material";
import { Avatar, Button, Chip, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntl } from "react-intl";
import { getFormattedDate } from "../../../../util/dates";
import IntlMessages from "../../../../util/IntlMessages";
import { getFullName } from "../../../../util/user";

const useStyles = makeStyles(() => ({
  container: {
    padding: "30px",
  },
  image: {
    width: "107px",
    height: "unset",
    aspectRatio: "1/1",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    overflowWrap: "anywhere",
    paddingLeft: "20px",
  },
  fullName: {
    color: "#202020",
    fontWeight: "700",
  },
  userName: {
    color: "#7E7E7E",
  },
  onHoldUntil: {
    backgroundColor: "#7E7E7E",
    borderRadius: "100px",
    color: "#FFF",
    height: "unset",
    marginTop: "24px",
  },
  onHoldUntilLabel: {
    padding: "8px 16px",
  },
  videoCallButton: {
    marginTop: "15px",
    padding: "12px 24px",
    textTransform: "none",
  },
}));

const ProfileHeader = ({
  beneficiary,
  enrolment,
  call,
  loadingVideoCallToken,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const { id, username } = beneficiary;
  const fullName = getFullName(beneficiary, false);
  const userName = username || "-";
  const image = enrolment?.recent_picture;
  const onHoldLabel = beneficiary?.on_hold_until
    ? intl.formatMessage(
        { id: "pages.beneficiaryDetail.onHoldUntil" },
        { date: getFormattedDate(beneficiary.on_hold_until, "MMMM Do, YYYY") }
      )
    : "";

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Avatar src={image} className={classes.image} />
      </Grid>
      <Grid item xs className={classes.infoContainer}>
        <Typography variant="h5" className={classes.fullName}>
          {fullName}
        </Typography>
        <Typography className={classes.userName}>{userName}</Typography>
        {beneficiary?.on_hold && (
          <Chip
            label={onHoldLabel}
            className={classes.onHoldUntil}
            classes={{ label: classes.onHoldUntilLabel }}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.videoCallButton}
          onClick={() => call(id)}
          startIcon={<VideocamOutlined />}
          disabled={loadingVideoCallToken}
          disableElevation
          disableRipple
        >
          <IntlMessages id="pages.beneficiaryDetail.startVideoCall" />
        </Button>
      </Grid>
    </Grid>
  );
};

export { ProfileHeader };
