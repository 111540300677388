import { Close } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IntlMessages from "../../../util/IntlMessages";

const useStyles = makeStyles(() => ({
  container: {
    overflowY: "auto",
  },
  headerContainer: {
    padding: "40px 30px 0",
    marginBottom: "25px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
  },
  closeButton: {
    marginLeft: "auto",
    padding: "0",
  },
  closeIcon: {
    fontSize: "1.25rem",
  },
  contentContainer: {
    padding: "0 30px 0",
  },
  actionsContainer: {
    width: "100%",
    padding: "20px 30px",
    boxShadow: "0px -5px 30px rgba(0, 0, 0, 0.1)",
  },
  actionButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelAction: {
    color: "#C62222",
    padding: "12px 16px",
    fontSize: "16px",
    textTransform: "none",
  },
  saveAction: {
    padding: "12px 16px",
    fontSize: "16px",
    textTransform: "none",
  },
}));

const SideBar = ({
  title,
  toggleDrawer,
  children,
  hideActions,
  cancelLabel,
  cancelFn,
  cancelIcon,
  cancelDisabled,
  saveLabel,
  saveFn,
  saveIcon,
  saveDisabled,
  saveVariant,
  hideSave,
  saveProps,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={classes.container}
        style={{ height: hideActions ? "100vh" : "calc(100vh - 92px)" }}
      >
        {/* Header */}
        <Box className={classes.headerContainer}>
          <Typography variant="h5" className={classes.title}>
            <IntlMessages id={title} />
            <IconButton
              color="primary"
              aria-label="close"
              className={classes.closeButton}
              onClick={() => toggleDrawer(false)}
            >
              <Close className={classes.closeIcon} />
            </IconButton>
          </Typography>
        </Box>
        {/* Content */}
        <Box className={classes.contentContainer}>{children}</Box>
      </Box>
      {/* Actions */}
      {!hideActions && (
        <Box className={classes.actionsContainer}>
          <Grid container spacing={3}>
            <Grid item xs={hideSave ? 12 : 4} className={classes.actionButton}>
              <Button
                className={classes.cancelAction}
                onClick={cancelFn}
                startIcon={cancelIcon}
                disabled={cancelDisabled}
                fullWidth
              >
                <IntlMessages id={cancelLabel ?? "common.cancel"} />
              </Button>
            </Grid>
            {!hideSave && (
              <Grid item xs className={classes.actionButton}>
                <Button
                  variant={saveVariant ?? "contained"}
                  color="primary"
                  className={classes.saveAction}
                  onClick={saveFn}
                  startIcon={saveIcon}
                  disabled={saveDisabled}
                  disableElevation
                  fullWidth
                  {...saveProps}
                >
                  <IntlMessages id={saveLabel ?? "common.save"} />
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export { SideBar };
