import {
  FIXED_DRAWER,
  INSIDE_THE_HEADER,
  LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
} from "../constants/ActionTypes";
import { UNJSPF } from "../constants/ThemeColors";
import {
  DEFAULT_LANGUAGE,
  SUPPORTED_LANGUAGES,
} from "../lngProvider/constants";

export const getLanguage = (userLanguage) => {
  let initialLanguage = DEFAULT_LANGUAGE;
  const browserLanguage = navigator.language.split("-")[0];

  const defaultLanguage =
    SUPPORTED_LANGUAGES.find((lng) => lng === browserLanguage) ??
    DEFAULT_LANGUAGE;

  if (userLanguage) {
    initialLanguage =
      SUPPORTED_LANGUAGES.find((lng) => lng === userLanguage) ??
      defaultLanguage;
  } else {
    initialLanguage = defaultLanguage;
  }

  return initialLanguage;
};

const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  themeColor: UNJSPF,
  language: getLanguage(),
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false,
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed,
      };
    case LANGUAGE:
      return {
        ...state,
        language: action.newLanguage,
      };
    default:
      return state;
  }
};

export default settings;
