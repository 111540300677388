import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withRouter } from "../../../util/withRouter";
import { loadEnrolmentDetailV2 } from "../../../actions/Beneficiaries";
import { CLEAR_ENROLMENT_DETAIL } from "../../../constants/ActionTypes";
import {
  enrolmentDetailSelector,
  enrolmentsLoadingSelector,
} from "../../../reducers/selectors";
import { EnrolmentDetailContent } from "./EnrolmentDetailContent/EnrolmentDetailContent";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const EnrolmentDetail = withRouter(() => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const enrolment = useSelector(enrolmentDetailSelector);
  const loading = useSelector(enrolmentsLoadingSelector);

  useEffect(() => {
    if (!loading) {
      dispatch(loadEnrolmentDetailV2({ id }));
    }

    // Clean-up
    return () => {
      dispatch({ type: CLEAR_ENROLMENT_DETAIL });
    };
  }, []);

  const reload = () => {
    !loading && dispatch(loadEnrolmentDetailV2({ id }));
  };

  return (
    <div className="flex-row">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && <EnrolmentDetailContent {...{ enrolment, reload }} />}
    </div>
  );
});

export { EnrolmentDetail };
