import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Forbidden } from "../../components/generic/Error/Forbidden";
import { NotFound } from "../../components/generic/Error/NotFound";
import { Logo } from "../../components/generic/Logo";
import { ROUTES } from "../../constants/Routes";
import { languageSelector } from "../../reducers/selectors";
import { ORG_SHORT_NAME } from "../../util/appInfo";

const GenericLayout = () => {
  const language = useSelector(languageSelector);

  return (
    <Box className="app-login-container d-flex justify-content-center align-items-center">
      <div className="d-flex align-items-center justify-content-center">
        <div className="logo-lg w-75" title={ORG_SHORT_NAME}>
          <Logo
            id="unjspf_logo"
            language={language}
            useSquare
            className="img-fluid mb-3"
          />

          <div className="d-flex align-items-center">
            <Routes>
              <Route path={ROUTES.FORBIDDEN} element={<Forbidden />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </Box>
  );
};

export { GenericLayout };
