import { ENROLMENT_WORKFLOW_STATUS_DISPLAY } from "../../../constants/common";
import IntlMessages from "../../../util/IntlMessages";
import { getColor } from "../../Enrolment/EnrolmentSupportStatus";

const StatusPill = ({
  status,
  statusValues = ENROLMENT_WORKFLOW_STATUS_DISPLAY,
  colorClass,
}) => {
  return colorClass ? (
    <span
      className={`badge m-0 bg-${colorClass} ${colorClass === "light" ? "text-dark" : ""}`}
    >
      <IntlMessages id={statusValues[status]} />
    </span>
  ) : (
    <span
      className="badge m-0 text-white"
      style={{ backgroundColor: getColor(status) }}
    >
      <IntlMessages id={statusValues[status]} />
    </span>
  );
};

export { StatusPill };
