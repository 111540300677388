import { connectRouter } from "connected-react-router";
import * as localforage from "localforage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import Auth from "./Auth";
import Beneficiaries from "./Beneficiaries";
import Common from "./Common";
import Enrolment from "./Enrolment";
import Settings from "./Settings";
import SupportSchedule from "./SupportSchedule";
import User from "./User";
import Users from "./Users";

const userPersistConfig = {
  key: "user",
  storage: localforage,
  whitelist: ["data"],
};

const authPersistConfig = {
  key: "auth",
  storage: localforage,
  whitelist: ["userName", "userToken", "refreshToken"],
};

const reducers = () =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    user: persistReducer(userPersistConfig, User),
    auth: persistReducer(authPersistConfig, Auth),
    users: Users,
    beneficiaries: Beneficiaries,
    supportSchedule: SupportSchedule,
    common: Common,
    enrolment: Enrolment,
  });

export { reducers };
