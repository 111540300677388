import {
  CLEAR_ORGANIZATIONS,
  LOAD_ORGANIZATIONS,
  LOAD_ORGANIZATIONS_FAIL,
  LOAD_ORGANIZATIONS_SUCCESS,
  LOAD_REPORTED_COUNTRIES,
  LOAD_REPORTED_COUNTRIES_SUCCESS,
  LOAD_REPORTED_COUNTRIES_FAIL,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loadingOrganizations: false,
  loadingReportedCountries: false,
  organizations: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_ORGANIZATIONS: {
      return {
        ...state,
        organizations: null,
      };
    }
    case LOAD_ORGANIZATIONS: {
      return {
        ...state,
        loadingOrganizations: true,
      };
    }
    case LOAD_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        loadingOrganizations: false,
        organizations: action.payload?.data?.results,
      };
    }
    case LOAD_ORGANIZATIONS_FAIL: {
      return {
        ...state,
        loadingOrganizations: false,
      };
    }
    case LOAD_REPORTED_COUNTRIES: {
      return {
        ...state,
        loadingReportedCountries: true,
      };
    }
    case LOAD_REPORTED_COUNTRIES_SUCCESS: {
      return {
        ...state,
        loadingReportedCountries: false,
        reportedCountries: action.payload?.data,
      };
    }
    case LOAD_REPORTED_COUNTRIES_FAIL: {
      return {
        ...state,
        loadingReportedCountries: false,
      };
    }
    default:
      return state;
  }
};
