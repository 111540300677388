import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import cl from "classnames";
import { useState } from "react";
import { TabPanel } from "../../../generic/TabPanel";
import { Tabs } from "../../../generic/Tabs";
import { CertificatesOfEntitlement } from "../CoEs";
import { UserInvitationsList } from "../UserInvitationsList";
import { CallsHistory } from "./CallsHistory";
import { ProfileEnrolments } from "./ProfileEnrolments";
import { UserDevicesList } from "./UserDevicesList/";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    height: "100%",
    padding: "10px 30px 30px",
  },
  tabPanel: {
    height: "calc(100% - 60px)",
    overflow: "hidden auto",
    padding: "30px 0",
    "&.hasAction": {
      height: "calc(100% - 128px)",
    },
  },
}));

const ProfileContent = ({ beneficiary }) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    { id: "dce-tab", label: "pages.beneficiaryDetail.tab.dce" },
    { id: "app-codes-tab", label: "pages.beneficiaryDetail.tab.appCodes" },
    { id: "devices-tab", label: "pages.beneficiaryDetail.tab.devices" },
    { id: "enrolments-tab", label: "pages.beneficiaryDetail.tab.enrolments" },
    { id: "calls-tab", label: "pages.beneficiaryDetail.tab.calls" },
  ];

  return (
    <Box className={classes.container}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => setTabIndex(value)}
        tabs={tabs}
      />
      <TabPanel
        index={0}
        value={tabIndex}
        className={cl(classes.tabPanel, "hasAction")}
      >
        {/* DCE */}
        <CertificatesOfEntitlement {...{ beneficiary }} />
      </TabPanel>
      <TabPanel
        index={1}
        value={tabIndex}
        className={cl(classes.tabPanel, "hasAction")}
      >
        {/* App Codes */}
        <UserInvitationsList {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={2} value={tabIndex} className={classes.tabPanel}>
        {/* Devices */}
        <UserDevicesList {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={3} value={tabIndex} className={classes.tabPanel}>
        {/* Enrolments */}
        <ProfileEnrolments {...{ beneficiary }} />
      </TabPanel>
      <TabPanel index={4} value={tabIndex} className={classes.tabPanel}>
        {/* Calls */}
        <CallsHistory {...{ beneficiary }} />
      </TabPanel>
    </Box>
  );
};

export { ProfileContent };
