import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import {
  ENROL_REJECT_FACE,
  ENROL_REJECT_FACE_OPTIONS,
  ENROL_REJECT_FLOW_STEP,
  ENROL_REJECT_ID_DOC,
  ENROL_REJECT_ID_DOC_OPTIONS,
  ENROL_REJECT_REASONS,
} from "../../../../../constants/common";
import IntlMessages from "../../../../../util/IntlMessages";
import { ActionDialog } from "../../../../generic/ActionDialog";
import { RadioButton } from "../../../../generic/RadioButton";
import { StepperProgress } from "../../../../generic/StepperProgress";
import styles from "./ManageEnrolmentDialog.module.scss";

const INITIAL_REASONS = {
  [ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS]]:
    ENROL_REJECT_FACE.VALID,
  [ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS]]:
    ENROL_REJECT_ID_DOC.VALID,
  [ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.OTHER_REASONS]]: "",
};

const ManageEnrolmentDialog = ({
  manageEnrolmentFn,
  open,
  handleClose,
  status,
}) => {
  const intl = useIntl();
  const [flowStep, setFlowStep] = useState(
    ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS
  );
  const [reasons, setReasons] = useState(INITIAL_REASONS);
  const [isValidRejection, setIsValidRejection] = useState(false);

  const areReasonsValid = (currentReasons) => {
    const { face, issue_id, other } = currentReasons;

    const valid =
      face !== ENROL_REJECT_FACE.VALID ||
      issue_id !== ENROL_REJECT_ID_DOC.VALID ||
      (!!other && other !== "");
    return valid;
  };

  const handleRejectedReasonsChange = (step, value) => {
    const currentReasons = { ...reasons };
    currentReasons[step] = value;
    setReasons(currentReasons);
    setIsValidRejection(areReasonsValid(currentReasons));
  };

  // #region Renderers
  const renderContentTitle = () => {
    switch (flowStep) {
      case ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS:
        return (
          <IntlMessages id="pages.enrolmentDetail.dialog.userProfilePicStatus.title" />
        );
      case ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS:
        return (
          <IntlMessages id="pages.enrolmentDetail.dialog.idDocStatus.title" />
        );
      case ENROL_REJECT_FLOW_STEP.OTHER_REASONS:
        return (
          <IntlMessages id="pages.enrolmentDetail.dialog.otherReasons.title" />
        );
      default:
        return "You should not be here";
    }
  };

  const renderContentForm = () => {
    let step, stepValue;
    switch (flowStep) {
      case ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS:
        step = ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS];
        stepValue = reasons[step];

        return (
          <RadioButton
            value={stepValue}
            options={ENROL_REJECT_FACE_OPTIONS}
            onChange={(value) => handleRejectedReasonsChange(step, +value)}
          />
        );
      case ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS:
        step = ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.ID_DOC_STATUS];
        stepValue = reasons[step];

        return (
          <RadioButton
            value={stepValue}
            options={ENROL_REJECT_ID_DOC_OPTIONS}
            onChange={(value) => handleRejectedReasonsChange(step, +value)}
          />
        );
      case ENROL_REJECT_FLOW_STEP.OTHER_REASONS:
        step = ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.OTHER_REASONS];
        stepValue = reasons[step];

        return (
          <>
            <TextField
              id={ENROL_REJECT_REASONS[ENROL_REJECT_FLOW_STEP.OTHER_REASONS]}
              variant="outlined"
              multiline
              minRows={5}
              placeholder={intl.formatMessage({
                id: "pages.enrolmentDetail.rejectionDetails.explain",
              })}
              className={styles.otherReasons}
              onChange={(event) =>
                handleRejectedReasonsChange(step, event.target.value)
              }
              fullWidth
            />
            {!isValidRejection ? (
              <Typography
                variant="overline"
                color="textSecondary"
                className={styles.invalidReason}
              >
                <IntlMessages id="pages.enrolmentDetail.rejectionDetails.selectOne" />
              </Typography>
            ) : (
              <></>
            )}
          </>
        );
      default:
        return "You are here by mistake";
    }
  };

  const renderDialogContent = () => {
    return (
      <Box className={styles.dialogContent}>
        <StepperProgress
          currentStep={flowStep + 1}
          numberSteps={Object.keys(ENROL_REJECT_FLOW_STEP).length}
        />
        <Typography className={styles.contentTitle}>
          {renderContentTitle()}
        </Typography>
        <Box className={styles.contentForm}>{renderContentForm()}</Box>
      </Box>
    );
  };

  const renderActions = () => {
    return (
      <Box className={styles.actionsBox}>
        {flowStep !== ENROL_REJECT_FLOW_STEP.FACE_PIC_STATUS ? (
          <Button
            color="primary"
            startIcon={<ChevronLeftOutlined />}
            className={styles.actionButton}
            onClick={onClickPrevious}
            disableElevation
            disableRipple
          >
            <IntlMessages id="pages.enrolmentDetail.rejectionDetails.previous" />
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant={
            flowStep === ENROL_REJECT_FLOW_STEP.OTHER_REASONS
              ? "contained"
              : "text"
          }
          color="primary"
          endIcon={
            flowStep === ENROL_REJECT_FLOW_STEP.OTHER_REASONS ? null : (
              <ChevronRightOutlined />
            )
          }
          className={`${styles.actionButton} ${styles.nextButton}`}
          onClick={
            flowStep === ENROL_REJECT_FLOW_STEP.OTHER_REASONS
              ? onClickSubmit
              : onClickNext
          }
          disabled={
            flowStep === ENROL_REJECT_FLOW_STEP.OTHER_REASONS &&
            !isValidRejection
          }
          disableElevation
          disableRipple
        >
          <IntlMessages
            id={
              flowStep === ENROL_REJECT_FLOW_STEP.OTHER_REASONS
                ? "pages.enrolmentDetail.rejectionDetails.submit"
                : "pages.enrolmentDetail.rejectionDetails.next"
            }
          />
        </Button>
      </Box>
    );
  };
  // #endregion

  // #region Actions handlers
  const onClickPrevious = () => {
    setFlowStep(flowStep - 1);
  };

  const onClickNext = () => {
    setFlowStep(flowStep + 1);
  };

  const onClickSubmit = () => {
    manageEnrolmentFn({ status, reasons });
  };
  // #endregion

  return (
    <ActionDialog
      open={open}
      onClose={handleClose}
      title={
        <IntlMessages id="pages.enrolmentDetail.dialog.selectRejectReasonTitle" />
      }
      content={renderDialogContent()}
      actions={renderActions()}
    />
  );
};

export { ManageEnrolmentDialog };
