import { Close } from "@mui/icons-material";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";
import { getDateStr, getTimeSlotStr } from "../../../util/schedule";
import { getFullName, getReducedName } from "../../../util/user";
import { EnrolmentSupportStatusDot } from "../../Enrolment/EnrolmentSupportStatus/EnrolmentSupportStatusDot";
import { DataTable } from "../../generic/DataTable/DataTable";
import { SearchBox } from "../../generic/SearchBox";
import { SwitchButton } from "../../generic/SwitchButton";

const useStyles = makeStyles(() => ({
  switch: {
    display: "flex",
    alignItems: "end",
  },
  search: {
    marginBottom: "8px",
  },
  tableClassSelected: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
  },
  cellClassSelected: {
    borderBottom: "none",
  },
}));

const EnrolmentsShortList = ({
  appointment,
  enrolments,
  defaultPagination,
  fetchEnrolments,
  count,
  selectedEnrolmentChange,
}) => {
  // #region Hooks
  const classes = useStyles();
  const intl = useIntl();
  const [searchString, setSearchString] = useState("");
  const [assignedToAgent, setAssignedToAgent] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(defaultPagination);
  const [selectedEnrolment, setSelectedEnrolment] = useState(null);
  const ref = useRef();

  // Columns definition
  const columnData = [
    {
      id: "status",
      align: "left",
      handler: (row) => {
        return (
          <div className="enrolment-workflow-status">
            <EnrolmentSupportStatusDot status={row.workflow_status_unjspf} />
          </div>
        );
      },
    },
    {
      id: "name",
      align: "left",
      handler: (row) => {
        const fullName = getFullName(row?.user);
        return (
          <>
            {/* <Avatar className='user-avatar' /> */}
            <Typography>{fullName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {row.user?.unjspf_uuid}
            </Typography>
          </>
        );
      },
    },
    {
      id: "timezone",
      align: "left",
      handler: (row) => {
        const timezone = row.user?.profile?.timezone;
        const beneficiaryTime = getTimeSlotStr(appointment, timezone);
        const localStartDate = getDateStr(appointment);
        const beneficiaryStartDate = getDateStr(appointment, timezone);

        return (
          <>
            <Typography variant="body2" color="textSecondary">
              {beneficiaryTime.split("-")[0].trimEnd()}
              {localStartDate !== beneficiaryStartDate && " (+1)"}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {timezone ?? "N/D"}
            </Typography>
          </>
        );
      },
    },
    {
      id: "clear",
      align: "center",
      disablePadding: true,
      handler: () => {
        return (
          <IconButton
            color="inherit"
            aria-label="clear"
            onClick={() => setSelectedEnrolment(null)}
          >
            <Close />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    const { searchString: prevSearchString } = ref?.current ?? {};

    if (prevSearchString !== searchString) {
      currentPagination.page = 1;
    }

    fetchEnrolments(
      { ...defaultPagination, ...currentPagination },
      searchString,
      assignedToAgent
    );

    // Save current values
    ref.current = { currentPagination, assignedToAgent, searchString };
  }, [currentPagination, assignedToAgent, searchString]);
  // #endregion

  // #region Selection methods
  const selectEnrolment = (row) => {
    setSelectedEnrolment(row);
  };

  useEffect(() => {
    selectedEnrolmentChange(selectedEnrolment);
  }, [selectedEnrolment]);
  // #endregion

  return (
    <Box>
      <Grid container spacing={2} justifyContent="space-between">
        {/* Filters */}
        <Grid item xs={8} className={classes.switch}>
          <SwitchButton
            labelTranslated={intl.formatMessage(
              { id: "pages.schedule.appointment.assignedToX" },
              { agent: getReducedName(appointment?.operator) }
            )}
            checked={assignedToAgent}
            onChange={(event) => setAssignedToAgent(event.target.checked)}
            disabled={!!selectedEnrolment}
          />
        </Grid>
        <Grid item xs className={classes.search}>
          <SearchBox
            value={searchString}
            search={setSearchString}
            disabled={!!selectedEnrolment}
          />
        </Grid>
        {/* Table */}
        <Grid item xs={12}>
          {selectedEnrolment ? (
            <DataTable
              hideHeader
              hideFooter
              hideResults
              tableClass={classes.tableClassSelected}
              cellClass={classes.cellClassSelected}
              columnData={columnData}
              tableData={[selectedEnrolment]}
              initialPagination={defaultPagination}
              setFiltersList={setCurrentPagination}
              count={1}
            />
          ) : (
            <DataTable
              hideHeader
              hideResults
              columnData={columnData.slice(0, -1)}
              tableData={enrolments}
              initialPagination={defaultPagination}
              setFiltersList={setCurrentPagination}
              rowClickAction={selectEnrolment}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              noSearchResultsText={<IntlMessages id="enrolment.noEnrolments" />}
              count={count}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export { EnrolmentsShortList };
