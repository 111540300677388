import { Schedule } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  TimePicker as MuiTimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { useState } from "react";
import IntlMessages from "../../../util/IntlMessages";

// const ALLOWED_MINUTES = [0, 15, 30, 45];

const useStyles = makeStyles({
  pickerRoot: {
    cursor: "pointer",
    padding: "10px",
  },
  fieldset: {
    border: "1px solid rgba(126, 126, 126, 0.5)",
    borderRadius: "8px",
  },
  icon: {
    "& button": {
      marginLeft: "-8px",
    },
  },
  inputAdornment: {
    marginRight: "5px",
  },
  inputBase: {
    padding: "7px 0",
  },
});

const TimePicker = ({ id, label, value, onAccept, onChange }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <Box>
      {!!label && (
        <Typography className={classes.label}>
          <IntlMessages id={label} />
        </Typography>
      )}
      <MuiTimePicker
        id={id}
        open={open}
        value={value}
        minutesStep={15}
        slots={{ openPickerIcon: Schedule }}
        slotProps={{
          inputAdornment: {
            position: "start",
          },
          openPickerButton: {
            color: "primary",
          },
          textField: {
            InputProps: {
              classes: {
                adornedStart: classes.icon,
                input: classes.inputBase,
                inputAdornedStart: classes.inputAdornment,
                notchedOutline: classes.fieldset,
                root: classes.pickerRoot,
              },
              disableUnderline: true,
              placeholder: "Select",
              onClick: () => setOpen(true),
            },
          },
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        closeOnSelect
        onAccept={onAccept}
        onChange={onChange}
        onClose={() => setOpen(false)}
      />
    </Box>
  );
};

export { TimePicker };
