import { DATE_TIME_SHORT_FORMAT } from "../constants/common";
import { getFormattedDate } from "./dates";
import { getFullName, getReducedName } from "./user";

export const getAgent = (enrolment) => {
  const getName = (en) => {
    if (
      en?.assigned_agent?.first_name &&
      en?.assigned_agent?.middle_name &&
      en?.assigned_agent?.last_name
    ) {
      return (
        en?.assigned_agent?.first_name +
        " " +
        en?.assigned_agent?.middle_name +
        " " +
        en?.assigned_agent?.last_name
      );
    }
    if (
      en?.assigned_agent?.first_name &&
      !en?.assigned_agent?.middle_name &&
      en?.assigned_agent?.last_name
    ) {
      return (
        en?.assigned_agent?.first_name + " " + en?.assigned_agent?.last_name
      );
    }
    return "No data";
  };
  return enrolment?.assigned_agent ? getName(enrolment) : null;
};

export const getReviewerInfo = (reviewer, upperCase = false) => {
  let info;
  if (!reviewer) {
    return null;
  }
  const username = reviewer?.username;
  const fullName = getFullName(reviewer);
  if (!fullName || fullName === "") {
    info = username;
  } else {
    info = `${fullName} (${username})`;
  }
  if (upperCase) {
    info = info.toUpperCase();
  }
  return info;
};

export const getAppointmentInfo = (appointment) => {
  if (
    !appointment ||
    !appointment?.slot?.start_datetime ||
    !appointment?.slot?.end_datetime
  ) {
    return null;
  }
  const operator = appointment?.operator;
  const operatorReducedName = getReducedName(operator);

  return (
    appointment &&
    `On ${getFormattedDate(appointment?.slot?.start_datetime, DATE_TIME_SHORT_FORMAT)} with ${operatorReducedName}`
  );
};
