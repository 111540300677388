import { Box, LinearProgress, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import IntlMessages from "../../../../util/IntlMessages";
import styles from "./Linear.module.scss";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 8,
    borderRadius: 100,
  },
  colorPrimary: {
    backgroundColor: "#F5F6F7",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#5092CE",
  },
}))(LinearProgress);

const LinearProgressWithLabel = ({ value, label }) => {
  const labelValue = isScoreValid(value) ? `${value} %` : "NAN";
  const progressValue = isScoreValid(value) ? value : 0;

  return (
    <Box className={styles.progressContainer}>
      <Box className={styles.labelContainer}>
        <Typography className={styles.label}>
          <IntlMessages id={label} />
        </Typography>
        <Typography className={`${styles.label} ${styles.value}`}>
          {labelValue}
        </Typography>
      </Box>
      <BorderLinearProgress variant="determinate" value={progressValue} />
    </Box>
  );
};

const isScoreValid = (score) => {
  // Only validates percentage
  return score >= 0 && score <= 100;
};

export { LinearProgressWithLabel };
