import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import maintenance from "../../../assets/images/maintenance.svg";
import { languageSelector } from "../../../reducers/selectors";
import IntlMessages from "../../../util/IntlMessages";
import { Logo } from "../Logo/Logo";

const useStyles = makeStyles(() => ({
  item: {
    marginBottom: "50px",
  },
  title: {
    color: "#202020 !important",
    fontWeight: 700,
    lineHeight: "140%",
    marginBottom: "8px",
  },
  reload: {
    textTransform: "none",
    fontSize: "16px",
  },
}));

const Maintenance = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const language = useSelector(languageSelector);

  return (
    <Box className="bg-white d-flex flex-column w-100 align-items-center justify-content-center text-center">
      <Logo
        language={language}
        style={{ maxWidth: "240px" }}
        className={styles.item}
      />
      <img alt="Maintenance" src={maintenance} className={styles.item} />
      <Box className={styles.item}>
        <Typography variant="h5" className={styles.title}>
          <IntlMessages id="pages.maintenance.text" />
        </Typography>
        <Typography>
          <IntlMessages id="pages.maintenance.subtext" />
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className={styles.reload}
        onClick={() => navigate(-1)}
        disableElevation
      >
        <IntlMessages id="pages.maintenance.reload" />
      </Button>
    </Box>
  );
};

export { Maintenance };
