import {
  CLEAR_ERRORS,
  CLEAR_MY_USER_DATA,
  CREATE_USER_FAIL,
  LOAD_MY_USER_DATA,
  LOAD_MY_USER_DATA_FAIL,
  LOAD_MY_USER_DATA_SUCCESS,
  RESET_PASSWORD_FAIL,
  SET_MY_USER_DATA_FAIL,
  SET_MY_USER_DATA_SUCCESS,
  SET_USER_OPT_OUT,
  SET_USER_OPT_OUT_FAIL,
  UPDATE_USER,
  UPDATE_USER_FAIL,
} from "../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  data: null,
  errors: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_MY_USER_DATA:
    case UPDATE_USER:
    case SET_USER_OPT_OUT: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_MY_USER_DATA_SUCCESS:
    case SET_MY_USER_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    }
    case CREATE_USER_FAIL:
    case UPDATE_USER_FAIL:
    case SET_USER_OPT_OUT_FAIL:
    case SET_MY_USER_DATA_FAIL:
    case RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.error?.response?.data,
      };
    }
    case CLEAR_ERRORS: {
      return {
        ...state,
        loading: false,
        errors: null,
      };
    }
    case LOAD_MY_USER_DATA_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }
    case CLEAR_MY_USER_DATA: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
