import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isoWeek from "dayjs/plugin/isoWeek";
import {
  BOOK_APPOINTMENT,
  CREATE_OPERATOR_AVAILABILITY_SLOTS,
  DELETE_OPERATOR_APPOINTMENT,
  LOAD_AVAILABILITY_SLOTS,
  LOAD_USER_VIDEO_URL,
  LOAD_VERIFICATION_APPOINTMENTS,
} from "../constants/ActionTypes";
import {
  EVENT_REPETITION,
  EVENT_REPETITION_INCREMENT,
} from "../constants/common";
import {
  ISO_WEEK_DAY_SATURDAY,
  ISO_WEEK_DAY_SUNDAY,
  axiosGetParamsArraySerializer,
} from "../util";

dayjs.extend(isSameOrBefore);
dayjs.extend(isoWeek);

const OPERATOR_AVAILABILITY_URL = "/unjspfadmin/manager/availability";
const OPERATOR_AVAILABILITY_URL_V2 =
  "/unjspfadmin/v2/manager/availabilities_day";
const OPERATOR_APPPOINTMENTS_URL = "/unjspfadmin/v2/manager/appointment";
const VIDEO_CALL_URL_URL = "/unjspfadmin/manager/video_configuration";
const BOOK_APPOINTMENT_URL = "/unjspfadmin/v2/manager/book_appointment";

const makeOperatorTimeRangeListData = ({
  operatorsIds,
  startTime,
  endTime,
  onboarding__isnull,
}) => ({
  operator_id: operatorsIds,
  time_min: startTime && dayjs(startTime).valueOf(),
  time_max: endTime && dayjs(endTime).valueOf(),
  onboarding__isnull,
});

const makeOperatorTimeRangeListDataV2 = ({
  operatorsIds,
  startTime,
  endTime,
}) => ({
  operator_id: operatorsIds,
  start_time: startTime && dayjs(startTime).valueOf(),
  end_time: endTime && dayjs(endTime).valueOf(),
});

export const listOperatorsAvailabilityV2 = ({
  operatorsIds,
  startTime,
  endTime,
}) => ({
  type: LOAD_AVAILABILITY_SLOTS,
  payload: {
    request: {
      url: OPERATOR_AVAILABILITY_URL_V2,
      method: "GET",
      params: makeOperatorTimeRangeListDataV2({
        operatorsIds,
        startTime,
        endTime,
      }),
      paramsSerializer: axiosGetParamsArraySerializer,
    },
  },
});

export const listOperatorsAppointments = ({
  operatorsIds,
  startTime,
  endTime,
  onboarding__isnull,
}) => ({
  type: LOAD_VERIFICATION_APPOINTMENTS,
  payload: {
    request: {
      url: OPERATOR_APPPOINTMENTS_URL,
      method: "GET",
      params: makeOperatorTimeRangeListData({
        operatorsIds,
        startTime,
        endTime,
        onboarding__isnull,
      }),
      paramsSerializer: axiosGetParamsArraySerializer,
    },
  },
});

const makeOperatorAvailabilityCreationData = ({ operatorId, slots }) => ({
  operator_id: operatorId,
  availability: slots.map(({ start, end }) => ({
    start_date: start.valueOf(),
    end_date: end.valueOf(),
  })),
});

const dateIsWeekend = (date) => {
  // date is a Moment date
  const isoWeekday = date.isoWeekday();
  return [ISO_WEEK_DAY_SATURDAY, ISO_WEEK_DAY_SUNDAY].includes(isoWeekday);
};

const removeWeekend = (slots) => {
  return slots.filter(
    (slot) => !(dateIsWeekend(slot.start) || dateIsWeekend(slot.end))
  );
};

const makeAdditionalSlots = (repetition, start, end, endRepetitionDate) => {
  const slots = [];
  if (repetition !== EVENT_REPETITION.NONE) {
    const repetitionIncrement = EVENT_REPETITION_INCREMENT[repetition];
    let { nextStart, nextEnd } = {
      nextStart: dayjs(start).add(1, repetitionIncrement),
      nextEnd: dayjs(end).add(1, repetitionIncrement),
    };
    if (
      nextStart.isSameOrBefore(endRepetitionDate, "day") &&
      nextEnd.isSameOrBefore(endRepetitionDate, "day")
    ) {
      do {
        slots.push({
          start: nextStart,
          end: nextEnd,
        });
        nextStart = dayjs(nextStart).add(1, repetitionIncrement);
        nextEnd = dayjs(nextEnd).add(1, repetitionIncrement);
      } while (
        nextStart.isSameOrBefore(endRepetitionDate, "day") &&
        nextEnd.isSameOrBefore(endRepetitionDate, "day")
      );
    }
  }
  return removeWeekend(slots);
};

export const createOperatorsAvailability = ({
  operatorsIds,
  start,
  end,
  repetition,
  endRepetitionDate,
}) => {
  const slot = { start, end };
  const slots = [
    slot,
    ...makeAdditionalSlots(repetition, start, end, endRepetitionDate),
  ];
  const data = operatorsIds?.map((operatorId) =>
    makeOperatorAvailabilityCreationData({ operatorId, slots })
  );
  return {
    type: CREATE_OPERATOR_AVAILABILITY_SLOTS,
    payload: {
      request: {
        url: OPERATOR_AVAILABILITY_URL,
        method: "POST",
        data,
      },
    },
  };
};

export const deleteVerificationAppointment = (appointmentId) => ({
  type: DELETE_OPERATOR_APPOINTMENT,
  payload: {
    request: {
      url: `${OPERATOR_APPPOINTMENTS_URL}/${appointmentId}`,
      method: "DELETE",
    },
  },
});

export const bookAppointment = (appointment, enrolment) => {
  const { start_datetime, end_datetime } = appointment?.slot || {};
  const operator_id = appointment?.operator?.id;
  const on_boarding_id = enrolment?.id;
  const data = { start_datetime, end_datetime, on_boarding_id, operator_id };
  return {
    type: BOOK_APPOINTMENT,
    payload: {
      request: {
        url: BOOK_APPOINTMENT_URL,
        method: "POST",
        data,
      },
    },
  };
};

export const getVideoCallUrl = (beneficiaryId) => ({
  type: LOAD_USER_VIDEO_URL,
  payload: {
    request: {
      url: VIDEO_CALL_URL_URL,
      method: "POST",
      data: {
        user_id: beneficiaryId,
      },
    },
  },
});
