import { Close } from "@mui/icons-material";
import { IconButton, Modal } from "@mui/material";
import React from "react";

const ImageModal = ({ image, altText, open, handleCloseModal }) => {
  return (
    <Modal onClose={handleCloseModal} open={open}>
      <>
        <IconButton
          className="text-white"
          onClick={handleCloseModal}
          style={{ position: "absolute", top: 5, right: 5 }}
        >
          <Close />
        </IconButton>
        <img
          src={image}
          alt={altText}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
            maxHeight: "80vh",
            maxWidth: "80vw",
          }}
        />
      </>
    </Modal>
  );
};

export { ImageModal };
