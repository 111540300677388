import { TextField } from "@mui/material";
import { useIntl } from "react-intl";
import styles from "./User.module.scss";

const UserField = (props) => {
  const intl = useIntl();
  const { id, label, disabled, InputProps, InputLabelProps, readOnly } = props;
  const fieldInputProps = {
    ...InputProps,
    readOnly,
    disableUnderline: readOnly,
    className: `${InputProps?.className} ${styles.input} ${
      disabled ? styles.inputDisabled : ""
    }`,
  };
  const fieldInputLabelProps = {
    ...InputLabelProps,
    shrink: true,
    className: styles.label,
  };

  const translatedLabel = intl.formatMessage({ id: label });

  return (
    <TextField
      {...props}
      name={id}
      label={translatedLabel}
      variant="standard"
      InputProps={fieldInputProps}
      InputLabelProps={fieldInputLabelProps}
      fullWidth
    />
  );
};

export { UserField };
